import {
    faArrowLeft,
    faBell,
    faCableCar,
    faEnvelopeOpen,
    faLocationPin,
    faPenToSquare,
    faTag,
    faTrash,
    faUser,
    faStar,
    faCircleCheck,
    faCheck,
    faCircleXmark,
    faIndianRupeeSign,
    faHotel,
    faUtensils,
    faBinoculars,
    faBus,
    faTrainSubway,
    faPlane,
    faTaxi,
    faEdit,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState, useRef } from "react"
import { Modal, Form, Button } from "react-bootstrap"
import "../Project/css/index1.css"
import { faCalendar } from "@fortawesome/free-regular-svg-icons"
import Side_navbar from "./Side_navbar"
import My_pannel from "./My_pannel"
import Header from "../Project/Header"
import Header2 from "./Header2"
import { useNavigate, useParams } from "react-router-dom"
import { BASE_URL } from "../BASE_URL"
import './css/vendorpackages.css'
import Uppage from "../Project/Uppage"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProfileSection from "./SecondNavbar"

function ViewMyPackage(props) {
    const navigate = useNavigate()


    const [editedata, setEditedData] = useState("")

    const Call = async () => {
        const token = localStorage.getItem("vendorToken")
        const res = await fetch(`${BASE_URL}agency/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        })
        const data = await res.json()
        setEditedData(data?.data?.[0])
    }

    useEffect(() => {
        Call()
    }, [])

    const handleNav = () => {
        navigate("/vendor/myprofile")
    }

    const overRef = useRef(null)
    const itenarary = useRef(null)
    const services = useRef(null)
    const hotels = useRef(null)

    const scrollTo = (section) => {
        let targetRef

        switch (section) {
            case "overview":
                targetRef = overRef
                break
            case "services":
                targetRef = services
                break
            case "itenarary":
                targetRef = itenarary
                break
            case "hotels":
                targetRef = hotels
                break
            default:
                targetRef = null
        }

        if (targetRef) {
            window.scroll({
                top: targetRef.current.offsetTop,
                behavior: "smooth",
            })
        }
    }

    const { id } = useParams()
    const [pDetails, setPDetails] = useState([])
    const [hotelList, setHotelList] = useState([])
    const [selectedHotel, setSelectedHotel] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const PackageDetails = async () => {
        const token = localStorage.getItem("vendorToken")
        const res = await fetch(
            `${BASE_URL}package/getPackageData?package_id=${id}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            },
        )
        const data = await res.json()
        console.log(data?.data)
        setPDetails(data.data)
    }

    useEffect(() => {
        PackageDetails()
        window.scrollTo(0, 0)
    }, [])

    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        photoFile: null,
        photoURL: '',
        description: '',
        day: ''
    });

    const handleClose = () => {
        setOpen(false)
        setFormData({
            title: '',
            photoFile: null,
            photoURL: '',
            hotelName: '',
            description: '',
            day: ''
        })
    };
    const handleShow = () => setOpen(true);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'photoFile') {
            setFormData({ ...formData, [name]: files[0] });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSave = async () => {
        try {
            const token = localStorage.getItem("vendorToken");

            // Validation
            if (!formData.title) {
                toast.error("Title is required.", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
                return;
            }
            if (!formData.description) {
                toast.error("Activity description is required.", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
                return;
            }
            if (!selectedHotel) {
                toast.error("Select Hotel.", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
                return;
            }

            const formData1 = new FormData();
            formData1.append("title", formData.title);
            formData1.append("day", formData.day);
            formData1.append("activity", formData.description);
            formData1.append("hotel_itienrary_id", selectedHotel?._id);
            if (formData.photoFile) {
                formData1.append("photo", formData.photoFile);
            }

            const response = await fetch(`${BASE_URL}itinerary?_id=${formData.itineraryid}`, {
                method: "PUT",
                headers: {
                    Authorization: token,
                },
                body: formData1,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();
            toast.success("Itinerary updated successfully", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            handleClose();
            PackageDetails();
        } catch (error) {
            toast.error("Error updating itinerary. Please try again.", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            console.error("Error updating itineraries:", error);
        }
    };


    const handleDelete = async () => {

        try {
            const token = localStorage.getItem("vendorToken");


            const response = await fetch(`${BASE_URL}package/${id}`, {
                method: "DELETE",
                headers: {
                    Authorization: token,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            navigate('/vendoe/mypackages')
        } catch (error) {
            console.error("Error updating itineraries:", error);
        }
    };

    const fetchItineraryHotel = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}api/hotel_itienrary/displayAgencyById`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setHotelList(data?.data);
    };

    useEffect(() => {
        fetchItineraryHotel()
    }, []);

    const transformPriceAndDate = (priceAndDateArray) => {
        return priceAndDateArray?.map((item) => {
            // Convert price_start_date to a month name
            const date = new Date(item?.price_start_date);
            const monthName = date?.toLocaleString("default", { month: "long" });

            // Return the transformed object
            return {
                month_name: monthName,
                price_per_person: item?.price_per_person,
            };
        });
    };

    // Assuming pDetails[0].price_and_date is the array you want to transform
    const transformedArray = transformPriceAndDate(pDetails?.[0]?.price_and_date);

    const gotFinalValue = (baseValue, percentage) => {
         return (Number(baseValue) * (Number(percentage) / 100)) + baseValue;
    }

    const gotPercentageValue = (baseValue, percentage) => {
         return Number(baseValue) * (Number(percentage) / 100);
    }

    return (
        <>
            <Header2 />
            <div className='costum_container'>
                <My_pannel />
                <ToastContainer />
                <div>
                    <ProfileSection title="My Package List" />

                    <section className='py-5 mypackage_margin' style={{ backgroundColor: "whitesmoke", display: "flex", gap: "10px" }}>
                        {pDetails && pDetails?.map((ele) => {
                            return (
                                <>
                                    <div className='col-xl-8 col-lg-6 col-sm-12 col-12 m-0 posi-first'>
                                        <img
                                            // src="/bkg1.png"
                                            // src={packageData}
                                            src={ele.Place[0].photo}
                                            alt=''
                                            className='w-100 rounded h-md-50 meadia-for-package-details-img'
                                        />

                                        <div className='mt-3 hover-buttons-media'>
                                            <div className='me-3 mb-3'>
                                                <button
                                                    class='button-47'
                                                    role='button'
                                                    onClick={() => scrollTo("overview")}
                                                >
                                                    Overview
                                                </button>
                                            </div>
                                            <div className='me-3 mb-3'>
                                                <button
                                                    class='button-47'
                                                    role='button'
                                                    onClick={() => scrollTo("services")}
                                                >
                                                    Services
                                                </button>
                                            </div>
                                            <div className='me-3 mb-3'>
                                                <button
                                                    class='button-47'
                                                    role='button'
                                                    onClick={() => scrollTo("itenarary")}
                                                >
                                                    Itinerary
                                                </button>
                                            </div>
                                            <div className='me-3 mb-3'>
                                                <button
                                                    class='button-47'
                                                    role='button'
                                                    onClick={() => scrollTo("hotels")}
                                                >
                                                    Hotels
                                                </button>
                                            </div>

                                        </div>
                                        <Uppage />
                                        <section>
                                            <div className=''>
                                                <div className='overview-btn mb-4' ref={overRef}>
                                                    <button className='mb-3 mt-4'>Overview</button>
                                                    <div className='mx-xl-0 mx-sm-0 mx-1'>
                                                        {ele.more_details}
                                                    </div>
                                                </div>
                                                <div className='details-service' ref={services}>
                                                    <button className='mb-4'>Services</button>
                                                    <div className='media-services'>
                                                        <div className='row gx-xl-1 gx-lg-5 gx-md-4 gx-sm-0 IE-border'>
                                                            <div className='col-xl-6 col-12'>
                                                                <div className='hotel-Included'>
                                                                    <h4>
                                                                        <FontAwesomeIcon icon={faCircleCheck} />{" "}
                                                                        Included
                                                                    </h4>
                                                                    <div class='content-green'>
                                                                        {ele.include_service.map((e) => {
                                                                            return (
                                                                                <>
                                                                                    <p>
                                                                                        <FontAwesomeIcon icon={faCheck} />{" "}
                                                                                        {e.include_services_value}
                                                                                    </p>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-xl-6 col-12'>
                                                                <div className='hotel-excluded'>
                                                                    <h4>
                                                                        <FontAwesomeIcon icon={faCircleXmark} />{" "}
                                                                        Excluded
                                                                    </h4>
                                                                    <div className='content-red'>
                                                                        {ele.exclude_service.map((e1) => {
                                                                            return (
                                                                                <>
                                                                                    <p>
                                                                                        <FontAwesomeIcon icon={faCheck} />{" "}
                                                                                        {e1.exclude_services_value}
                                                                                    </p>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className='details-service mt-4 mb-1'
                                                    ref={itenarary}
                                                >
                                                    <button className='mb-4'>Itinerary</button>
                                                </div>

                                                {ele.Itinaries.length < Math.max(ele.total_days, ele.total_nights) &&
                                                    <div>
                                                        <button className="vendoraddpackageitinerary-add-button mb-3"
                                                            onClick={() => handleShow()}
                                                        >Add Itinerary</button>
                                                    </div>
                                                }
                                                <div className='itenary-days'>
                                                    {ele.Itinaries
                                                        .sort((a, b) => a.day - b.day)
                                                        .slice(0, Math.max(ele.total_days, ele.total_nights))
                                                        .map((itinerary, index) => (
                                                            <div className='mb-4' key={index}>
                                                                <h3>Day {index + 1}
                                                                    <span>
                                                                        <FontAwesomeIcon
                                                                            icon={faEdit}
                                                                            style={{ fontSize: "12px", color: "#09646D", margin: "10px" }}
                                                                            onClick={() => {
                                                                                setIsEdit(true);
                                                                                setFormData({
                                                                                    title: itinerary.title,
                                                                                    photoURL: itinerary.photo,
                                                                                    hotelName: itinerary.hotel_name,
                                                                                    description: itinerary.activity,
                                                                                    day: itinerary.day,
                                                                                    itineraryid: itinerary._id,
                                                                                });

                                                                                handleShow();
                                                                            }}
                                                                        />
                                                                    </span>
                                                                </h3>
                                                                <div className='itenary-days1'>
                                                                    <ul>
                                                                        <li>{itinerary.title}</li>
                                                                        <li>{itinerary.activity}</li>
                                                                    </ul>
                                                                    <div >
                                                                        <img style={{ width: "80%", height: "200px", borderRadius: "10px", margin: "10px" }} src={itinerary.photo} alt={itinerary.title} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}

                                                    <Modal show={open} onHide={handleClose}>
                                                        <Modal.Body>
                                                            <Modal.Title>{isEdit ? 'Edit Itinerary' : 'Add Itinerary'}</Modal.Title>
                                                            <form>
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Title"
                                                                        name="title"
                                                                        value={formData.title}
                                                                        onChange={handleChange}
                                                                        className="vendoraddpackageitinerary-input"
                                                                    />
                                                                </div>
                                                                <div className="form-group">
                                                                    <input
                                                                        type="file"
                                                                        accept="image/*"
                                                                        onChange={handleChange}
                                                                        name="photoFile"
                                                                        className="vendoraddpackageitinerary-photo-input"
                                                                    />
                                                                    {formData.photoFile ? (
                                                                        <img
                                                                            src={URL.createObjectURL(formData.photoFile)}
                                                                            alt="Preview"
                                                                            className="vendoraddpackageitinerary-preview-image"
                                                                        />
                                                                    ) :
                                                                        (

                                                                            formData.photoURL && (<img
                                                                                src={formData.photoURL}
                                                                                alt="Preview"
                                                                                className="vendoraddpackageitinerary-preview-image"
                                                                            />)

                                                                        )}
                                                                </div>
                                                                <div className='mb-2'>
                                                                    <label htmlFor='text'>Select Hotel</label>
                                                                    <div className='d-flex align-items-center'>
                                                                        <input
                                                                            type='text'
                                                                            name='select_hotel'
                                                                            className='vendoraddpackageitinerary-input'
                                                                            value={selectedHotel ? selectedHotel.hotel_name : ""}
                                                                            readOnly
                                                                        />
                                                                        <Button
                                                                            variant='primary'
                                                                            className='ms-2'
                                                                            onClick={() => setShowModal(true)}
                                                                            style={{
                                                                                backgroundColor: "#155E75"
                                                                            }}
                                                                        >
                                                                            +
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Hotel Name"
                                                                        name="hotelName"
                                                                        value={formData.hotelName}
                                                                        onChange={handleChange}
                                                                        className="vendoraddpackageitinerary-input"
                                                                    />
                                                                </div> */}
                                                                <div className="form-group">
                                                                    <textarea
                                                                        placeholder="Description"
                                                                        name="description"
                                                                        value={formData.description}
                                                                        onChange={handleChange}
                                                                        className="vendoraddpackageitinerary-textarea"
                                                                        rows="4"
                                                                    />
                                                                </div>
                                                            </form>
                                                            <div>
                                                                <Button variant="secondary" onClick={handleClose}>
                                                                    Close
                                                                </Button>
                                                                <Button variant="primary" style={{ backgroundColor: "#155E75", marginLeft: "5px" }} onClick={handleSave}>
                                                                    Submit
                                                                </Button>
                                                            </div>
                                                        </Modal.Body>

                                                    </Modal>
                                                </div>

                                            </div>
                                        </section>

                                        <section className='pt-4'>
                                            <div className='hotels-section' ref={hotels}>
                                                <div className='last-hotels'>
                                                    <h3>Hotels</h3>

                                                    {/* <!--------------------------- part-1 ---------------------------------------------> */}

                                                    <div className='hotel-president'>
                                                        <div className='row gx-5 p-2 second-changes'>
                                                            <div className='col-xl-6 col-12'>
                                                                <div className='image-center'>
                                                                    <img
                                                                        src='/hotel1.1.png'
                                                                        alt=''
                                                                        className='image-changes'
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-xl-6 col-12 first-change'>
                                                                <div className='d-inline-block mb-md-2'>
                                                                    <span className='d-flex day1-2  text-left align-items-center'>
                                                                        <div className='day-1-2 py-1 font-changes'>
                                                                            <h6>Day 1-2</h6>
                                                                        </div>
                                                                        <div className=' font-changes'>
                                                                            <h6 className='mb-1 pe-5'>
                                                                                Hotel Presisent
                                                                            </h6>
                                                                            <p className='mb-0 text-start'>
                                                                                <i class='fa-solid fa-location-dot me-2'></i>
                                                                                Dalhousie
                                                                            </p>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <div className='hotels-star'>
                                                                    <FontAwesomeIcon
                                                                        icon={faStar}
                                                                        className='p-1'
                                                                        style={{ color: "yellow" }}
                                                                    />
                                                                    <FontAwesomeIcon
                                                                        icon={faStar}
                                                                        className='p-1'
                                                                        style={{ color: "yellow" }}
                                                                    />
                                                                    <FontAwesomeIcon
                                                                        icon={faStar}
                                                                        className='p-1'
                                                                        style={{ color: "yellow" }}
                                                                    />
                                                                    <FontAwesomeIcon
                                                                        icon={faStar}
                                                                        className='p-1'
                                                                        style={{ color: "yellow" }}
                                                                    />
                                                                    <FontAwesomeIcon
                                                                        icon={faStar}
                                                                        className='p-1'
                                                                        style={{ color: "yellow" }}
                                                                    />
                                                                </div>
                                                                <div className='hotels-footer'>
                                                                    <p>
                                                                        Satdhara Road, Gandhi Chowk Dalhousie
                                                                        Dalhousie - <br /> 176304 Himachal Pradesh,
                                                                        India
                                                                    </p>
                                                                    <h6>More Details</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <!--------------------------- part-2 ---------------------------------------------> */}

                                                    <div className='hotel-president my-4'>
                                                        <div className='row gx-5 p-2 second-changes'>
                                                            <div className='col-xl-6 col-12'>
                                                                <div className='image-center'>
                                                                    <img
                                                                        src='/hotel1.2.png'
                                                                        alt=''
                                                                        className='image-changes'
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-xl-6 col-12 first-change'>
                                                                <div className='d-inline-block mb-md-2'>
                                                                    <span className='d-flex day1-2  text-left align-items-center'>
                                                                        <div className='day-1-2 py-1 font-changes'>
                                                                            <h6>Day 3</h6>
                                                                        </div>
                                                                        <div className=' font-changes'>
                                                                            <h6 className='mb-1 pe-5'>
                                                                                Hotel Mohan Palace
                                                                            </h6>
                                                                            <p className='mb-0 text-start'>
                                                                                <i className='fa-solid fa-location-dot me-2'></i>
                                                                                Manali
                                                                            </p>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <div className='hotels-star'>
                                                                    <FontAwesomeIcon
                                                                        icon={faStar}
                                                                        className='p-1'
                                                                        style={{ color: "yellow" }}
                                                                    />
                                                                    <FontAwesomeIcon
                                                                        icon={faStar}
                                                                        className='p-1'
                                                                        style={{ color: "yellow" }}
                                                                    />
                                                                    <FontAwesomeIcon
                                                                        icon={faStar}
                                                                        className='p-1'
                                                                        style={{ color: "yellow" }}
                                                                    />
                                                                    <FontAwesomeIcon
                                                                        icon={faStar}
                                                                        className='p-1'
                                                                        style={{ color: "yellow" }}
                                                                    />
                                                                    <FontAwesomeIcon
                                                                        icon={faStar}
                                                                        className='p-1'
                                                                        style={{ color: "yellow" }}
                                                                    />
                                                                </div>
                                                                <div className='hotels-footer'>
                                                                    <p>
                                                                        Left Bank, Naggar Road, Aleo, New Manali,
                                                                        District Kullu <br /> (H.P.) Aleo-New Manali,
                                                                        Manali - Himachal Pradesh
                                                                    </p>
                                                                    <h6>More Details</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                                                        <Modal.Body>
                                                            <div>
                                                                <h5 className="mb-0 mt-2 ms-2">Select Hotel</h5>
                                                                <hr />
                                                                {hotelList && hotelList.map((e, index) => (
                                                                    <div key={index} className="d-flex align-items-start mb-3 mx-4 gap-3">
                                                                        <input
                                                                            type="radio"
                                                                            name="selectedHotel"
                                                                            value={e._id}
                                                                            onChange={() => setSelectedHotel(e)}
                                                                            checked={selectedHotel && selectedHotel._id === e._id}
                                                                            className="mt-2"
                                                                        />
                                                                        <div>
                                                                            <p className="mb-1 cmnp">{e.hotel_name}</p>
                                                                            <p className="mb-1 cmnp">{e?.hotel_address}</p>
                                                                            <p className="mb-1 cmnp">{e?.hotel_city},{e?.hotel_state}</p>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                                {hotelList?.length < 1 && (
                                                                    <Button
                                                                        variant="primary"
                                                                        style={{
                                                                            backgroundColor: "#155E75",
                                                                        }}
                                                                        onClick={() => navigate("/vendor/addhotels")}
                                                                    >
                                                                        Add Hotel
                                                                    </Button>
                                                                )}
                                                                {hotelList?.length > 0 && (
                                                                    <div className="d-flex justify-content-center mt-4 mb-3">
                                                                        <Button
                                                                            variant="primary"
                                                                            style={{
                                                                                backgroundColor: "#155E75",
                                                                            }}
                                                                            onClick={() => setShowModal(false)}
                                                                        >
                                                                            Select
                                                                        </Button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Modal.Body>
                                                    </Modal>

                                                    {/* <!--------------------------- part-3 ---------------------------------------------> */}

                                                    <div className='hotel-president'>
                                                        <div className='row gx-5 p-2 second-changes'>
                                                            <div className='col-xl-6 col-12'>
                                                                <div className='image-center'>
                                                                    <img
                                                                        src='/hotel1.3.png'
                                                                        alt=''
                                                                        className='image-changes'
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-xl-6 col-12 first-change'>
                                                                <div className='d-inline-block mb-md-2'>
                                                                    <span className='d-flex day1-2  text-left align-items-center'>
                                                                        <div className='day-1-2 py-1 font-changes'>
                                                                            <h6>Day 4-5</h6>
                                                                        </div>
                                                                        <div className=' font-changes'>
                                                                            <h6 className='mb-1 pe-5'>Snow Retreat</h6>
                                                                            <p className='mb-0 text-start'>
                                                                                <i className='fa-solid fa-location-dot me-2'></i>
                                                                                Dharmashala
                                                                            </p>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <div className='hotels-star'>
                                                                    <FontAwesomeIcon
                                                                        icon={faStar}
                                                                        className='p-1'
                                                                        style={{ color: "yellow" }}
                                                                    />
                                                                    <FontAwesomeIcon
                                                                        icon={faStar}
                                                                        className='p-1'
                                                                        style={{ color: "yellow" }}
                                                                    />
                                                                    <FontAwesomeIcon
                                                                        icon={faStar}
                                                                        className='p-1'
                                                                        style={{ color: "yellow" }}
                                                                    />
                                                                    <FontAwesomeIcon
                                                                        icon={faStar}
                                                                        className='p-1'
                                                                        style={{ color: "yellow" }}
                                                                    />
                                                                    <FontAwesomeIcon
                                                                        icon={faStar}
                                                                        className='p-1'
                                                                        style={{ color: "yellow" }}
                                                                    />
                                                                </div>
                                                                <div className='hotels-footer'>
                                                                    <p>
                                                                        Naddi, McLeod Ganj, Dharamshala, Himachal
                                                                        Pradesh <br /> 176219
                                                                    </p>
                                                                    <h6>More Details</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <div className='col-xl-4 col-lg-6 col-sm-12 col-12 m-0 posi-second'>
                                        <div style={{ display: "flex", justifyContent: "end" }}>
                                            <button
                                                style={{ backgroundColor: "red", padding: "10px 20px", border: "none", borderRadius: "10px", color: "white", margin: "10px" }}
                                                onClick={handleDelete}
                                            >Delete Package</button>
                                            <button
                                                style={{ backgroundColor: "#09646D", padding: "10px 20px", border: "none", borderRadius: "10px", color: "white", margin: "10px" }}
                                                onClick={() => navigate(`/vendor/editmypackage/${ele._id}`)}
                                            >Edit Package</button>
                                        </div>
                                        <div className='CuStom_package package_details_2 book_now_card py-3 px-xl-3 px-sm-3 px-1 hyhy mb-3'>
                                            <div className='inner_packega'>
                                                <div className=''>
                                                    <div className='border_bottom'>
                                                        <p className='text-19 mb-1'>{ele.name}</p>
                                                        <div className='d-flex justify-content-between'>
                                                            <div>
                                                                <p className='mb-0 mt-2'>
                                                                    <button class='button-13' role='button'>
                                                                        {ele.day}
                                                                    </button>
                                                                </p>
                                                                <p className='mb-2 ms-1 fw-bold'>
                                                                    {ele.destination[0].destination_name}
                                                                </p>
                                                            </div>
                                                            {/* <div>
                                                            <div className='for-18000 mb-2'>
                                                                <div className='d-flex justify-content-end'>
                                                                    <p className=''></p>
                                                                    <p className='ms-2 mb-0 price-per-person-media'>
                                                                        <FontAwesomeIcon
                                                                            className='pe-2'
                                                                            icon={faIndianRupeeSign}
                                                                        />
                                                                        {ele.vender_price_per_person}
                                                                    </p>
                                                                    <div className='per-person-media text-end'>
                                                                        Per Person
                                                                    </div>
                                                                </div>
                                                                <div className='Per_Person text-end'>
                                                                    *Excluding applicable taxes
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        </div>
                                                        {ele.destination_category_id.length > 0 ? (
                                                            <>
                                                                <div className='mb-4'>
                                                                    <p
                                                                        className='mb-0 ms-1'
                                                                        style={{
                                                                            fontWeight: "500",
                                                                            fontSize: "12px",
                                                                        }}
                                                                    >
                                                                        Package Best For :
                                                                    </p>
                                                                    {ele.destination_category_id.map((e) => {
                                                                        return (
                                                                            <>
                                                                                <button
                                                                                    class='button-17 me-1'
                                                                                    role='button'
                                                                                >
                                                                                    {e.category_name}
                                                                                </button>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        <div className='pad_img d-flex text-center justify-content-evenly'>
                                                            <div>
                                                                <FontAwesomeIcon
                                                                    icon={faHotel}
                                                                    className='media-for-icon'
                                                                />
                                                                <br />
                                                                <span>Upto 3 Stars</span>
                                                            </div>
                                                            <div className='mx-3'>
                                                                <FontAwesomeIcon
                                                                    icon={faUtensils}
                                                                    className='media-for-icon'
                                                                />
                                                                <br />
                                                                <span>Meals</span>
                                                            </div>
                                                            <div>
                                                                <FontAwesomeIcon
                                                                    icon={faBinoculars}
                                                                    className='media-for-icon'
                                                                />
                                                                <br />
                                                                <span>Sightseeing</span>
                                                            </div>
                                                            <div className='mx-3'>
                                                                {ele.travel_by === "Bus" && (
                                                                    <>
                                                                        <FontAwesomeIcon
                                                                            icon={faBus}
                                                                            className='media-for-icon'
                                                                        />
                                                                        <br />
                                                                        <span>Bus</span>
                                                                    </>
                                                                )}
                                                                {ele.travel_by === "Train" && (
                                                                    <>
                                                                        <FontAwesomeIcon
                                                                            icon={faTrainSubway}
                                                                            className='media-for-icon'
                                                                        />
                                                                        <br />
                                                                        <span>Train</span>
                                                                    </>
                                                                )}
                                                                {ele.travel_by === "Flight" && (
                                                                    <>
                                                                        <FontAwesomeIcon
                                                                            icon={faPlane}
                                                                            className='media-for-icon'
                                                                        />
                                                                        <br />
                                                                        <span>Flight</span>
                                                                    </>
                                                                )}
                                                                {ele.travel_by === "Car" && (
                                                                    <>
                                                                        <FontAwesomeIcon
                                                                            icon={faTaxi}
                                                                            className='media-for-icon'
                                                                        />
                                                                        <br />
                                                                        <span>Car</span>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='CuStom_package package_details_2 book_now_card py-3 px-xl-3 px-sm-3 px-1 hyhy mb-3'>
                                            <div className='inner_packega'>
                                                <div className="margin-pricing-agency">
                                                    <div className="margin-pricing-agency-header">
                                                        <h5>Month</h5>
                                                        <h5>Price per Person</h5> {/* Updated header */}
                                                        <h5>Admin Margin</h5>
                                                        <h5>Final Price</h5>
                                                    </div>
                                                    <hr />
                                                    {/* Mapping through the transformed array */}
                                                    {transformedArray.map((updatedItem) => {
                                                        // Find the matching item in agencyProfitMargin
                                                        const matchingItem = ele?.profitMargin?.[0]?.month_and_margin_user?.find(
                                                            (e) => e.month_name === updatedItem.month_name
                                                        );

                                                        return (
                                                            <div className="margin-pricing-agency-body" key={updatedItem.month_name}>
                                                                {/* Display month name and price per person from transformedArray */}
                                                                <p className="d-flex align-items-center mb-0">{updatedItem.month_name?.slice(0, 3)}</p>
                                                                <p className="d-flex align-items-center mb-0"><FontAwesomeIcon icon={faIndianRupeeSign} />{updatedItem.price_per_person}</p>

                                                                {/* Display admin margin and final price from matchingItem if found */}
                                                                {matchingItem ? (
                                                                    <>
                                                                        <p className="d-flex align-items-center mb-0"><FontAwesomeIcon icon={faIndianRupeeSign} />{gotPercentageValue(updatedItem.price_per_person, matchingItem.margin_percentage)}({matchingItem.margin_percentage}%)</p>
                                                                        <p className="d-flex align-items-center mb-0"><FontAwesomeIcon icon={faIndianRupeeSign} />{gotFinalValue(updatedItem.price_per_person, matchingItem.margin_percentage)}</p>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <p>N/A</p> {/* Placeholder if no matching item is found */}
                                                                        <p>N/A</p>
                                                                    </>
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </>
                            )
                        })}

                    </section>
                </div>
            </div>
        </>
    )
}

export default ViewMyPackage;
