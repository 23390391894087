import React, { useState, useEffect, useContext } from "react"
import Hearer from "./Header"
import { Form, Modal, Row } from "react-bootstrap"
import "../Project/css/index1.css"
import "../Project/css/custom_package.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faStar,
  faUserXmark,
  faIndianRupeeSign
} from "@fortawesome/free-solid-svg-icons"

import { useLocation, useParams } from "react-router-dom"
import { bidContext } from "../App"
import { BASE_URL } from "../BASE_URL"
import Footer from "./Footer"
import { useRef } from "react"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { useNavigate } from "react-router-dom"
import Package_Details from "./package-details"
function Custom_packega(props) {
  const [show, setShow] = useState(false)
  const [show1, setShow1] = useState(false)
  const [bidDetails, setBidDetails] = useState([])
  const { bidData, setBidData } = useContext(bidContext)
  const navigate = useNavigate()
  const [fullname, setFullname] = useState("")
  const [departure, setDeparture] = useState("")
  const [email, setEmail] = useState("")
  const [number, setNumber] = useState("")
  const [customeCity, setCustomeCity] = useState("")
  const [customeState, setCustomeState] = useState("")
  const [Adult, setAdult] = useState(bidDetails.total_adult)
  const [Child, setChild] = useState(bidDetails.total_child)
  const [Infant, setInfant] = useState(bidDetails.total_infant)
  const [Night, setNight] = useState(bidDetails.total_nights)
  const [Day, setDay] = useState(bidDetails.total_days)
  const [Meal, setMeal] = useState(bidDetails.meal_required)
  const [Mealtype, setMealtype] = useState(bidDetails.meal_types)
  const [Sightseeing, setSightseeing] = useState(bidDetails.siteseeing)
  const [Vehicle, setVehicle] = useState(bidDetails.travel_by)
  const [Hoteltype, setHoteltype] = useState(bidDetails.hotel_types)
  const [personalCare, setPersonalcare] = useState("")
  const [bookedInclude, setBookedInclude] = useState("")
  const [bookedExclude, setBookedExclude] = useState("")
  const [requirment, setRequirment] = useState("")
  const [itenarary1, setItenerary] = useState("")
  const [PriceperPerson, setPriceperPerson] = useState(bidDetails.price_per_person)
  const [category, setcategory] = useState(bidDetails.destination_category)
  const [CustomId, setCustomId] = useState(bidDetails.custom_requirement_id)
  const [BidId, setBidId] = useState(bidDetails._id)
  const [booking, setBooking] = useState("")
  console.log(booking)

  const [load, setLoad] = useState("")

  const [profileData, setProfileData] = useState("")
  const [agencyName, setAgencyName] = useState("")
  const [agencyId, setAgencyId] = useState("")

  const [agencyAverageStar, setAgencyAverageStar] = useState(0);
  const [agencyTotalReview, setAgencyTotalReview] = useState(0);


  useEffect(() => {
    if (profileData) {
      setFullname(profileData.full_name)
      setEmail(profileData.email_address)
      setNumber(profileData.mobile_no)
      setCustomeCity(profileData.city)
      setCustomeState(profileData.state)
    }
  }, [profileData])

  const token = localStorage.getItem("userToken")
  const ShowPackage = async () => {
    const res = await fetch(`${BASE_URL}customrequirements`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()

    let matchedObject = null

    data.data.forEach(item => {
      if (item.custom_requirement._id === CustomId) {
        matchedObject = item
      }
    })

    if (matchedObject) {
      setProfileData(matchedObject.custom_requirement)
    }
  }

  useEffect(() => {
    ShowPackage()
  }, [CustomId])

  const { id } = useParams()
  const { merchentId } = useParams()

  const [startDate, setStartDate] = useState(bidDetails.start_date1)
  const [formattedStartDate, setformattedStartDate] = useState("")
  const [finalStartDate, setFinalStartDate] = useState("")

  useEffect(() => {
    if (formattedStartDate) {
      const formattedAbc = startDate ? new Date(startDate).toISOString() : ""
      setFinalStartDate(formattedAbc)
    }
  }, [formattedStartDate])

  useEffect(() => {
    if (startDate) {
      const dateComponents = startDate.split("/")
      const formattedDate = `${dateComponents[2]}-${dateComponents[1]}-${dateComponents[0]}`
      setStartDate(formattedDate)
      setformattedStartDate(formattedDate)
    }
  }, [load])

  const [endDate, setEndDate] = useState(bidDetails.end_date1)
  const [formattedEndDate, setformattedEndDate] = useState("")
  const [finalEndDate, setFinalEndDate] = useState("")

  useEffect(() => {
    if (formattedEndDate && endDate) {
      // Check if endDate is a valid Date object
      if (endDate instanceof Date && !isNaN(endDate)) {
        const formattedAbc = endDate.toISOString();
        setFinalEndDate(formattedAbc);
      } else {
        console.error("Invalid endDate:", endDate);
        // Handle the error or set a default value for finalEndDate
        setFinalEndDate("");
      }
    }
  }, [formattedEndDate, endDate]);


  useEffect(() => {
    if (endDate) {
      const dateComponents = endDate.split("/")
      const formattedDate = `${dateComponents[2]}-${dateComponents[1]}-${dateComponents[0]}`
      setEndDate(formattedDate)
      setformattedEndDate(formattedDate)
    }
  }, [load])

  const handleName = (e) => {
    setFullname(e.target.value)
  }
  const handleEmail = (e) => {
    setEmail(e.target.value)
  }
  const handleNumber = (e) => {
    setNumber(e.target.value)
  }
  const handleState = (e) => {
    setCustomeState(e.target.value)
  }
  const handleCity = (e) => {
    setCustomeCity(e.target.value)
  }

  const Profile = async () => {
    const token = localStorage.getItem("userToken")
    const res = await fetch(`${BASE_URL}user/userprofile`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()
  }

  const [privacy, setPrivacy] = useState([])

  const privacypolicies = async () => {
    const res = await fetch(`${BASE_URL}policy`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()
    setPrivacy(data.data[2])
  }

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleClose1 = () => setShow1(false)
  const handleShow1 = () => setShow1(true)

  const [dataSet, setDataSet] = useState(false);

  const DetailsBid = async () => {
    const res = await fetch(`${BASE_URL}bidpackage/biddetails?_id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })

    const data = await res.json()

    if (data.code === 200) {
      setLoad(data?.data?.start_date1)
      setBidDetails(data.data)
      setBooking(data.data[0])
      setDeparture(data?.data?.[0]?.departure)
      setAdult(data.data[0].total_adult)
      setChild(data.data[0].total_child)
      setInfant(data.data[0].Infant)
      setNight(data.data[0].total_nights)
      setDay(data.data[0].total_days)
      setMeal(data.data[0].meal_required)
      setMealtype(data.data[0].meal_types)
      setSightseeing(data.data[0].sightseeing)
      setVehicle(data.data[0].travel_by)
      setHoteltype(data.data[0].hotel_types)
      setPriceperPerson(data.data[0].price_per_person)
      setcategory(data.data[0].destination_category.split(","))
      setCustomId(data.data[0].custom_requirement_id)
      setBidId(data.data[0]._id)
      setStartDate(data.data[0].start_date1)
      setEndDate(data.data[0].end_date1)
      setPersonalcare(data?.data?.[0]?.personal_care)
      setBookedInclude(data?.data?.[0]?.include_services)
      setBookedExclude(data?.data?.[0]?.exclude_services)
      setRequirment(data?.data?.[0]?.additional_requirement)
      setItenerary(data?.data?.[0]?.itineries.map(ele => ele._id))
      setAgencyName(data?.data?.[0]?.agency_name)
      setAgencyId(data?.data?.[0]?.agency_id)
      setDataSet(true);
    }

  }

  // useEffect(() => {
  //   if (dataSet && merchentId) {
  //     LastSubmit();
  //     setDataSet(false); 
  //   }
  // }, [dataSet, merchentId]);

  const AgencyReviews = async () => {
    const res = await fetch(`${BASE_URL}review/agencyallreview?_id=${agencyId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
    })

    const data = await res.json()
    console.log(data)
    setAgencyAverageStar((data.metadata.average_star_rating)?.toFixed(1));
    setAgencyTotalReview(data.metadata.total_review);
  }

  useEffect(() => {
    if (agencyId) {
      AgencyReviews()
    }
  }, [agencyId])

  const totalPerson = Adult + Child + Infant

  useEffect(() => {

    const userDetails = async () => {
      const token = localStorage.getItem("token");
      const res = await fetch(
        `${BASE_URL}payment/payment_status?merchantTransactionId=${merchentId}`,
        {
          method: "GET",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await res.json();

      LastSubmit(data?.data?.merchantTransactionId, data?.data?.paymentInstrument?.type)
    };


    userDetails();
  }, [merchentId]);

  const LastSubmit = async (tranjectionId, paymentType) => {
    const token = localStorage.getItem("userToken");
    const bidDetails = JSON.parse(localStorage.getItem("bidDetails"));

    const updatedBidDetails = {
      ...bidDetails,
      transaction_id: tranjectionId,
      payment_type: paymentType,
    };

    const res = await fetch(`${BASE_URL}bookpackage`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedBidDetails),
    });

    const data = await res.json();

    if (data.code === 200) {
      toast.success("Package Booked Successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      navigate("/my_package");

      // Remove bidDetails from localStorage
      localStorage.removeItem("bidDetails");

      handleClose();
    }
  };


  const location = useLocation();

  const handlePrice = async () => {
    const myId = localStorage.getItem("my_id")
    const mobileno = number;
    const amount = bidDetails?.[0]?.price_per_person * (bidDetails?.[0]?.Infant + bidDetails?.[0]?.total_adult + bidDetails?.[0]?.total_child)
    const url = location.pathname;
    const bidId = bidDetails?.[0]?._id || ""
    const customRequrmentId = bidDetails?.[0]?.custom_requirement_id || ""
    const token = localStorage.getItem("userToken")

    const res = await fetch(`${BASE_URL}payment/pay`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        MERCHANT_USER_ID: myId,
        amount: 1,
        mobileNumber: mobileno,
        link: url,
        custom_requirement_id: customRequrmentId,
        bid_id: bidId,
      }),
    });

    const data = await res.json();

    const bidData = {
      total_adult: Adult,
      total_child: Child,
      total_infant: Infant,
      price_per_person_infant: booking?.price_per_person_infant,
      price_per_person_child: booking?.price_per_person_child,
      price_per_person_adult: booking?.price_per_person_adult,
      total_amount: booking?.total_amount,
      meal: Meal,
      meal_type: Mealtype,
      totalnight: Night,
      totaldays: Day,
      hoteltype: Hoteltype,
      room_sharing: booking?.room_sharing,
      siteseeing: Sightseeing,
      departure: departure,
      category: category,
      transport_mode: Vehicle,
      bid_package_id: BidId,
      approx_start_date: finalStartDate,
      approx_end_date: finalEndDate,
      custom_package_id: CustomId,
      othere_requirement: requirment,
      total_person: totalPerson,
      personal_care: personalCare,
      booked_include: bookedInclude,
      booked_exclude: bookedExclude,
      booked_itinerary: itenarary1,

      contact_number: number,
      email_id: email,
      user_name: fullname,
      city: customeCity,
      state: customeState,
      
      transport_include_exclude: "no",

      priceperperson: 0,
    };

    localStorage.setItem('bidDetails', JSON.stringify(bidData));

    window.location.href = data?.data?.url
  }

  const handleReject = async (e) => {
    e.preventDefault()
    const token = localStorage.getItem("userToken")

    const res = await fetch(`${BASE_URL}bidpackage/updateBidStatus/${id}`, {
      method: "PUT",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        bid_status: "reject",
      }),
    })
    const data = await res.json()
    if (data.code === 200) {
      toast.success("Bid Reject Successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      })
      DetailsBid()
      handleClose1()
      navigate("/custom_requirement")
    }
  }

  const overRef = useRef(null)
  const itenarary = useRef(null)
  const services = useRef(null)

  const scrollTo = (section) => {
    let targetRef

    switch (section) {
      case "overview":
        targetRef = overRef
        break
      case "services":
        targetRef = services
        break
      case "itenarary":
        targetRef = itenarary
        break
      case "privacy":
        targetRef = privacy
        break
      default:
        targetRef = null
    }

    if (targetRef) {
      window.scroll({
        top: targetRef.current.offsetTop,
        behavior: "smooth",
      })
    }
  }

  useEffect(() => {
    privacypolicies()
    Profile()
    DetailsBid()
  }, []);


  return (
    <>
      <Hearer />
      <ToastContainer />
      {bidDetails.map((ele, index) => {
        const mealRequired = ele.meal_required.split(",")
        return (
          <Package_Details
            key={index}
            despature={ele.departure}
            destination={profileData.destination}
            adult={ele.total_adult}
            scrollTo={scrollTo}
            overRef={overRef}
            services={services}
            handleClose1={handleClose1}
            show1={show1}
            itenarary={itenarary}
            handleShow1={handleShow1}
            bidData={bidData}
            handleReject={handleReject}
            handleShow={handleShow}
            privacy={privacy}
            Infant={ele.Infant}
            kids={ele.total_child}
            priceAdult={ele.price_per_person_adult}
            priceChild={ele.price_per_person_child}
            priceInfants={ele.price_per_person_infant}
            total_traveller={
              parseInt(ele.total_adult) +
              parseInt(ele.total_child) +
              parseInt(ele.Infant)
            }
            totalDays={ele.total_days}
            totalNights={ele.total_nights}
            end_date={ele.end_date1}
            start_date={ele.start_date1}
            hotel_types={ele.hotel_types}
            room_sharing={ele.room_sharing}
            hotel_itienrary={ele.hotel_itienrary}
            meal={mealRequired}
            meal_types={ele.meal_types}
            travel_by={ele.travel_by}
            include_services={ele.include_services}
            exclude_services={ele.exclude_services}
            itineries={ele.itineries}
            price_per_person={ele.price_per_person}
            total_amount={ele.total_amount}
            travel_item={ele.travel_by}
            bid_status={ele.bid_status}
            personal_care={profileData.personal_care}
            additional_req={profileData.additional_requirement}
            custom_requirement_status={ele.custom_requirement_status}
            agency_name={ele.agency_name}
            agencyAverageStar={agencyAverageStar}
            agencyTotalReview={agencyTotalReview}
          />
        )
      })}

      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop='static'
        keyboard={false}
        centered
      >
        <Modal.Body className='pt-5 pb-4'>
          <p className="text-center"><FontAwesomeIcon icon={faUserXmark} /></p>
          <h5 className='mb-4 text-center'>Are you sure you want to reject?</h5>
          <div className='d-flex justify-content-center'>
            <button
              className='reject-modal reject-modal-no'
              onClick={handleClose1}
            >
              No
            </button>
            <button
              className='reject-modal reject-modal-yes ms-2'
              onClick={handleReject}
            >
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>


      {/* ----------------------modal--------------------- */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div>
            <Row>
              <div className='col-md-6 col-12'>
                <div>
                  <div
                    style={{
                      background: "linear-gradient(#00363D, #005C63)",
                      color: "#fff",
                      borderRadius: "8px",
                    }}
                    className='p-1'
                  >
                    <p className='cmnp pt-1 textcm'>
                      {booking.departure} to{" "}
                      {booking.destination}
                      {/* {localStorage.getItem("destination")} */}
                    </p>
                    <span className='cmnp textcm'>
                      <FontAwesomeIcon
                        icon={faIndianRupeeSign}
                        className='ps-2'
                      />{" "}
                      {booking.price_per_person}
                    </span>
                    <p className='cmnp pb-1 textcm'>
                      Per person on twin sharing
                    </p>
                    <div className=' textcm'>
                      <FontAwesomeIcon icon={faStar} className='inner_star' />
                      <FontAwesomeIcon icon={faStar} className='inner_star' />
                      <FontAwesomeIcon icon={faStar} className='inner_star' />
                      <FontAwesomeIcon icon={faStar} className='inner_star' />
                      <FontAwesomeIcon icon={faStar} className='inner_star' />
                    </div>
                  </div>
                  <div className='cmn p-1'>
                    <p className='text-center'>Booking Details</p>
                    <div>
                      <Form.Control
                        type='text'
                        placeholder='Full Name'
                        style={{
                          borderLeft: "none",
                          borderRight: "none",
                          borderTop: "none",
                          fontSize: "10px",
                        }}
                        value={fullname}
                        // onChange={handleName}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^A-Za-z ]/g,
                            "",
                          )
                          handleName(e)
                        }}
                        className='mt-1 my-1'
                        name='fname'
                        readOnly
                      />

                      {/* <Form.Control
                        type='text'
                        placeholder='Departure'
                        style={{
                          borderLeft: "none",
                          borderRight: "none",
                          borderTop: "none",
                          fontSize: "10px",
                        }}
                        value={departure}
                        onChange={handleDeparture}
                        className='mt-1 my-1'
                        name='Departure'
                        readOnly
                      /> */}

                      <Form.Control
                        type='text'
                        placeholder='Email id'
                        style={{
                          borderLeft: "none",
                          borderRight: "none",
                          borderTop: "none",
                          fontSize: "10px",
                        }}
                        value={email}
                        onChange={handleEmail}
                        className='mt-1 my-1'
                        name='email'
                        readOnly
                      />

                      <Form.Control
                        type='text'
                        placeholder='Mobile Number'
                        style={{
                          borderLeft: "none",
                          borderRight: "none",
                          borderTop: "none",
                          fontSize: "10px",
                        }}
                        value={number}
                        onChange={handleNumber}
                        className='mt-1 my-1'
                        name='mobile'
                        readOnly
                      />

                      <div className="row gx-2">
                        <div className="col-6">
                          <Form.Control
                            type='text'
                            placeholder='Departure'
                            style={{
                              borderLeft: "none",
                              borderRight: "none",
                              borderTop: "none",
                              fontSize: "10px",
                            }}
                            value={customeState}
                            onChange={handleState}
                            className='mt-1 my-1'
                            name='Departure'
                            readOnly
                          />
                        </div>
                        <div className="col-6">
                          <Form.Control
                            type='text'
                            placeholder='Departure'
                            style={{
                              borderLeft: "none",
                              borderRight: "none",
                              borderTop: "none",
                              fontSize: "10px",
                            }}
                            value={customeCity}
                            onChange={handleCity}
                            className='mt-1 my-1'
                            name='Departure'
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <Row className='p-1'>
                        <div className='col-sm-6 col-12'>
                          <p className='cmnp textcm'>
                            Adult : {booking.total_adult}
                          </p>
                          <p className='cmnp textcm'>
                            Kids : {booking.total_child}
                          </p>
                        </div>
                        <div className='col-sm-6 col-12'>
                          <p className='cmnp textcm'>
                            Infant : {booking.Infant}
                          </p>
                          <p className='cmnp textcm'>
                            Total Travellers :{" "}
                            {parseInt(booking.total_adult) +
                              parseInt(booking.total_child) +
                              parseInt(booking.Infant)}
                          </p>
                        </div>
                      </Row>
                    </div>
                  </div>
                  <div className='text-center pt-2'>
                    <p
                      className='w-100 border-0 py-1'
                      style={{
                        background: "#09646D",
                        color: "#fff",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() => handlePrice(booking.total_amount)}
                    >
                      Proceed to Pay
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-6 d-md-block d-none'>
                <img
                  src='/boocking.png'
                  className='img-fluid h-100'
                  alt='booking'
                />
              </div>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <hr />
      <Footer />
    </>
  )
}

export default Custom_packega