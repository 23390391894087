import React from "react"
import { Nav } from "react-bootstrap"
import "../Project/css/index1.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCube,
  faHotel,
  faRightFromBracket,
  faKey,
  faStar,
  faCarSide,
  faBuildingCircleCheck,
  faPeopleGroup,
  faFileImport,
  faHandshake,
  faGaugeHigh,
  faElevator
} from "@fortawesome/free-solid-svg-icons"
import { useNavigate, useLocation } from "react-router-dom"
import { useState } from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import { useEffect } from "react"

function My_pannel(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleYesClick = () => {
    localStorage.removeItem("vendorToken")

    handleClose()

    navigate("/vendor/login")
  }

  useEffect(() => {
    const vendorToken = localStorage.getItem("vendorToken")
    if (!vendorToken) {
      // If "vendorToken" is present, automatically navigate to login
      navigate("/vendor/login")
    }
  }, [navigate])

  const isActive = (path) => location.pathname === path
  return (
    <>
      <style>
        {`
      .activea {
        background-color: #09646D;
        color: #FFFFFF; important
      }
    `}
      </style>
      <nav className='position_sticky'>
        <ul className='side_nav_list mb-0'>
          <li
            className={`button-48 d-flex align-items-center mb-2 ${isActive("/vendor/home-page") ? 'activea' : ''}`}
            onClick={() => navigate("/vendor/home-page")}
          >
            <a>
              <FontAwesomeIcon
                icon={faGaugeHigh}
                className={`your-external-css-class ${isActive("/vendor/home-page") ? 'activea' : ''}`}
              />
            </a>
            <p className={`mb-0 nav-text ${isActive("/vendor/home-page") ? 'activea' : ''}`}>Dashboard</p>
          </li>
          <li
            className={`py-2 button-48 d-flex align-items-center mb-2 ${isActive("/vendor/my-bid") ? 'activea' : ''}`}
            onClick={() => navigate("/vendor/my-bid")}
          >
            <a>
              <FontAwesomeIcon
                icon={faFileImport}
                className={`your-external-css-class ${isActive("/vendor/my-bid") ? 'activea' : ''}`}
              />
            </a>
            <p className={`mb-0 nav-text ${isActive("/vendor/my-bid") ? 'activea' : ''}`}>My Bid</p>
          </li>
          <li
            className={`button-48 d-flex align-items-center mb-2 ${isActive("/display/custom") ? 'activea' : ''}`}
            onClick={() => navigate("/display/custom")}
          >
            <a>
              <FontAwesomeIcon
                icon={faPeopleGroup}
                className={`your-external-css-class ${isActive("/display/custom") ? 'activea' : ''}`}
              />
            </a>
            <p className={`mb-0 nav-text ${isActive("/display/custom") ? 'activea' : ''}`}>New Leads</p>
          </li>
          <li
            className={`button-48 d-flex align-items-center mb-2 ${isActive("/vendor/mypackages") ? 'activea' : ''}`}
            onClick={() => navigate("/vendor/mypackages")}
          >
            <a>
              <FontAwesomeIcon
                icon={faCube}
                className={`your-external-css-class ${isActive("/vendor/mypackages") ? 'activea' : ''}`}
              />
            </a>
            <p className={`mb-0 nav-text ${isActive("/vendor/mypackages") ? 'activea' : ''}`}>My Package</p>
          </li>
          <li
            className={`py-2 button-48 d-flex align-items-center mb-2 ${isActive("/vendor/Booked-packega") ? 'activea' : ''}`}
            onClick={() => navigate("/vendor/Booked-packega")}
          >
            <a>
              <FontAwesomeIcon
                icon={faHandshake}
                className={`your-external-css-class ${isActive("/vendor/Booked-packega") ? 'activea' : ''}`}
              />
            </a>
            <p className={`mb-0 nav-text ${isActive("/vendor/Booked-packega") ? 'activea' : ''}`}>Booked</p>
          </li>
          <li
            className={`button-48 d-flex align-items-center mb-2 ${isActive("/vendor/hotels") ? 'activea' : ''}`}
            onClick={() => navigate("/vendor/hotels")}
          >
            <a>
              <FontAwesomeIcon icon={faElevator}
                className={`your-external-css-class ${isActive("/vendor/hotels") ? 'activea' : ''}`}
              />
            </a>
            <p className={`mb-0 nav-text ${isActive("/vendor/hotels") ? 'activea' : ''}`}>
              Itinerary Hotels
            </p>
          </li>
          <li
            className={`button-48 d-flex align-items-center mb-2 ${isActive("/vendor/myhotels") ? 'activea' : ''}`}
            onClick={() => navigate("/vendor/myhotels")}
          >
            <a>
              <FontAwesomeIcon icon={faHotel}
                className={`your-external-css-class ${isActive("/vendor/myhotels") ? 'activea' : ''}`}
              />
            </a>
            <p className={`mb-0 nav-text ${isActive("/vendor/myhotels") ? 'activea' : ''}`}>
              My Hotels
            </p>
          </li>
          <li
            className={`button-48 d-flex align-items-center mb-2 ${isActive("/vendor/hotelbookings") ? 'activea' : ''}`}
            onClick={() => navigate("/vendor/hotelbookings")}
          >
            <a>
              <FontAwesomeIcon icon={faBuildingCircleCheck}
                className={`your-external-css-class ${isActive("/vendor/hotelbookings") ? 'activea' : ''}`}
              />
            </a>
            <p className={`mb-0 nav-text ${isActive("/vendor/hotelbookings") ? 'activea' : ''}`}>
              Hotel Bookings
            </p>
          </li>
          <li
            className={`button-48 d-flex align-items-center mb-2 ${isActive("/vendor/mycars") ? 'activea' : ''}`}
            onClick={() => navigate("/vendor/mycars")}
          >
            <a>
              <FontAwesomeIcon icon={faCarSide}
                className={`your-external-css-class ${isActive("/vendor/mycars") ? 'activea' : ''}`}
              />
            </a>
            <p className={`mb-0 nav-text ${isActive("/vendor/mycars") ? 'activea' : ''}`}>
              My Cars
            </p>
          </li>
          <li
            className={`button-48 d-flex align-items-center mb-2 ${isActive("/vendor/carbookings") ? 'activea' : ''}`}
            onClick={() => navigate("/vendor/carbookings")}
          >
            <a>
              <FontAwesomeIcon icon={faCarSide}
                className={`your-external-css-class ${isActive("/vendor/carbookings") ? 'activea' : ''}`}
              />
            </a>
            <p className={`mb-0 nav-text ${isActive("/vendor/carbookings") ? 'activea' : ''}`}>
              Car Bookings
            </p>
          </li>
          <li
            className={`button-48 d-flex align-items-center mb-2 ${isActive("/vendor/Reviews") ? 'activea' : ''}`}
            onClick={() => navigate("/vendor/Reviews")}
          >
            <a>
              <FontAwesomeIcon
                icon={faStar}
                className={`your-external-css-class ${isActive("/vendor/Reviews") ? 'activea' : ''}`}
              />
            </a>
            <p className={`mb-0 nav-text ${isActive("/vendor/Reviews") ? 'activea' : ''}`}>
              Agency <br /> Reviews
            </p>
          </li>
          <li
            className={`button-48 d-flex align-items-center mb-2 ${isActive("/vendor/changepassword") ? 'activea' : ''}`}
            onClick={() => navigate("/vendor/changepassword")}
          >
            <a>
              <FontAwesomeIcon
                icon={faKey}
                className={`your-external-css-class ${isActive("/vendor/changepassword") ? 'activea' : ''}`}
              />
            </a>
            <p className={`mb-0 nav-text ${isActive("/vendor/changepassword") ? 'activea' : ''}`}>
              Change <br /> Password
            </p>
          </li>
          <li
            className='button-48 d-flex align-items-center mb-2'
            onClick={handleShow}
          >
            <a>
              <FontAwesomeIcon
                icon={faRightFromBracket}
                className='your-external-css-class'
              />
            </a>
            <p className='mb-0 nav-text'>Logout</p>
          </li>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop='static'
            keyboard={false}
            centered // Add the centered prop to center the modal
          >
            <Modal.Body className='p-5'>
              <div>
                <h4 className='text-center'>Are you Sure Want to Logout?</h4>
              </div>
              <div
                className='d-flex justify-content-center'
                style={{ gap: "10px", marginTop: "30px" }}
              >
                <button
                  style={{
                    width: "80px",
                    padding: "4px 0px",
                    backgroundColor: "lightgray",
                    borderRadius: "10px",
                    boxShadow: "none",
                    border: "none",
                    color: "grey",
                  }}
                  onClick={handleClose}
                >
                  No
                </button>
                <button
                  style={{
                    width: "80px",
                    padding: "4px 0px",
                    backgroundColor: "#09646d",
                    borderRadius: "10px",
                    boxShadow: "none",
                    border: "none",
                    color: "white",
                  }}
                  onClick={handleYesClick}
                >
                  Yes
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </ul>
      </nav>
    </>
  )
}

export default My_pannel
