import {
  faArrowLeft,
  faBell,
  faCableCar,
  faEnvelopeOpen,
  faLocationPin,
  faPenToSquare,
  faTag,
  faTrash,
  faUser,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { Container, Row } from "react-bootstrap"
import "../Project/css/index1.css"
import { faCalendar } from "@fortawesome/free-regular-svg-icons"
import Side_navbar from "./Side_navbar"
import My_pannel from "./My_pannel"
import Header from "../Project/Header"
import Header2 from "./Header2"
import { useNavigate, useParams } from "react-router-dom"
import { BASE_URL } from "../BASE_URL"
import ProfileSection from "./SecondNavbar"

const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Format options to get the date in 'dd-mm-yyyy' format
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  return date.toLocaleDateString('en-GB', options);
};

function Display_custom(props) {
  const navigate = useNavigate()

  const { id } = useParams()

  const [showPackageData, setShowPackageData] = useState([])
  console.log(showPackageData?.length)


  const showPackages = async () => {
    const token = localStorage.getItem("vendorToken")

    const res = await fetch(`${BASE_URL}customrequirements/Agencyshowdata`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()
    console.log(data?.data)
    setShowPackageData(data.data)
  }

  useEffect(() => {
    showPackages()
  }, [])

  const [editedata, setEditedData] = useState("")

  const Call = async () => {
    const token = localStorage.getItem("vendorToken")
    const res = await fetch(`${BASE_URL}agency/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    const data = await res.json()
    setEditedData(data?.data?.[0])
  }

  useEffect(() => {
    Call()
  }, [])

  const handleNav = () => {
    navigate("/vendor/myprofile")
  }

  return (
    <>
      <Header2 />
      <div className='costum_container'>
        <My_pannel />
        <div>
          <ProfileSection title="Custome Requirements" />

          <section className='py-5' style={{ backgroundColor: "whitesmoke" }}>
            <div className='row gx-5 gy-4 me-xl-5 me-lg-5 me-sm-auto ms-auto me-auto ms-sm-auto ps-lg-3 overflow-hidden  ps-sm-0'>
              {showPackageData &&
                showPackageData.map((ele, i) => {
                  return (
                    <>
                      <div
                        className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'
                        key={ele._id}
                      >
                        <div
                          className='row only_for_green_border p-xl-3 p-lg-3 p-md-3 p-sm-3 p-2 costum_requirments'
                          style={{
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                            height: "auto",
                          }}
                          onClick={() =>
                            navigate(`/display/custom/details/${ele._id}`)
                          }
                        >
                          <div className='col-1 px-xl-2 px-lg-0 px-md-0 px-sm-0 costum_requirments_span'>
                            <span className='cmnp cmnbkg'>{i + 1}</span>
                          </div>
                          <div className='col-xl-11 col-lg-11 col-md-11 col-11 costum_requirments_center'>
                            <div className='d-flex align-items-center justify-content-between pt-2 py-1'>
                              <div className="d-flex align-items-center">
                                <FontAwesomeIcon
                                  icon={faUser}
                                  className='cmnclr'
                                />
                                <p className='cmnp ps-1 text-capitalize'>
                                  {ele.full_name}
                                </p>
                              </div>
                              <div>
                                <p className='cmnp ps-1 text-capitalize'>
                                  <b>Post On : </b>
                                  <span
                                    style={{
                                      fontWeight: "500",
                                      // color: getStatusColor(ele.bid_status),
                                      fontSize: "20px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {formatDate(ele.createdAt)}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className='d-flex align-item-center pt-2 py-1'>
                              <FontAwesomeIcon
                                icon={faLocationPin}
                                className='cmnclr'
                              />
                              <p className='cmnp ps-1 text-capitalize'>
                                <b>Departure : </b> {ele.departure}
                              </p>
                            </div>
                            <div className='d-flex align-item-center pt-2 py-1'>
                              <FontAwesomeIcon
                                icon={faLocationPin}
                                className='cmnclr'
                              />
                              <p className='cmnp ps-1 text-capitalize'>
                                <b>Destination : </b> {ele.destination}
                              </p>
                            </div>
                            <div className='d-flex align-item-center pt-2 py-1'>
                              <FontAwesomeIcon
                                icon={faCalendar}
                                className='cmnclr'
                              />
                              <p className='cmnp ps-1'>
                                <b>Travel Between :</b>
                                {formatDate(ele.start_date)} to{" "}
                                {formatDate(ele.end_date)}
                              </p>
                            </div>
                            {/* <div className="d-flex justify-content-between align-items-end">
                              <div className='py-1'>
                                <span
                                  className='cmnp'
                                  style={{ color: "#B8B8B8" }}
                                >
                                  Requirement ID : {ele._id}
                                </span>
                              </div>
                            </div> */}
                          </div>
                          <div className='for_order_only mt-2'>
                            <button
                              href=''
                              className='costum_requirments_footer_button'
                            >
                              <FontAwesomeIcon
                                icon={faTag}
                                className='me-2'
                              />
                              Budget : {ele.budget_per_person} / Person
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })}
              {showPackageData?.length < 1 && (
                <div>
                  <p>Leads Not Available.</p>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

export default Display_custom
