import React, { useState, useEffect, useContext } from "react"
import Hearer from "./Header"
import { Container, Form, Modal, Row } from "react-bootstrap"
import "../Project/css/index1.css"
import "../Project/css/custom_package.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBrush,
  faCalendar,
  faCheck,
  faHandHoldingDroplet,
  faHandsHoldingCircle,
  faHandshakeAlt,
  faHotel,
  faIndianRupeeSign,
  faLocationPinLock,
  faPaperclip,
  faStar,
  faUserDoctor,
  faUsers,
  faStarHalfAlt
} from "@fortawesome/free-solid-svg-icons"
import {
  faCircleCheck,
  faUser,
  faXmarkCircle,
} from "@fortawesome/free-regular-svg-icons"
import { useParams } from "react-router-dom"
import { bidContext } from "../App"
import { BASE_URL } from "../BASE_URL"
import Footer from "./Footer"
import { faTrainSubway } from "@fortawesome/free-solid-svg-icons"
import { faBinoculars } from "@fortawesome/free-solid-svg-icons"
import { faUtensils } from "@fortawesome/free-solid-svg-icons"
import { faBus } from "@fortawesome/free-solid-svg-icons"
import { faPlane } from "@fortawesome/free-solid-svg-icons"
import { faTaxi } from "@fortawesome/free-solid-svg-icons"
import { useRef } from "react"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import dayjs from "dayjs"

import { useLocation } from "react-router-dom"

function Package_Details({
  despature,
  destination,
  adult,
  scrollTo,
  overRef,
  services,
  handleClose1,
  show1,
  itenarary,
  handleShow1,
  bidData,
  handleReject,
  handleShow,
  privacy,
  Infant,
  kids,
  total_traveller,
  totalDays,
  totalNights,
  end_date,
  start_date,
  hotel_types,
  meal,
  meal_types,
  travel_by,
  include_services,
  exclude_services,
  itineries,
  personal_care,
  additional_req,
  custom_requirement_status,
  agency_name,
  agencyAverageStar,
  agencyTotalReview,
  priceAdult,
  priceChild,
  priceInfants,
  room_sharing,
  total_amount,
  hotel_itienrary,
}) {

  const firstWord = travel_by?.split(',')[0];

  const [stars, setStars] = useState([]);

  const location = useLocation()

  useEffect(() => {
    const fullStars = Math.floor(agencyAverageStar);
    const hasHalfStar = agencyAverageStar % 1 !== 0;
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

    const starElements = [];
    for (let i = 0; i < fullStars; i++) {
      starElements.push(
        <FontAwesomeIcon
          key={`full-${i}`}
          icon={faStar}
          className='CuStom_package_icon'
        />
      );
    }
    if (hasHalfStar) {
      starElements.push(
        <FontAwesomeIcon
          key='half'
          icon={faStarHalfAlt}
          className='CuStom_package_icon'
        />
      );
    }
    for (let i = 0; i < emptyStars; i++) {
      starElements.push(
        <FontAwesomeIcon
          key={`empty-${i}`}
          icon={faStar}
          className='CuStom_package_icon'
          style={{ color: '#B8B8B8' }}
        />
      );
    }

    setStars(starElements);
  }, [agencyAverageStar]);

  return (
    <>
      <>
        <section className='pt-5 pb-3'>
          <div className='container-customes'>
            <div className=''>
              <Row className='custom-package-row'>
                <div className='col-xl-8 col-lg-6 col-sm-12 col-12 text-center m-0 p-0 posi-first'>
                  <div className='CuStom_package_image'>
                    <img
                      src='/C-packega.png'
                      className='w-100 rounded h-md-50'
                      style={{ height: "370px" }}
                    />
                  </div>
                  <div className='d-flex mt-3 overview-packages'>
                    <div className='me-3 mb-3'>
                      <button
                        class='button-47'
                        role='button'
                        onClick={() => scrollTo("overview")}
                      >
                        Overview
                      </button>
                    </div>
                    <div className='me-3 mb-3'>
                      <button
                        class='button-47'
                        role='button'
                        onClick={() => scrollTo("services")}
                      >
                        Services
                      </button>
                    </div>
                    <div className='me-3 mb-3'>
                      <button
                        class='button-47'
                        role='button'
                        onClick={() => scrollTo("itenarary")}
                      >
                        Itinerary
                      </button>
                    </div>
                    <div className='me-3 mb-3'>
                      <button
                        class='button-47'
                        role='button'
                        onClick={() => scrollTo("privacy")}
                      >
                        Policy
                      </button>
                    </div>
                    <ToastContainer />
                  </div>
                  <section className='mb-3 mt-3' ref={overRef}>
                    <div className=''>
                      <div>
                        <button className='w-100 over-btn'>
                          <img src='/c-icon.png' className=' me-3' />
                          Overview
                        </button>
                      </div>
                    </div>
                  </section>

                  <section className='text-start'>
                    <div className=''>
                      <Row>
                        <div className='col-xl-6 col-lg-12 mb-3'>
                          <div className='amh_to_hima'>
                            <div>
                              <h6 className='pb-2 mb-0 cmntext text-capitalize'>
                                {despature} to {destination}
                              </h6>
                            </div>
                            <div className=''>
                              <div className='row'>
                                <div className='col-12 px-0'>
                                  <div className='amh_to_hima_border ms-3 px-2 px-sm-4 px-md-4 px-lg-4 px-xl-4 py-2'>
                                    <p className='cmnp py-1'>
                                      <FontAwesomeIcon
                                        icon={faHotel}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Adult (12+yrs)</b> : {adult}
                                    </p>
                                    <p className='cmnp py-1'>
                                      <FontAwesomeIcon
                                        icon={faHandHoldingDroplet}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Infant (2 to 12 years)</b> : {Infant}
                                    </p>
                                    <p className='cmnp py-1'>
                                      <FontAwesomeIcon
                                        icon={faHandHoldingDroplet}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Kids(0 to 12 years)</b> : {kids}
                                    </p>
                                    <p className='cmnp py-1'>
                                      <FontAwesomeIcon
                                        icon={faUsers}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Total Travellers</b> :{" "}
                                      {total_traveller}
                                    </p>
                                    <p className='cmnp py-1'>
                                      <FontAwesomeIcon
                                        icon={faHandsHoldingCircle}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Any Traveller Needs Personal Care?</b>{" "}
                                      : {personal_care}
                                    </p>
                                    <p className='cmnp py-1'>
                                      <FontAwesomeIcon
                                        icon={faCalendar}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Total Days & Night</b> : {totalDays} & {totalNights}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className='mt-3 mb-2'>
                                <h6 className='cmntext'>
                                  <FontAwesomeIcon
                                    icon={faCalendar}
                                    className='cmnclr'
                                  />{" "}
                                  Approx Departure Between{" "}
                                </h6>
                              </div>
                              <div className='cmn'>
                                <p className='cmnp ms-4 py-2'>
                                  {start_date} to {end_date}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='col-xl-6 col-lg-12 mb-3'>
                          <div className='amh_to_hima'>
                            <div>
                              <h6 className=' pb-2 mb-0 cmntext'>
                                <FontAwesomeIcon
                                  icon={faHotel}
                                  className='cmnclr'
                                />{" "}
                                Hotel & Food
                              </h6>
                            </div>
                            <div className=''>
                              <div className='row'>
                                <div className='col-12 px-0'>
                                  <div className='amh_to_hima_border ms-3 px-4 py-2'>
                                    <p className='cmnp py-1'>
                                      <FontAwesomeIcon
                                        icon={faHotel}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Hotel Type</b> : {hotel_types}
                                    </p>
                                    <p className='cmnp py-1'>
                                      <FontAwesomeIcon
                                        icon={faHotel}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Room Sharing</b> : {room_sharing}
                                    </p>
                                    <p className='cmnp py-1'>
                                      <FontAwesomeIcon
                                        icon={faUserDoctor}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Meal</b> : {meal}
                                    </p>
                                    <p className='cmnp py-1'>
                                      <FontAwesomeIcon
                                        icon={faUserDoctor}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Meal Type</b> : {meal_types}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='mt-3 mb-2'>
                              {travel_by?.length > 10 ? (
                                <h6 className='cmntext'>
                                  <FontAwesomeIcon
                                    icon={firstWord?.toLowerCase() === "train" ? faTrainSubway : firstWord?.toLowerCase() === "flight" ? faPlane : faTaxi}
                                    className='cmnclr'
                                  />{" "}
                                  Travel By
                                </h6>
                              ) : (
                                <h6 className='cmntext'>
                                  <FontAwesomeIcon
                                    icon={travel_by?.toLowerCase() === "train" ? faTrainSubway : travel_by?.toLowerCase() === "flight" ? faPlane : faTaxi}
                                    className='cmnclr'
                                  />{" "}
                                  Travel By
                                </h6>
                              )}
                            </div>
                            <div className='cmn'>
                              <p className='cmnp ms-4 py-2'>{travel_by}</p>
                            </div>
                          </div>
                        </div>
                      </Row>

                      <div className='cmn  text-start mt-3'>
                        <div className='mt-3 mb-2 text-start cmnp ms-4 pb-2'>
                          <h6 className='cmntext'>
                            <FontAwesomeIcon
                              icon={faPaperclip}
                              className='cmnclr'
                            />{" "}
                            Additional Requirements By Traveller
                          </h6>
                        </div>
                        {
                          additional_req && additional_req.map(req => (
                            <p className='cmnp ms-4 py-2 mb-3' key={req.id}>{req}</p>
                          ))
                        }

                      </div>
                    </div>
                  </section>

                  <section className='my-3' ref={services}>
                    <div className=''>
                      <div className='mb-3'>
                        <button className='w-100 over-btn'>
                          <FontAwesomeIcon className='me-3' icon={faBrush} />{" "}
                          Services
                        </button>
                      </div>
                      <div className='cmn cmn_2 row text-start included_service'>
                        <div className='col-12 col-md-6'>
                          <h6 className='cmntext mb-2'>
                            <FontAwesomeIcon icon={faCircleCheck} /> Include
                            Services
                          </h6>
                          <div className='cmn package_service mb-3'>
                            <p style={{ color: "#00B707" }} className='cmnp'>
                              {include_services.map((e1) => {
                                return (
                                  <>
                                    <div className='d-flex , align-items-center'>
                                      <FontAwesomeIcon
                                        icon={faCheck}
                                        className='me-2'
                                        style={{
                                          marginBottom: "15px",
                                        }}
                                      />
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: e1.include_services_value,
                                        }}
                                      />
                                    </div>
                                  </>
                                )
                              })}
                            </p>
                          </div>
                        </div>
                        <div className='col-12 col-md-6'>
                          <h6 className='cmntext'>
                            <FontAwesomeIcon icon={faXmarkCircle} /> Exclude
                            Services
                          </h6>
                          <div className='cmn package_service'>
                            <p style={{ color: "red" }} className='cmnp'>
                              {exclude_services.map((e2) => {
                                return (
                                  <>
                                    <div className='d-flex , align-items-center'>
                                      <FontAwesomeIcon
                                        icon={faXmarkCircle}
                                        className='me-2'
                                        style={{
                                          marginBottom: "15px",
                                        }}
                                      />
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: location.pathname.includes(
                                            "booking-full-detail",
                                          )
                                            ? e2.include_services_value
                                            : e2.exclude_services_value,
                                        }}
                                      />
                                    </div>
                                  </>
                                )
                              })}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className='text-start' ref={itenarary}>
                    <div className=''>
                      <div className='my-3'>
                        <button className='w-100 over-btn'>
                          <FontAwesomeIcon
                            icon={faHandshakeAlt}
                            className='me-2'
                          />{" "}
                          Itinerary
                        </button>
                      </div>
                      <div className='cmn cmn__3'>
                        {itineries.map((e3, i) => {
                          return (
                            <>
                              <div className="py-2">
                                <h6 className="cmntext mb-2">Day {i + 1}</h6>
                                <div className='cmn p-2'>

                                  <Row className='align-items-start'>
                                    <div className='col-lg-9'>
                                      <div className='py-1'>
                                        <h6 className='cmnp cmnclr1'>Title</h6>
                                        <p className='cmnp cmnclr font-weight-bolder'>
                                          {e3.title}{" "}
                                        </p>
                                      </div>
                                      <div className='py-1'>
                                        <h6 className='cmnp cmnclr1'>Activity</h6>
                                        <p className='cmnp cmnclr font-weight-bolder'>
                                          <div
                                            style={{ textAlign: "justify" }}
                                            dangerouslySetInnerHTML={{
                                              __html: e3.activity,
                                            }}
                                          />
                                        </p>
                                      </div>
                                    </div>
                                    {e3.photo && <div className='col-lg-3'>
                                      <div className='text-center py-2'>
                                        <img src={e3.photo} className='img-fluid' style={{ height: "120px", width: "120px" }} />
                                      </div>
                                    </div>}
                                  </Row>


                                </div>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </div>
                  </section>

                  <section className='pt-4'>
                    <div className='hotels-section'>
                      <div className='last-hotels'>
                        <h3 className="text-start">Hotels</h3>

                        {/* <!--------------------------- part-1 ---------------------------------------------> */}

                        <div className='hotel-president'>
                          {hotel_itienrary && hotel_itienrary?.map((e, index) => (
                            <div className='row gx-5 p-2 second-changes'>
                              <div className='col-xl-6 col-12'>
                                <div id={`carouselExampleCaptions-${index}`} className="carousel slide" data-bs-ride="carousel">
                                  <div className="carousel-indicators">
                                    {e?.hotel_photo?.map((_, idx) => (
                                      <button
                                        key={idx}
                                        type="button"
                                        data-bs-target={`#carouselExampleCaptions-${index}`}
                                        data-bs-slide-to={idx}
                                        className={idx === 0 ? "active" : ""}
                                        aria-current={idx === 0 ? "true" : "false"}
                                        aria-label={`Slide ${idx + 1}`}
                                      ></button>
                                    ))}
                                  </div>
                                  <div className="carousel-inner">
                                    {e?.hotel_photo?.map((ele, idx) => (
                                      <div key={idx} className={`carousel-item ${idx === 0 ? "active" : ""}`}>
                                        <img src={ele} className="d-block w-100" alt="..." />
                                      </div>
                                    ))}
                                  </div>
                                  <button className="carousel-control-prev" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                  </button>
                                  <button className="carousel-control-next" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                  </button>
                                </div>
                              </div>
                              <div className='col-xl-6 col-12 first-change'>
                                <div className='d-inline-block mb-md-2'>
                                  <span className='d-flex day1-2  text-left align-items-center'>
                                    <div className='day-1-2 py-1 font-changes'>
                                      <h6>Day {e?.days}</h6>
                                    </div>
                                    <div className=' font-changes'>
                                      <h6 className='mb-1 pe-5'>
                                        {e?.hotel_name}
                                      </h6>
                                      <p className='mb-0 text-start'>
                                        <i class='fa-solid fa-location-dot me-2'></i>
                                        Dalhousie
                                      </p>
                                    </div>
                                  </span>
                                </div>
                                <div className='hotels-star'>
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className='p-1'
                                    style={{ color: "yellow" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className='p-1'
                                    style={{ color: "yellow" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className='p-1'
                                    style={{ color: "yellow" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className='p-1'
                                    style={{ color: "yellow" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className='p-1'
                                    style={{ color: "yellow" }}
                                  />
                                </div>
                                <div className='hotels-footer'>
                                  <p>
                                    {e?.hotel_address} <br />
                                    {e?.hotel_city},{e?.hotel_state}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                
                <div className='col-xl-4 col-lg-6 col-sm-12 col-12 posi-second'>
                  <div className='CuStom_package book_now_box d-flex justify-content-center align-items-center hyhy'>
                    <div className='inner_CuStom_package'>
                      <p className='CuStom_package_header text-capitalize'>
                        Agency : {agency_name}
                      </p>
                      <div className='CuStom_package_content'>
                        <p className='mb-1'>
                          {" "}
                          <span>Date </span> <span className='mx-1'>:</span>{" "}
                          {start_date} to {end_date}
                        </p>
                        <p className="mb-1" style={{ fontSize: "13px", fontWeight: "500" }}>Adult : {adult} * <FontAwesomeIcon className='me-1' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{priceAdult} = <FontAwesomeIcon className='me-1' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{Number(adult) * Number(priceAdult)}</p>
                        {Number(kids) > 0 && (
                          <p className="mb-1" style={{ fontSize: "13px", fontWeight: "500" }}>Children : {kids} * <FontAwesomeIcon className='me-1' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{priceChild} = <FontAwesomeIcon className='me-1' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{Number(kids) * Number(priceChild)}</p>
                        )}
                        {Number(Infant) > 0 && (
                          <p className="mb-2" style={{ fontSize: "13px", fontWeight: "500" }}>Infants : {Infant} * <FontAwesomeIcon className='me-1' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{priceInfants} = <FontAwesomeIcon className='me-1' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{Number(Infant) * Number(priceInfants)}</p>
                        )}
                        <div className='d-flex'>
                          <p className='mb-1' style={{ fontWeight: "600" }}>
                            <span>Total Amount</span>
                            <span className='ms-1 me-2'>:</span>
                            <FontAwesomeIcon className='me-1' icon={faIndianRupeeSign} />
                            {total_amount}
                          </p>
                        </div>
                        <div
                          className=''
                          style={{
                            display: "grid",
                            gridAutoColumns: "1fr 1fr",
                          }}
                        >
                          <p className='mb-0'>
                            {" "}
                            <span> Total Reviews </span>{" "}
                            <span className='mx-1'>:</span> {agencyTotalReview}
                          </p>
                          {agencyAverageStar && (
                            <p className=''>
                              {agencyAverageStar}
                              {stars}
                            </p>
                          )}
                        </div>
                        <div className='CuStom_package_images mt-4'>
                          <div className='text-center'>
                            <FontAwesomeIcon
                              icon={faHotel}
                              style={{ color: "#868383", fontSize: "30px" }}
                              className=''
                            />
                            <p className="" style={{ width: "60px" }}>{hotel_types}</p>
                          </div>
                          <div className='mx-2 text-center'>
                            <FontAwesomeIcon
                              icon={faUtensils}
                              style={{ color: "#868383", fontSize: "30px" }}
                              className=''
                            />
                            <p>Meals</p>
                          </div>
                          <div className='text-center'>
                            <FontAwesomeIcon
                              icon={faBinoculars}
                              style={{ color: "#868383", fontSize: "30px" }}
                              className=''
                            />
                            <p>Sightseeing</p>
                          </div>
                          {travel_by?.length > 10 && (
                            <div className='mx-2 text-center'>
                              <div>
                                {firstWord?.toLowerCase() ===
                                  "train" && (
                                    <>
                                      <FontAwesomeIcon
                                        icon={faTrainSubway}
                                        style={{ color: "#868383", fontSize: "30px" }}
                                        className=''
                                      />
                                    </>
                                  )}
                                {firstWord?.toLowerCase() ===
                                  "flight" && (
                                    <>
                                      <FontAwesomeIcon
                                        icon={faPlane}
                                        style={{ color: "#868383", fontSize: "30px" }}
                                        className=''
                                      />
                                    </>
                                  )}
                                {firstWord?.toLowerCase() === "cab/car" && (
                                  <>
                                    <FontAwesomeIcon
                                      icon={faTaxi}
                                      style={{ color: "#868383", fontSize: "30px" }}
                                      className=''
                                    />
                                  </>
                                )}
                              </div>
                              <p style={{ width: "60px" }}>By {travel_by}</p>
                            </div>
                          )}
                          {travel_by?.length < 10 && (
                            <div className='mx-2 text-center'>
                              <div>
                                {travel_by?.toLowerCase() ===
                                  "train" && (
                                    <>
                                      <FontAwesomeIcon
                                        icon={faTrainSubway}
                                        style={{ color: "#868383", fontSize: "30px" }}
                                        className=''
                                      />
                                    </>
                                  )}
                                {travel_by?.toLowerCase() ===
                                  "flight" && (
                                    <>
                                      <FontAwesomeIcon
                                        icon={faPlane}
                                        style={{ color: "#868383", fontSize: "30px" }}
                                        className=''
                                      />
                                    </>
                                  )}
                                {travel_by?.toLowerCase() === "cab/car" && (
                                  <>
                                    <FontAwesomeIcon
                                      icon={faTaxi}
                                      style={{ color: "#868383", fontSize: "30px" }}
                                      className=''
                                    />
                                  </>
                                )}
                              </div>
                              <p>By {travel_by}</p>
                            </div>
                          )}
                        </div>
                        {custom_requirement_status !== "reject" && custom_requirement_status !== "booked" && (
                          <div className='row'>
                            <div className='col-sm-6 col-12 d-flex justify-content-center mt-3'>
                              <button
                                className='CuStom_package_btn'
                                onClick={handleShow1}
                              >
                                Reject
                              </button>
                            </div>

                            <div className='col-sm-6 col-12 d-flex justify-content-center mt-3'>
                              <button
                                className='CuStom_package_btn CuStom_package_btn_2'
                                onClick={handleShow}
                              >
                                Book Now
                              </button>
                            </div>
                          </div>
                        )}
                        {custom_requirement_status === "booked" && (

                          <div className='col-sm-6 col-12 d-flex justify-content-center mt-3'>
                            <button
                              // className='CuStom_package_btn'
                              style={{
                                color: '#09646D',
                                border: 'solid',
                                borderColor: "#09646D",
                                padding: '10px 20px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 'bold',
                              }}
                            >
                              Booked
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-12 posi-third' ref={privacy}>
                  <div className='mt-5'>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: privacy.term_and_condition_content,
                      }}
                    />
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </section>
      </>
    </>
  )
}

export default Package_Details
