import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyAohr4jdELgHlAuO5dDztvJZp_MT-rOUFI",
  authDomain: "startyourtour.firebaseapp.com",
  databaseURL: "https://startyourtour.firebaseio.com",
  projectId: "startyourtour",
  storageBucket: "startyourtour.appspot.com",
  messagingSenderId: "485831053571",
  appId: "1:485831053571:web:6dc68f5e60f941d8129936",
  measurementId: "G-7932MQEWQT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);