import React, { useEffect, useRef } from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import { useState } from "react"
import { Container, FormLabel, Row } from "react-bootstrap"
import { toast, ToastContainer } from "react-toastify"
import { BASE_URL } from "../BASE_URL"
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth"
import { auth } from "../firebase"
import { useNavigate, useLocation } from "react-router-dom"
import { useAuthContext } from "../context/AuthContext"
import axios from "axios"
import loader from "../images/loading.png"

function Login({ setName, setShow, show }) {

  const [loading, setLoading] = useState(false)

  const { setAuthUser } = useAuthContext();

  const [show1, setShow1] = useState(false)
  const [show2, setShow2] = useState(false)
  const [show3, setShow3] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  const [newpassword, setNewpassword] = useState()

  const handleNewpassword = (e) => {
    const value = e.target.value
    setNewpassword(value)
  }

  const [newPasswordVisible, setNewPasswordVisible] = useState(false)

  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)

  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible)
  }

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible)
  }

  const [passwordVisible, setPasswordVisible] = useState(false)
  const [password, setPassword] = useState("")
  const [phoneerror, setPhoneerror] = useState("")

  const handlePasswordChange = (event) => {
    const value = event.target.value
    setPassword(value)
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const handleClose = () => {
    setShow(false)
    setPassword("")
  }

  const handleClose1 = () => setShow1(false)
  const handleShow1 = () => setShow1(true)

  const handleClose2 = () => setShow2(false)
  const handleShow2 = () => setShow2(true)

  const handleClose3 = () => setShow3(false)
  const handleShow3 = () => setShow3(true)

  const clickon = () => {
    handleShow1(true)
    handleClose(false)
  }

  const [inputValues, setInputValues] = useState("")
  const [passworderror, setPassworderror] = useState("")

  const inputRefs = useRef([])

  const handleInputChange = (index, e) => {
    const input = e.target
    const value = input.value

    if (index < 6) {
      if (value && input.nextSibling) {
        input.nextSibling.focus()
      } else if (!value && input.previousSibling) {
        input.previousSibling.focus()
      }
    } else {
      const inputValue = value.slice(0, 1)
      input.value = inputValue
    }

    // Update the inputValues state by joining the values of all inputs
    setInputValues(() => {
      const updatedValues = inputRefs.current.map((ref) => ref.value).join("")
      return updatedValues
    })
  }

  const handleInputKeyDown = (index, e) => {
    const input = e.target

    if (e.keyCode === 8 && !input.value && input.previousSibling) {
      input.previousSibling.focus()
    }
  }

  const createRef = (index) => (ref) => {
    inputRefs.current[index] = ref
  }

  const renderInputs = () => {
    const inputs = []

    for (let i = 0; i < 6; i++) {
      inputs.push(
        <input
          key={i}
          type='number'
          maxLength={1}
          onChange={(e) => handleInputChange(i, e)}
          onKeyDown={(e) => handleInputKeyDown(i, e)}
          ref={createRef(i)}
          style={{
            width: "40px",
            height: "40px",
            margin: "0 5px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            fontSize: "16px",
            padding: "8px",
            textAlign: "center",
          }}
        />,
      )
    }

    return inputs
  }

  const [user, setUser] = useState({
    phone: "",
  })

  const txt = (e) => {
    const { name, value } = e.target
    setUser({ ...user, [name]: value })
  }

  const [numbersave, setNumbersave] = useState()
  const [myUser, setMyUser] = useState("")
  const [validDetails, setValidDetails] = useState("")

  const handleNumberSave = (ev) => {
    const value = ev.target.value
    setNumbersave(value.slice(0, 10))
  }

  const clickon2 = () => {
    if (!numbersave) {
      toast.error("Please enter your mobile number!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    if (numbersave.length < 10) {
      toast.error("Please enter valid mobile number!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    handleClose1(false)
    handleShow2(true)
  }

  const clickon3 = () => {
    handleClose2(false)
    handleShow3(true)
  }

  const [updateName, setUpdateName] = useState(false)

  useEffect(() => {
    setUpdateName(false)
  }, [updateName])

  const profile = async () => {
    const res = await fetch(`${BASE_URL}user/userprofile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    const data = await res.json()
    setName(data?.data?.[0]?.user_details?.[0]?.name)
  }

  const loginHandle = async (e) => {
    e.preventDefault()
    setUpdateName(true)

    const { phone } = user

    if (phone.length === 0 && !password) {
      setPhoneerror("Please enter your mobile number")
      setPassworderror("Please enter your password")
      return
    }

    if (phone.length === 0) {
      setPhoneerror("Please enter mobile number")
      return
    }

    if (phone.length < 10) {
      setPhoneerror("Please enter valid mobile number")
      return
    }

    setLoading(true)

    const res = await fetch(`${BASE_URL}user/loginAll`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        phone: Number(phone),
        password,
        role: "customer",
      }),
    })
    const data = await res.json()
    setLoading(false)
    console.log(data)

    if (data.code == 200) {
      profile()
      setPassword("")
      localStorage.setItem("userToken", data.data.token)
      localStorage.setItem("mySytUserId", data.data.userId)
      setAuthUser(data.data.userId)
      navigate("/")
      handleClose(true)
    } else {
      setValidDetails(data.message)
      return
    }
  }

  const [myOtp, setMyOtp] = useState("")

  const sendOtp = async () => {
    if (numbersave.length < 10) {
      toast.error("Please enter valid number!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    const number = "+91" + numbersave;
    const random = Math.floor(Math.random() * 900000) + 100000;
    setMyOtp(random)

    const url = `https://rslri.connectbind.com:8443/bulksms/bulksms?username=DG35-webearl&password=digimile&type=0&dlr=1&destination=${number}&source=WEBEAR&message=Dear User, Your one time password is ${random} and it is valid for 10 minutes. Do not share it to anyone. Thank you, Team WEBEARL TECHNOLOGIES.&entityid=1101602010000073269&tempid=1107169899584811565`;

    try {
      const response = await axios.get(url);
      if (response.status === 200) {
        toast.success('OTP sent successfully!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
        clickon2()
      } else {
        toast.error('Failed to send OTP. Please try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      toast.error('Error sending OTP. Please check your network connection and try again.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    }

  }

  const [confirmpassword, setConfirmpassword] = useState()

  const token = localStorage.getItem("userToken")

  const handleConfirmpassword = (e) => {
    const value = e.target.value
    setConfirmpassword(value)
  }

  const handleSubmit = async () => {
    if (newpassword.length < 6) {
      toast.error("New password should have 6 characters.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    if (!newpassword && !confirmpassword) {
      toast.error("Please new password and confirm password!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    if (!newpassword && confirmpassword) {
      toast.error("Please enter new password!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    if (confirmpassword && newpassword < 6) {
      toast.error("New password must be more than 5 letters!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    if (newpassword && !confirmpassword) {
      toast.error("Please enter confirm password!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    if (newpassword !== confirmpassword) {
      toast.error("New password and confirm password is not same!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    const res = await fetch(`${BASE_URL}user/chengepassword`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        phone: Number(numbersave),
        newpassword: newpassword,
      }),
    })
    const data = await res.json()

    if (data.code == 200) {
      // alert("");
      toast.success("Password changed successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      setShow3(false)
    } else {
      // setValidDetails("Enter Valid Details");
      setValidDetails(data.message)
      return
    }
  }

  const verifyOtp = async () => {
    if (myOtp === Number(inputValues)) {
      toast.success("OTP verified successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      })
      clickon3()
    } else {
      toast.error("Wrong Otp!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      })
    }
  }

  return (
    <div>
      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Body>
            <div>
              <Container>
                <div className='common_p '>
                  <div className='inerp p-4 border-1 pb-0'>
                    <Row>
                      <div className='col-sm-6 col-12'>
                        <form>
                          <div>
                            <h6 style={{ fontWeight: "600", fontSize: "15px" }}>
                              LOG IN
                            </h6>
                            <p
                              style={{
                                margin: "0",
                                padding: "0",
                                fontSize: "12px",
                              }}
                            >
                              <ToastContainer />
                              Enter Your Mobile No.
                            </p>
                            <input
                              type='text'
                              id='phone'
                              className='w-100 px-2 py-1'
                              style={{ borderRadius: "5px" }}
                              name='phone'
                              pattern='[0-9]*'
                              onChange={(e) => {
                                let input = e.target.value;
                                // Remove non-numeric characters
                                const numericInput = input.replace(/[^0-9]/g, "");
                                // Limit to 10 digits
                                let truncatedInput = numericInput.slice(0, 10);
                                // If the input starts with '0', remove it
                                if (truncatedInput.startsWith('0')) {
                                  truncatedInput = truncatedInput.substring(1);
                                }
                                // Update the input value
                                e.target.value = truncatedInput;
                                // Call the onChange handler
                                txt(e); // Assuming txt is your onChange handler
                              }}
                            />
                            {user.phone.length < 10 ? (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {phoneerror}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <p
                            className='pt-3'
                            style={{
                              margin: "0",
                              padding: "0",
                              fontSize: "12px",
                            }}
                          >
                            Enter Your Password
                          </p>
                          <div
                            style={{
                              position: "relative",
                            }}
                          >
                            <input
                              type={passwordVisible ? "text" : "password"}
                              id='password'
                              name='password'
                              className='w-100 px-2 py-1'
                              style={{ borderRadius: "5px" }}
                              value={password}
                              onChange={(txt, handlePasswordChange)}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^A-Za-z0-9!@#$%^&*()_+\-=[\]{}|;:'",.<>?/]/g,
                                  "",
                                )
                              }}
                            />
                            <img
                              src={passwordVisible ? "/eye.png" : "/eye2.png"}
                              className='eye-image'
                              alt=''
                              style={{
                                height: "10px",
                                width: "15px",
                                position: "absolute",
                                top: "0",
                                bottom: "0",
                                right: "5%",
                                margin: "auto",
                                cursor: "pointer",
                              }}
                              onClick={togglePasswordVisibility}
                            />
                          </div>
                          {password.length <= 0 ? (
                            <span style={{ color: "red", fontSize: "12px" }}>
                              {passworderror}
                            </span>
                          ) : (
                            ""
                          )}
                          <p
                            href=''
                            className='text-danger pt-2 '
                            style={{
                              textDecoration: "none",
                              float: "right",
                              fontSize: "12px",
                              cursor: "pointer",
                            }}
                            onClick={clickon}
                          >
                            Forgot password?
                          </p>
                          <div className='d-flex justify-content-center w-100'>
                            {validDetails.length ? (
                              <span
                                className='mb-0'
                                style={{ color: "red", fontSize: "12px" }}
                              >
                                {validDetails}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className='pb-2 text-center my-3'>
                            {loading ? (
                              <button
                                type='submit'
                                className="login-modal-btn"
                              >
                                <img src={loader} alt="" className="spinner" />
                              </button>
                            ) : (
                              <button
                                type='submit'
                                className="login-modal-btn"
                                onClick={loginHandle}
                              >
                                Login
                              </button>
                            )}
                            {!location.pathname.includes(
                              "user/registration",
                            ) && (
                                <p style={{ fontSize: "12px" }} className='pt-3'>
                                  Don’t Have an Account?
                                  <a
                                    onClick={() => navigate("/user/registration")}
                                    style={{
                                      textDecoration: "none",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Create Account
                                  </a>
                                  ?
                                </p>
                              )}
                          </div>
                        </form>
                      </div>

                      <div className='col-sm-6 d-sm-block d-none'>
                        <img src='/login.png' className='img-fluid' />
                      </div>
                    </Row>
                  </div>
                </div>
              </Container>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      <div>
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Body>
            <div>
              <Container>
                <div className='text-center'>
                  <div className='text-center py-2 pt-3'>
                    <h6
                      className='text-center'
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Please Enter Your Register Mobile No:-
                    </h6>
                  </div>
                  <div id='recaptcha'></div>
                  <div className='text-center'>
                    <input
                      type='tel'
                      className='px-sm-2 py-1'
                      style={{ borderRadius: "5px" }}
                      value={numbersave}
                      onChange={handleNumberSave}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/\D/g, "")
                      }}
                    />
                  </div>
                  <div className='pt-5'>
                    <button
                      type='submit'
                      style={{
                        color: "white",
                        background: "#09646D",
                        fontSize: "14px",
                        padding: "8px 30px",
                        border: "none",
                        borderRadius: "8px",
                      }}
                      onClick={sendOtp}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Container>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      <div>
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Body>
            <Container>
              <div>
                <div className='common p-4 text-center'>
                  <div>
                    <h6
                      className='text-center'
                      style={{ fontWeight: "600", fontSize: "20px" }}
                    >
                      Enter O.T.P
                    </h6>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {renderInputs()}
                  </div>
                  <div className='pt-3'>
                    <button
                      type='submit'
                      style={{
                        color: "white",
                        background: "#09646D",
                        fontSize: "14px",
                        padding: "8px 30px",
                        border: "none",
                        borderRadius: "8px",
                      }}
                      onClick={verifyOtp}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </Container>
          </Modal.Body>
        </Modal>
      </div>

      <div>
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Body>
            <div>
              <div>
                <div className='py-2 pt-3'>
                  <h6
                    className='text-center'
                    style={{ fontWeight: "600", fontSize: "20px" }}
                  >
                    Set New Password
                  </h6>
                </div>
                <div className='text-center py-2'>
                  <p className='cmnp'>New Password</p>
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <input
                      className="forgot-password-new-password-input"
                      type={newPasswordVisible ? "text" : "password"}
                      onChange={handleNewpassword}
                      value={newpassword}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(
                          /[^A-Za-z0-9!@#$%^&*()_+\-=[\]{}|;:'",.<>?/]/g,
                          "",
                        )
                      }}
                    />
                    <img
                      src={newPasswordVisible ? "/eye.png" : "/eye2.png"}
                      className='eye-image'
                      alt=''
                      style={{
                        height: "10px",
                        width: "15px",
                        position: "absolute",
                        top: "0",
                        bottom: "0",
                        right: "15%",
                        margin: "auto",
                        cursor: "pointer",
                      }}
                      onClick={toggleNewPasswordVisibility}
                    />
                  </div>
                </div>
                <div className='text-center py-2'>
                  <p className='cmnp'>Conform New Password</p>
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <input
                      className="forgot-password-new-password-input"
                      type={confirmPasswordVisible ? "text" : "password"}
                      onChange={handleConfirmpassword}
                      value={confirmpassword}
                      minLength={6}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(
                          /[^A-Za-z0-9!@#$%^&*()_+\-=[\]{}|;:'",.<>?/]/g,
                          "",
                        )
                      }}
                    />
                    <img
                      src={confirmPasswordVisible ? "/eye.png" : "/eye2.png"}
                      className='eye-image'
                      alt=''
                      style={{
                        height: "10px",
                        width: "15px",
                        position: "absolute",
                        top: "0",
                        bottom: "0",
                        right: "15%",
                        margin: "auto",
                        cursor: "pointer",
                      }}
                      onClick={toggleConfirmPasswordVisibility}
                    />
                  </div>
                </div>
                <div className='pt-5 text-center'>
                  <button
                    type='submit'
                    onClick={handleSubmit}
                    style={{
                      color: "white",
                      background: "#09646D",
                      fontSize: "14px",
                      padding: "8px 30px",
                      border: "none",
                      borderRadius: "8px",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      <ToastContainer />
    </div>
  )
}

export default Login
