/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  faBell,
  faEnvelopeOpen,
  faLocationPin,
  faTag,
  faUser,
  faIndianRupeeSign
} from "@fortawesome/free-solid-svg-icons"
import { faCalendar } from "@fortawesome/free-regular-svg-icons"
import Box from "@mui/material/Box"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import My_pannel from "./My_pannel"
import Header2 from "./Header2"
import { useNavigate } from "react-router-dom"
import { BASE_URL } from "../BASE_URL"
import { Details } from "@mui/icons-material"
import ProfileSection from "./SecondNavbar"

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return 'Invalid date format';
  }

  // Format options to get the date in 'dd-mm-yyyy' format
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  return date.toLocaleDateString('en-GB', options);
};

function MyBid(props) {
  const navigate = useNavigate()

  const [showPackageData, setShowPackageData] = useState([])
  const [details, setDetails] = useState([])
  console.log(details)
  const [tabsCount, setTabsCount] = useState({});
  console.log(details)

  const [color, setColor] = useState("yellow")

  const [value, setValue] = useState(0)
  const getBidPackage = async () => {
    const token = localStorage.getItem("vendorToken")
    const res = await fetch(`${BASE_URL}bidpackage/agencybid`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()

    if (res.status === 200) {
      setShowPackageData(data.data)
      setDetails(data.data)
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setDetails([]); // Reset details to an empty array
    const packageData = showPackageData.filter((e) => {
      switch (newValue) {
        case 0:
          return true;
        case 1:
          return e.bid_status === "submit";
        case 2:
          return e.bid_status === "reject";
        case 3:
          return e.bid_status === "save";
        default:
          return false;
      }
    });
    setDetails(packageData);
  };

  useEffect(() => {
    getBidPackage()
  }, [])

  const [editedata, setEditedData] = useState("")

  const Call = async () => {
    const token = localStorage.getItem("vendorToken")
    const res = await fetch(`${BASE_URL}agency/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    const data = await res.json()
    setEditedData(data?.data?.[0])
  }

  useEffect(() => {
    Call()
  }, [])

  const handleNav = () => {
    navigate("/vendor/myprofile")
  }

  useEffect(() => {
    const calculateTabsCount = () => {
      const allCount = showPackageData.length;
      const submitCount = showPackageData.filter((e) => e.bid_status === "submit").length;
      const rejectCount = showPackageData.filter((e) => e.bid_status === "reject").length;
      const saveCount = showPackageData.filter((e) => e.bid_status === "save").length;

      setTabsCount({
        0: allCount,
        1: submitCount,
        2: rejectCount,
        3: saveCount,
      });
    };

    calculateTabsCount();
  }, [showPackageData]);

  const getStatusColor = (status) => {
    switch (status) {
      case "submit":
        return "green"
      case "reject":
        return "red"
      default:
        return "#ffc107"
    }
  }

  return (
    <>
      <Header2 />
      <div className='costum_container'>
        <My_pannel />
        <div>
        
          <ProfileSection title="My Bid List" />

          <section
            className='py-5'
            style={{ backgroundColor: "whitesmoke", marginBottom: "20px" }}
          >
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                overflowX: "auto",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label='basic tabs example'
              >
                <Tab
                  label={`All (${tabsCount[0] || 0})`}
                  sx={{
                    borderBottom: `4px solid ${value === 0 ? 'green' : 'transparent'} `,
                    color: `${value === 0 ? '#09646d' : '#09646d'}`
                  }}
                />
                <Tab
                  label={`Submit (${tabsCount[1] || 0})`}
                  sx={{
                    borderBottom: `4px solid ${value === 1 ? 'green' : 'transparent'}`,
                    color: `${value === 1 ? '#09646d' : '#09646d'}`
                  }}
                />
                <Tab
                  label={`Reject (${tabsCount[2] || 0})`}
                  sx={{
                    borderBottom: `4px solid ${value === 2 ? 'green' : 'transparent'}`,
                    color: `${value === 2 ? '#09646d' : '#09646d'}`
                  }}
                />
                <Tab
                  label={`Save (${tabsCount[3] || 0})`}
                  sx={{
                    borderBottom: `4px solid ${value === 3 ? 'green' : 'transparent'}`,
                    color: `${value === 3 ? '#09646d' : '#09646d'}`
                  }}
                />
              </Tabs>

            </Box>

            <div className='row gx-5 gy-4 me-xl-5 me-lg-5 me-sm-auto ms-auto me-auto ms-sm-auto ps-lg-3 overflow-hidden  ps-sm-0 mt-3'>
              {details &&
                details.map((ele, i) => {
                  return (
                    <>
                      <div
                        className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'
                        key={ele._id}
                      >
                        <div
                          className='row only_for_green_border p-xl-3 p-lg-3 p-md-3 p-sm-3 p-2 costum_requirments'
                          style={{
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                            height: "auto",
                          }}
                          onClick={() =>
                            navigate(`/display/custom/details/${ele.custom_requirement_id}`)
                          }
                        >
                          <div className='col-1 px-xl-2 px-lg-0 px-md-0 px-sm-0 costum_requirments_span'>
                            <span className='cmnp cmnbkg'>{i + 1}</span>
                          </div>
                          <div className='col-xl-11 col-lg-11 col-md-11 col-11 costum_requirments_center'>
                            <div className='d-flex align-items-center justify-content-between pt-2 py-1'>
                              <p className='cmnp ps-1 text-capitalize'>
                                <b>Status : </b>
                                <span
                                  style={{
                                    fontWeight: "500",
                                    color: getStatusColor(ele.bid_status),
                                    fontSize: "20px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  {ele.bid_status}
                                </span>
                              </p>
                              <p className='cmnp ps-1 text-capitalize'>
                                <b>Post On : </b>
                                <span
                                  style={{
                                    fontWeight: "500",
                                    color: getStatusColor(ele.bid_status),
                                    fontSize: "20px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  {formatDate(ele.createdAt)}
                                </span>
                              </p>
                            </div>
                            {/* <div className='d-flex align-items-center pt-2 py-1'>
                              <FontAwesomeIcon
                                icon={faUser}
                                className='cmnclr'
                              />
                              <p className='cmnp ps-1 text-capitalize'>
                                {ele.full_name}
                              </p>
                            </div> */}
                            <div className='d-flex align-items-center pt-2 py-1'>
                              <FontAwesomeIcon
                                icon={faUser}
                                className='cmnclr'
                              />
                              <p className='cmnp ps-1 text-capitalize'>
                                {ele.full_name}
                              </p>
                            </div>
                            <div className='d-flex align-item-center pt-2 py-1'>
                              <FontAwesomeIcon
                                icon={faLocationPin}
                                className='cmnclr'
                              />
                              <p className='cmnp ps-1 text-capitalize'>
                                <b>Departure : </b> {ele.departure}
                              </p>
                            </div>
                            <div className='d-flex align-item-center pt-2 py-1'>
                              <FontAwesomeIcon
                                icon={faLocationPin}
                                className='cmnclr'
                              />
                              <p className='cmnp ps-1 text-capitalize'>
                                <b>Destination : </b> {ele.destination}
                              </p>
                            </div>
                            <div className='d-flex align-item-center pt-2 py-1'>
                              <FontAwesomeIcon
                                icon={faCalendar}
                                className='cmnclr'
                              />
                              <p className='cmnp ps-1'>
                                <b>Travel Between : </b>
                                {formatDate(ele.start_date)} to{" "}
                                {formatDate(ele.end_date)}
                              </p>
                            </div>
                            {/* <div className="d-flex justify-content-between align-items-end">
                              <div className='py-1'>
                                <span
                                  className='cmnp'
                                  style={{ color: "#B8B8B8" }}
                                >
                                  Package ID : {ele._id}
                                </span>
                              </div>
                            </div> */}
                          </div>
                          {/* <div className='col-xl-4 col-lg-12 col-md-12 col-12 px-0'> */}
                          <div className='for_order_only'>
                            <button
                              href=''
                              className='costum_requirments_footer_button'
                            >
                              <FontAwesomeIcon
                                icon={faTag}
                                className='me-2'
                              />
                              My Bid : <FontAwesomeIcon
                                icon={faIndianRupeeSign}
                                className=''
                              /> {ele?.total_amount}
                            </button>
                          </div>
                          {/* </div> */}
                        </div>
                      </div>
                    </>
                  )
                })}
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

export default MyBid
