import { Container, Row } from "react-bootstrap"
import React, { useEffect, useState } from "react"
import "../Project/css/index1.css"
import {
  faCheck,
  faCheckCircle,
  faStar,
  faXmark,
  faXmarkCircle,
  faHandshakeAlt
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useParams } from "react-router-dom"
import { BASE_URL } from "../BASE_URL"
import DOMPurify from "dompurify"
import { Rating } from "@mui/material";

const calculateAge = (dob) => {
  const birthDate = new Date(dob);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

const formatDate = (dateString) => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return 'Invalid date format';
  }

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};


function Book_packega1({ details }) {

  const { id } = useParams();
  const [reviews, setReviews] = useState("");

  const Reviews = async () => {
    const token = localStorage.getItem("vendorToken");
    const res = await fetch(`${BASE_URL}review/allreview`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const data = await res.json();
    const bookPackage = data?.data?.[0]?.book_package?.find(bp => bp?._id === id);
    setReviews(bookPackage?.review)
  };

  useEffect(() => {
    Reviews();
  }, []);

  return (
    <>
      <section className=''>
        {details.map((ele, index) => {
          {/* const categoryDetails = ele.category.split(",") */ }

          return (
            <>
              <div className='costum_container' key={index}>
                <div
                  className='green_border book_packega_1 p-3'
                  style={{ backgroundColor: "#ffffff" }}
                >
                  <div className='py-2'>
                    <h6 className='cmnclr font-weight-bold cmnhr'>
                      User Details
                    </h6>
                  </div>

                  <div className='px-xl-5 px-lg-4 px-md-3 px-sm-2 px-1 py-2'>
                    <div className='cmn p-2 px-3'>
                      <div style={{ borderBottom: "1px solid black" }}>
                        <Row>
                          <div className='col-lg-6 col-md-6 '>
                            <div className='ps-sm-4 py-2'>
                              <div className='py-1'>
                                <h6 className='cmnp cmnclr1'>Name</h6>
                                <p className='cmnp cmnclr font-weight-bolder text-capitalize'>
                                  {ele.user_name}
                                </p>
                              </div>
                              <div className='py-1'>
                                <h6 className='cmnp cmnclr1'>Mobile no.</h6>
                                <p className='cmnp cmnclr font-weight-bolder'>
                                  {ele.contact_number}
                                </p>
                              </div>
                              <div className='py-1'>
                                <h6 className='cmnp cmnclr1'>Email ID</h6>
                                <p className='cmnp cmnclr font-weight-bolder'>
                                  {ele.email_id}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className='col-lg-6 col-md-6 '>
                            <div className='ps-0 ps-md-4 py-2'>
                              <div className='py-1'>
                                <h6 className='cmnp cmnclr1'>Gender</h6>
                                <p className='cmnp cmnclr font-weight-bolder'>
                                  {ele?.customer?.map((e) => {
                                    return (
                                      <>
                                        {e?.customer_detail.map((e1) => {
                                          return <>{e1.gender}</>
                                        })}
                                      </>
                                    )
                                  })}
                                </p>
                              </div>
                              <div className='py-1'>
                                <h6 className='cmnp cmnclr1'>State</h6>
                                <p className='cmnp cmnclr font-weight-bolder text-capitalize'>
                                  {ele.state}
                                </p>
                              </div>
                              <div className='py-1'>
                                <h6 className='cmnp cmnclr1'>City</h6>
                                <p className='cmnp cmnclr font-weight-bolder text-capitalize'>
                                  {ele.city}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>
                      {reviews && <div className='col-lg-6 col-md-6 cmnp cmnclr font-weight-bolder' style={{ paddingLeft: "20px", border: "1px solid black", minHeight: "100px", marginTop: "10px", paddingTop: "10px" }}>
                        <p className='cmnp cmnclr font-weight-bolder' style={{ display: "flex" }}>
                          <div>
                            Rating:
                          </div>
                          <div>
                            <Rating
                              name="average-rating"
                              value={reviews.star || 0}
                              readOnly
                            />
                          </div>
                        </p>
                        <div>{reviews.comment_box}</div>
                      </div>}
                    </div>
                  </div>

                  <div className='py-2'>
                    <h6 className='cmnclr font-weight-bold cmnhr'>
                      Travellers Details
                    </h6>
                  </div>

                  <div className='px-xl-5 px-lg-4 px-md-3 px-sm-2 px-1 py-2'>
                    <div className='cmn p-2 px-3'>
                      <div>
                        <table className="table table-bordered mt-2">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">Full Name</th>
                              <th scope="col">Age</th>
                              <th scope="col">Gender</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ele?.travel_details && ele?.travel_details?.map((e) => (
                              <tr>
                                <td>{e?.first_name}{""}{e?.last_name}</td>
                                <td>{calculateAge(e?.dob)}</td>
                                <td>{e?.gender}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>


                  <div className='py-2'>
                    <h6 className='cmnclr font-weight-bold cmnhr'>
                      Package Details
                    </h6>
                  </div>

                  <div>
                    <div className='px-xl-5 px-lg-4 px-md-3 px-sm-2 px-1 py-2'>
                      <div className='cmn p-2 px-3'>
                        <div>
                          <Row>
                            <div className='col-lg-6 col-md-6 '>
                              <div className='ps-sm-4 ps-1 py-2'>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1'>
                                    Package category
                                  </h6>
                                  <p className='cmnp cmnclr font-weight-bolder'>
                                    {ele.category.join(", ")}
                                  </p>
                                </div>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1'>
                                    Trip Duration
                                  </h6>
                                  <p className='cmnp cmnclr font-weight-bolder'>
                                    {formatDate(ele?.approx_start_date)} to {formatDate(ele?.approx_end_date)}
                                  </p>
                                </div>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1'>
                                    Total Travellers
                                  </h6>
                                  <p className='cmnp cmnclr font-weight-bolder'>
                                    {ele.total_person}
                                  </p>
                                </div>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1'>Total Childs</h6>
                                  <p className='cmnp cmnclr font-weight-bolder'>
                                    {ele.total_child}
                                  </p>
                                </div>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1'>Total Days & Nights</h6>
                                  <p className='cmnp cmnclr font-weight-bolder'>
                                    {ele.totaldays}D & {ele.totalnight}N
                                  </p>
                                </div>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1'>Hotel Type</h6>
                                  <p className='cmnp cmnclr font-weight-bolder'>
                                    {ele.hoteltype}
                                  </p>
                                </div>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1'>Sightseeing</h6>
                                  <p className='cmnp cmnclr font-weight-bolder'>
                                    {ele.siteseeing}
                                  </p>
                                </div>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1'>Meal</h6>
                                  <p className='cmnp cmnclr font-weight-bolder'>
                                    {" "}
                                    {ele.meal.map((e) => e).join(", ")}
                                  </p>
                                </div>
                                {ele.othere_requirement !== "" && (
                                  <div className='py-1'>
                                    <h6 className='cmnp cmnclr1'>Other Requirment</h6>
                                    <p className='cmnp cmnclr font-weight-bolder'>
                                      {ele.othere_requirement}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className='col-lg-6 col-md-6 '>
                              <div className='ps-sm-4 ps-1 py-2'>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1'>Package</h6>
                                  <p className='cmnp cmnclr font-weight-bolder'>
                                    {ele.departure} -
                                    {ele.destination || ele.package_details[0].destination[0].destination_name}
                                  </p>
                                </div>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1'>Total Amount</h6>
                                  <p className='cmnp cmnclr font-weight-bolder'>
                                    {ele?.total_amount}
                                  </p>
                                </div>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1'>Total Adults</h6>
                                  <p className='cmnp cmnclr font-weight-bolder'>
                                    {ele.total_adult}
                                  </p>
                                </div>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1'>Total Infants</h6>
                                  <p className='cmnp cmnclr font-weight-bolder'>
                                    {ele.total_infant}
                                  </p>
                                </div>
                                {/* <div className='py-1'>
                                  <h6 className='cmnp cmnclr1'>Personal Care</h6>
                                  <p className='cmnp cmnclr font-weight-bolder'>
                                    {ele.personal_care}
                                  </p>
                                </div> */}
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1'>Room Sharing</h6>
                                  <p className='cmnp cmnclr font-weight-bolder'>
                                    {ele.room_sharing}
                                  </p>
                                </div>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1'>Travel By</h6>
                                  <p className='cmnp cmnclr font-weight-bolder'>
                                    {ele.transport_mode}
                                  </p>
                                </div>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1'>Meal Type</h6>
                                  <p className='cmnp cmnclr font-weight-bolder'>
                                    {" "}
                                    {ele.meal_type.map((e) => e).join(", ")}
                                  </p>
                                </div>
                                {/* <div className='py-1'>
                                  <h6 className='cmnp cmnclr1'>Review</h6>
                                  <p className='cmnp cmnclr font-weight-bolder'>
                                    Statick
                                  </p>
                                </div> */}
                              </div>
                            </div>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className='py-2'>
                    <h6 className='cmnclr font-weight-bold cmnhr1'>Overview</h6>
                  </div>

                  <div className='py-2'>
                    <div className='px-3 text-justify'>
                      <li className='py-1 cmnli'>
                        North Goa - Nestled at the western coast of the country,
                        Goa provides a totally different experience with its
                        alluring beaches and crazy nightlife. Celebrate the
                        start to your new journey and experience complete bliss
                        with our customizable 4 nights and 5 days Goa honeymoon
                        tour package. With this romantic Goa trip itinerary, you
                        will visit the mesmerizing attractions of North and
                        South Goa. Relish a delicious slice of leisure in the
                        loving company of your soulmate in this nature kissed
                        land.
                      </li>
                      <li className='py-1 cmnli'>
                        Opt our Goa couple tour package, and escape to this
                        hippie state- one of the most popular tourist
                        destinations amongst travellers from across the globe.
                        Your honeymoon in Goa starts with a visit to the
                        picture-perfect beaches of Goa like Calangute Beach,
                        Baga Beach, Miramar Beach, and Anjuna Beach. Swaying
                        coconut palm trees and the golden sandy beds invited you
                        for a long walk with your better half on your romantic
                        tour. Walk hand in hand over the sandy lagoons, and into
                        the secluded caves that are perfect for picnics and sun
                        tanning.
                      </li>
                      <li className='py-1 cmnli'>
                        The best part about booking a trip with us is that our 4
                        nights 5 days Goa couple packages can be easily
                        customized as per your pocket and preferences, imparting
                        you a vacation together, just as you want. Your trip of
                        togetherness is laced by other attractions such as a
                        romantic cruise, candlelight dinner, beach hopping, and
                        an exciting nightlife. If you and your beloved are
                        interested to have a glimpse of the past, head to Fort
                        Aguada to absorb the Portuguese charm.
                      </li>
                    </div>
                  </div> */}

                  <div className='py-2'>
                    <h6 className='cmnclr font-weight-bold cmnhr1'>Services</h6>
                  </div>

                  <div className='py-2'>
                    <div className=' p-3'>
                      <div>
                        <Row>
                          <div className='col-lg-6 col-md-6 col-dm-6'>
                            <h6>
                              <FontAwesomeIcon icon={faCheckCircle} /> Included
                            </h6>
                            <div className='cmn p-3'>
                              <p className='cmnp py-1 ruppe_text'>
                                {ele.booked_include.map((e) => (
                                  <div className="d-flex gap-2">
                                    <FontAwesomeIcon icon={faCheck} className="mb-0 pt-1" />
                                    <span
                                      className="mb-0"
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                          e.include_services_value,
                                        ),
                                      }}
                                    />
                                  </div>
                                ))}
                              </p>
                            </div>
                          </div>
                          <div className='col-lg-6 col-md-6 col-dm-6'>
                            <h6>
                              <FontAwesomeIcon icon={faXmarkCircle} /> Excluded
                            </h6>
                            <div className='cmn p-3'>
                              <p className='cmnp py-1 ruppe_text1'>
                                {ele.booked_exclude.map((e) => (
                                  <div className="d-flex gap-2">
                                    <FontAwesomeIcon icon={faXmark} className="mb-0 pt-1" />
                                    <span
                                      className="mb-0"
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                          e.exclude_services_value,
                                        ),
                                      }}
                                    />
                                  </div>
                                ))}
                              </p>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div className='py-2'>
                    <h6 className='cmnclr font-weight-bold cmnhr1'>
                      Itinerary
                    </h6>
                  </div>

                  <section className='text-start'>
                    <div className=''>
                      <div className='my-3'>
                        <button className='w-100 over-btn'>
                          <FontAwesomeIcon
                            icon={faHandshakeAlt}
                            className='me-2'
                          />{" "}
                          Itinerary
                        </button>
                      </div>
                      <div className='cmn cmn__3'>
                        {ele?.booked_itinerary.map((e3, i) => {
                          return (
                            <>
                              <div className="py-2">
                                <h6 className="cmntext mb-2">Day {i + 1}</h6>
                                <div className='cmn p-2'>

                                  <Row className='align-items-start'>
                                    <div className='col-lg-9'>
                                      <div className='py-1'>
                                        <h6 className='cmnp cmnclr1'>Title</h6>
                                        <p className='cmnp cmnclr font-weight-bolder'>
                                          {e3.title}{" "}
                                        </p>
                                      </div>
                                      <div className='py-1'>
                                        <h6 className='cmnp cmnclr1'>Activity</h6>
                                        <p className='cmnp cmnclr font-weight-bolder'>
                                          <div
                                            style={{ textAlign: "justify" }}
                                            dangerouslySetInnerHTML={{
                                              __html: e3.activity,
                                            }}
                                          />
                                        </p>
                                      </div>
                                    </div>
                                    {e3.photo && <div className='col-lg-3'>
                                      <div className='text-center py-2'>
                                        <img src={e3.photo} className='img-fluid' style={{ height: "120px", width: "120px" }} />
                                      </div>
                                    </div>}
                                  </Row>


                                </div>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </div>
                  </section>

                  <section className='pt-4'>
                    <div className='hotels-section'>
                      <div className='last-hotels'>
                        <h3 className="text-start">Hotels</h3>

                        {/* <!--------------------------- part-1 ---------------------------------------------> */}

                        <div className='hotel-president'>
                          {ele?.hotel_itienrary && ele?.hotel_itienrary?.map((e, index) => (
                            <div className='row gx-5 p-2 second-changes'>
                              <div className='col-xl-6 col-12'>
                                <div id={`carouselExampleCaptions-${index}`} className="carousel slide" data-bs-ride="carousel">
                                  <div className="carousel-indicators">
                                    {e?.hotel_photo?.map((_, idx) => (
                                      <button
                                        key={idx}
                                        type="button"
                                        data-bs-target={`#carouselExampleCaptions-${index}`}
                                        data-bs-slide-to={idx}
                                        className={idx === 0 ? "active" : ""}
                                        aria-current={idx === 0 ? "true" : "false"}
                                        aria-label={`Slide ${idx + 1}`}
                                      ></button>
                                    ))}
                                  </div>
                                  <div className="carousel-inner">
                                    {e?.hotel_photo?.map((ele, idx) => (
                                      <div key={idx} className={`carousel-item ${idx === 0 ? "active" : ""}`}>
                                        <img src={ele} className="d-block w-100" alt="..." />
                                      </div>
                                    ))}
                                  </div>
                                  <button className="carousel-control-prev" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                  </button>
                                  <button className="carousel-control-next" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                  </button>
                                </div>
                              </div>
                              <div className='col-xl-6 col-12 first-change'>
                                <div className='d-inline-block mb-md-2'>
                                  <span className='d-flex day1-2  text-left align-items-center'>
                                    <div className='day-1-2 py-1 font-changes'>
                                      <h6>Day {e?.days}</h6>
                                    </div>
                                    <div className=' font-changes'>
                                      <h6 className='mb-1 pe-5'>
                                        {e?.hotel_name}
                                      </h6>
                                      <p className='mb-0 text-start'>
                                        <i class='fa-solid fa-location-dot me-2'></i>
                                        Dalhousie
                                      </p>
                                    </div>
                                  </span>
                                </div>
                                <div className='hotels-star'>
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className='p-1'
                                    style={{ color: "yellow" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className='p-1'
                                    style={{ color: "yellow" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className='p-1'
                                    style={{ color: "yellow" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className='p-1'
                                    style={{ color: "yellow" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className='p-1'
                                    style={{ color: "yellow" }}
                                  />
                                </div>
                                <div className='hotels-footer'>
                                  <p>
                                    {e?.hotel_address} <br />
                                    {e?.hotel_city},{e?.hotel_state}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </section>

                </div>
              </div>
            </>
          )
        })}
      </section>
    </>
  )
}

export default Book_packega1
