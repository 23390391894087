import {
  faArrowRight,
  faBus,
  faCar,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faIndianRupeeSign,
  faPlane,
  faTrain,
  faUser,
  faXmark,
  faBrush,
  faCalendar,
  faHandHoldingDroplet,
  faHandsHoldingCircle,
  faHandshakeAlt,
  faHotel,
  faLocationPinLock,
  faPaperclip,
  faStar,
  faStarHalfAlt,
  faUserDoctor,
  faUsers
} from "@fortawesome/free-solid-svg-icons"
import { faXmarkCircle } from "@fortawesome/free-regular-svg-icons"
import { faTrainSubway } from "@fortawesome/free-solid-svg-icons"
import { faBinoculars } from "@fortawesome/free-solid-svg-icons"
import { faUtensils } from "@fortawesome/free-solid-svg-icons"
import { faTaxi } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Modal, Row } from "react-bootstrap"
import Header from "./Header"
import "./css/index1.css"
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-regular-svg-icons"
import { useState, useEffect } from "react"
import { useLocation, useParams } from "react-router-dom"
import { BASE_URL } from "../BASE_URL"
import Rating from "@mui/material/Rating"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useRef } from "react"
import Footer from "./Footer"
import DOMPurify from "dompurify"
import "./css/booked-package.css"

const calculateAge = (dob) => {
  const birthDate = new Date(dob);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  // If the current month is before the birth month or it's the birth month but the current day is before the birth day, subtract 1 from the age
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

const formatDate = (dateString) => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return 'Invalid date format';
  }

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

function getYearFromDate(dateString) {
  const birthDate = new Date(dateString);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  const dayDifference = today.getDate() - birthDate.getDate();

  // Adjust age if the current date is before the birth date this year
  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--;
  }

  return age;
}

const Booked_package_fulDetail = () => {
  const { id } = useParams();
  const [showReview, setShowReview] = useState(false);
  const [review, setReview] = useState(null); // New state to store the review data
  const [isUpdated, setIsUpdated] = useState(false); // State to track if the review was updated // State to track if the review was updated
  const [bookedData, setBookedData] = useState([]);
  const [booked, setBooked] = useState("");
  const [reviewid, setReviewId] = useState("");
  const [agencyId, setAgencyId] = useState("");
  const [agencyAverageStar, setAgencyAverageStar] = useState(0);
  const [agencyTotalReview, setAgencyTotalReview] = useState(0);
  const [stars, setStars] = useState([]);
  const [paidAmount, setPaidAmount] = useState(null);

  const location = useLocation();

  const BookedPackage = async () => {
    const token = localStorage.getItem("userToken");
    const res = await fetch(`${BASE_URL}bookpackage/jaydev`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    console.log(data?.data)
    setBookedData(data.data);

    const matchingPackage = data?.data?.find((item) => item._id === id);

    const sumPaidAmount = matchingPackage?.payment.reduce((total, payment) => total + payment.paid_amount, 0);

    setPaidAmount(sumPaidAmount)
  };

  useEffect(() => {
    BookedPackage();
  }, []);

  useEffect(() => {
    const matchingPackage = bookedData.find((item) => item._id === id);
    if (matchingPackage) {
      setBooked(matchingPackage);
    }
  }, [bookedData, id]);

  const fetchReview = async () => {
    const token = localStorage.getItem("userToken");
    const res = await fetch(`${BASE_URL}review?book_package_id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const data = await res.json();
    if (data.data.length !== 0) {
      setReview(data.data[0]);
      setgotComment(data.data[0].comment_box);
      setgotRating(data.data[0].star);
      setReviewId(data.data[0]._id);
    }
  };

  useEffect(() => {
    setAgencyId(booked.agency_id);
    const currentDate = new Date();
    if (booked && currentDate > new Date(booked.approx_start_date)) {
      setShowReview(true);
      fetchReview(); // Fetch review if the current date is past the start date
    }
  }, [booked, id]);

  const AgencyReviews = async () => {
    const token = localStorage.getItem("userToken");

    const res = await fetch(`${BASE_URL}review/agencyallreview?_id=${agencyId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
    })

    const data = await res.json()
    setAgencyAverageStar((data.metadata.average_star_rating)?.toFixed(1));
    setAgencyTotalReview(data.metadata.total_review);

  }

  useEffect(() => {
    if (agencyId) {
      AgencyReviews()
    }
  }, [agencyId])

  useEffect(() => {
    const fullStars = Math.floor(agencyAverageStar);
    const hasHalfStar = agencyAverageStar % 1 !== 0;
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

    const starElements = [];
    for (let i = 0; i < fullStars; i++) {
      starElements.push(
        <FontAwesomeIcon
          key={`full-${i}`}
          icon={faStar}
          className='CuStom_package_icon'
        />
      );
    }
    if (hasHalfStar) {
      starElements.push(
        <FontAwesomeIcon
          key='half'
          icon={faStarHalfAlt}
          className='CuStom_package_icon'
        />
      );
    }
    for (let i = 0; i < emptyStars; i++) {
      starElements.push(
        <FontAwesomeIcon
          key={`empty-${i}`}
          icon={faStar}
          className='CuStom_package_icon'
          style={{ color: '#B8B8B8' }}
        />
      );
    }

    setStars(starElements);
  }, [agencyAverageStar]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setRating(gotrating);
    setComment(gotcomment);
    setShow(true);
  }
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState("");

  const [gotrating, setgotRating] = useState(null);
  const [gotcomment, setgotComment] = useState(null);




  const handleRatingChange = (event) => {
    setRating(event.target.value);
    setIsUpdated(true); // Enable update button when rating changes
  };


  const handleSubmit = async () => {
    const token = localStorage.getItem("userToken");
    if (!rating || !comment) {
      toast.error("Please fill all details!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    const res = await fetch(`${BASE_URL}review`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        book_package_id: id,
        star: rating,
        comment_box: comment,
      }),
    });

    const data = await res.json();
    handleClose();

    if (data.code === 200) {
      toast.success("Review submitted successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });

    } else {
      toast.error(data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("userToken");

    if (!comment || !rating) {
      toast.error("Please fill all details!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    const res = await fetch(`${BASE_URL}review/updatereview?_id=${reviewid}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        book_package_id: id,
        star: rating,
        comment_box: comment,
      }),
    });

    const data = await res.json();
    handleClose();

    if (data.code === 200) {
      toast.success("Review updated successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });


    } else {
      toast.error(data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const overRef = useRef(null);
  const itenarary = useRef(null);
  const services = useRef(null);

  const scrollTo = (section) => {
    let targetRef;
    switch (section) {
      case "overview":
        targetRef = overRef;
        break;
      case "services":
        targetRef = services;
        break;
      case "itenarary":
        targetRef = itenarary;
        break;
      case "privacy":
        targetRef = privacy;
        break;
      default:
        targetRef = null;
    }
    if (targetRef) {
      window.scroll({
        top: targetRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const [privacy, setPrivacy] = useState([]);

  const privacypolicies = async () => {
    const res = await fetch(`${BASE_URL}policy`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    setPrivacy(data.data[2]);
  };

  useEffect(() => {
    privacypolicies();
  }, []);

  const url = location.pathname;

  const [paymentAmount, setPaymentAmount] = useState(null)
  console.log(paymentAmount)

  const handlePrice = (e) => {
    setPaymentAmount(e.target.value)
  }

  const hadlePayment = async () => {
    const token = localStorage.getItem("userToken");


    const res = await fetch(`${BASE_URL}payment/update/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        paid_amount: paymentAmount,
        link: url,
      }),
    });

    const data = await res.json();
    window.location.href = data?.data?.url
  }

  return (
    <div>
      <Header />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="py-4 px-3">
            <div>
              <div className="d-flex" style={{ gap: "15px" }}>
                <h5>Ratings</h5>
                <Rating
                  name="simple-controlled"
                  value={rating}
                  onChange={(e) => {
                    handleRatingChange(e);
                  }}
                />
              </div>
            </div>
            <div className="mt-3 mb-3">
              <h5>Comment</h5>
              <div>
                <textarea
                  name=""
                  id=""
                  cols="50"
                  rows="5"
                  className="ps-2 py-2"
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                    setIsUpdated(true); // Enable update button when comment changes
                  }}
                ></textarea>
              </div>
            </div>
            <div className="d-flex justify-content-center" style={{ gap: "10px" }}>
              <button onClick={handleClose} className="button-251">
                Close
              </button>
              {review ? (
                <button
                  onClick={handleUpdate}
                  className="button-25"
                  disabled={!isUpdated}
                >
                  Update
                </button>
              ) : (
                <button onClick={handleSubmit} className="button-25">
                  Submit
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {!booked && <p>loading...</p>}

      {booked && (
        <section className='pt-sm-5 pt-2 pb-sm-3 pb-2'>
          <div className='container-customes'>
            <div className=''>
              <Row className='included_service'>
                <div className='col-xl-8 col-lg-6 col-12 text-center m-0 posi-first '>
                  <div className='CuStom_package_image'>
                    <img
                      src='/C-packega.png'
                      className='w-100 rounded h-md-50 meadia-for-package-details-img'
                      alt=''
                    />
                  </div>
                  <div className='mt-3 hover-buttons-media'>
                    <div className='me-3 mb-3'>
                      <button
                        class='button-47'
                        role='button'
                        onClick={() => scrollTo("overview")}
                      >
                        Overview
                      </button>
                    </div>
                    <div className='me-3 mb-3'>
                      <button
                        class='button-47'
                        role='button'
                        onClick={() => scrollTo("services")}
                      >
                        Services
                      </button>
                    </div>
                    <div className='me-3 mb-3'>
                      <button
                        class='button-47'
                        role='button'
                        onClick={() => scrollTo("itenarary")}
                      >
                        Itinerary
                      </button>
                    </div>
                    <div className='me-3 mb-3'>
                      <button
                        class='button-47'
                        role='button'
                        onClick={() => scrollTo("privacy")}
                      >
                        Policy
                      </button>
                    </div>
                    <ToastContainer />
                  </div>

                  <section className='mb-3 mt-3' ref={overRef}>
                    <div className=''>
                      <div className='overview-btn mb-4' ref={overRef}>
                        <button className='mb-3 mt-sm-3 mt-2'>Overview</button>
                      </div>
                    </div>
                  </section>

                  <section className='text-start'>
                    <div className=''>
                      <Row>
                        <div className='col-12 col-xl-6 mb-3'>
                          <div className='amh_to_hima'>
                            <div>
                              <h6 className='pb-2 mb-0 cmntext text-capitalize'>
                                {booked.departure} to {booked.package_destination}
                              </h6>
                            </div>
                            <div className=''>
                              <div className='row mb-3'>
                                <div className='col-12 px-0'>
                                  <div className='amh_to_hima_border ms-3 px-xl-3 px-lg-4 px-md-2 px-sm-2 px-2 py-2'>
                                    <p className='cmnp py-1 media-for-booked-data-table d-flex '>
                                      <b>Travellers Detail</b>
                                    </p>
                                    {booked?.travel_details && booked?.travel_details?.map((e) => (
                                      <p className='cmnp py-1 media-for-booked-data-table d-flex '>
                                        <FontAwesomeIcon
                                          icon={faUser}
                                          className='cmnclr'
                                        />{" "}
                                        <b>{e?.first_name} {e?.last_name} ({calculateAge(e?.dob)} yrs)</b>
                                        <span className="ms-auto">Male</span>
                                      </p>
                                    ))}
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-12 px-0'>
                                  <div className='amh_to_hima_border ms-3 px-xl-3 px-lg-4 px-md-2 px-sm-2 px-2 py-2'>
                                    <p className='cmnp py-1 media-for-booked-data-table'>
                                      <FontAwesomeIcon
                                        icon={faUser}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Adult (12+yrs)</b> :{" "}
                                      {booked.total_adult}
                                    </p>
                                    <p className='cmnp py-1 media-for-booked-data-table'>
                                      <FontAwesomeIcon
                                        icon={faHandHoldingDroplet}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Infant(2 to 12 years)</b> :{" "}
                                      {booked.total_infant}
                                    </p>
                                    <p className='cmnp py-1 media-for-booked-data-table'>
                                      <FontAwesomeIcon
                                        icon={faHandHoldingDroplet}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Kids(0 to 12 years)</b> :{" "}
                                      {booked.total_child}
                                    </p>
                                    <p className='cmnp py-1 media-for-booked-data-table'>
                                      <FontAwesomeIcon
                                        icon={faUsers}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Total Travellers</b>:{" "}
                                      {parseInt(booked.total_adult) +
                                        parseInt(booked.total_child) +
                                        parseInt(booked.total_infant)}
                                    </p>
                                    {booked.personal_care && booked.personal_care !== "" && (
                                      <p className='cmnp py-1 media-for-booked-data-table'>
                                        <FontAwesomeIcon
                                          icon={faHandsHoldingCircle}
                                          className='cmnclr'
                                        />{" "}
                                        <b>Any Traveller Needs Personal Care?</b>{" "}
                                        :{" "}
                                        {booked.personal_care}
                                      </p>
                                    )}
                                    <FontAwesomeIcon
                                      icon={faHandsHoldingCircle}
                                      className='cmnclr'
                                    />{" "}
                                    <b>Sightseeing</b>{" "}
                                    :{" "}
                                    {booked?.siteseeing}
                                    <p>

                                    </p>
                                    <p className='cmnp py-1 media-for-booked-data-table'>
                                      <FontAwesomeIcon
                                        icon={faCalendar}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Total Days/Night</b> :{" "}
                                      {booked.totaldays}/{booked.totalnight}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className='mt-3 mb-2'>
                                <h6 className='cmntext'>
                                  <FontAwesomeIcon
                                    icon={faCalendar}
                                    className='cmnclr'
                                  />{" "}
                                  Approx Departure Between{" "}
                                </h6>
                              </div>
                              <div className='cmn'>
                                <p className='cmnp ms-4 py-2'>
                                  {booked?.approx_start_date?.slice(0, 10)} to{" "}
                                  {booked?.approx_end_date?.slice(0, 10)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-xl-6 mb-3'>
                          <div className='amh_to_hima'>
                            <div>
                              <h6 className=' pb-2 mb-0 cmntext'>
                                <FontAwesomeIcon
                                  icon={faHotel}
                                  className='cmnclr'
                                />{" "}
                                Hotel & Food
                              </h6>
                            </div>
                            <div className=''>
                              <div className='row'>
                                <div className='col-12 px-0'>
                                  <div className='amh_to_hima_border ms-3 px-sm-4 px-2 py-2'>

                                    <p className='cmnp py-1 media-for-booked-data-table'>
                                      <FontAwesomeIcon
                                        icon={faHotel}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Hotel Type</b> : {booked.hoteltype}
                                    </p>
                                    <p className='cmnp py-1 media-for-booked-data-table'>
                                      <FontAwesomeIcon
                                        icon={faHotel}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Room Sharing</b> : {booked?.package_room_sharing}
                                    </p>
                                    <p className='cmnp py-1 media-for-booked-data-table'>
                                      <FontAwesomeIcon
                                        icon={faUserDoctor}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Meal</b> :{" "}
                                      {booked &&
                                        booked.meal
                                          .toString()
                                          .split(",")
                                          .join(", ")}
                                    </p>
                                    <p className='cmnp py-1 media-for-booked-data-table'>
                                      <FontAwesomeIcon
                                        icon={faUserDoctor}
                                        className='cmnclr'
                                      />{" "}
                                      <b>Meal Type</b> :{" "}
                                      {booked &&
                                        booked.meal_type
                                          .toString()
                                          .split(",")
                                          .join(", ")}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='mt-3 mb-2'>
                              <h6 className='cmntext'>
                                <FontAwesomeIcon
                                  icon={faLocationPinLock}
                                  className='cmnclr'
                                />{" "}
                                Travel By
                              </h6>
                            </div>
                            <div className='cmn'>
                              <p className='cmnp ms-4 py-2'>
                                {booked.transport_mode}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Row>

                      <div className='cmn  text-start mt-3'>
                        <div className='mt-3 mb-2 text-start cmnp ms-4 pb-2'>
                          <h6 className='cmntext'>
                            <FontAwesomeIcon
                              icon={faPaperclip}
                              className='cmnclr'
                            />{" "}
                            Additional Requirement By Traveller
                          </h6>
                        </div>
                        <p className='cmnp ms-4 py-2 mb-3'>
                          {booked.othere_requirement}
                        </p>
                      </div>
                    </div>
                  </section>

                  <section className='my-3' ref={services}>
                    <div className=''>
                      <div className='mb-3'>
                        <button className='w-100 over-btn'>
                          <FontAwesomeIcon className='me-3' icon={faBrush} />{" "}
                          Services
                        </button>
                      </div>
                      <div className='cmn cmn_2 row text-start included_service'>
                        <div className='col-12 col-lg-6'>
                          <h6 className='cmntext mb-2'>
                            <FontAwesomeIcon icon={faCircleCheck} /> Included
                          </h6>
                          <div className='cmn package_service mb-3'>
                            <p style={{ color: "#00B707" }} className='cmnp'>
                              {booked.booked_include &&
                                booked.booked_include.map((e1, index) => (
                                  <div key={index} className='exclude_service'>
                                    <FontAwesomeIcon
                                      icon={faCheck}
                                      className='me-2'
                                    />
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                          e1.include_services_value,
                                        ),
                                      }}
                                    />
                                    { }
                                  </div>
                                ))}
                            </p>
                          </div>
                        </div>
                        <div className='col-12 col-lg-6'>
                          <h6 className='cmntext'>
                            <FontAwesomeIcon icon={faXmarkCircle} /> Excluded
                          </h6>
                          <div className='cmn package_service'>
                            <p style={{ color: "red" }} className='cmnp'>
                              {booked?.booked_exclude &&
                                booked.booked_exclude.map((e2) => (
                                  <div key={e2} className='exclude_service'>
                                    <FontAwesomeIcon
                                      icon={faXmarkCircle}
                                      className='me-2'
                                    />

                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                          e2.exclude_services_value,
                                        ),
                                      }}
                                    />
                                    { }
                                  </div>
                                ))}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className='text-start' ref={itenarary}>
                    <div className=''>
                      <div className='my-3'>
                        <button className='w-100 over-btn'>
                          <FontAwesomeIcon
                            icon={faHandshakeAlt}
                            className='me-2'
                          />{" "}
                          Itinerary
                        </button>
                      </div>
                      <div className='cmn cmn__3'>
                        {booked && booked?.booked_itinerary.map((e3, i) => {
                          return (
                            <>
                              <div className="py-2">
                                <h6 className="cmntext mb-2">Day {i + 1}</h6>
                                <div className='cmn p-2'>

                                  <Row className='align-items-start'>
                                    <div className='col-lg-9'>
                                      <div className='py-1'>
                                        <h6 className='cmnp cmnclr1'>Title</h6>
                                        <p className='cmnp cmnclr font-weight-bolder'>
                                          {e3.title}{" "}
                                        </p>
                                      </div>
                                      <div className='py-1'>
                                        <h6 className='cmnp cmnclr1'>Activity</h6>
                                        <p className='cmnp cmnclr font-weight-bolder'>
                                          <div
                                            style={{ textAlign: "justify" }}
                                            dangerouslySetInnerHTML={{
                                              __html: e3.activity,
                                            }}
                                          />
                                        </p>
                                      </div>
                                    </div>
                                    {e3.photo && <div className='col-lg-3'>
                                      <div className='text-center py-2'>
                                        <img src={e3.photo} className='img-fluid' style={{ height: "120px", width: "120px" }} />
                                      </div>
                                    </div>}
                                  </Row>


                                </div>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </div>
                  </section>

                  <section className='pt-4'>
                    <div className='hotels-section'>
                      <div className='last-hotels'>
                        <h3 className="text-start">Hotels</h3>

                        {/* <!--------------------------- part-1 ---------------------------------------------> */}

                        <div className='hotel-president'>
                          {booked?.hotel_itienrary && booked?.hotel_itienrary?.map((e, index) => (
                            <div className='row gx-5 p-2 second-changes'>
                              <div className='col-xl-6 col-12'>
                                <div id={`carouselExampleCaptions-${index}`} className="carousel slide" data-bs-ride="carousel">
                                  <div className="carousel-indicators">
                                    {e?.hotel_photo?.map((_, idx) => (
                                      <button
                                        key={idx}
                                        type="button"
                                        data-bs-target={`#carouselExampleCaptions-${index}`}
                                        data-bs-slide-to={idx}
                                        className={idx === 0 ? "active" : ""}
                                        aria-current={idx === 0 ? "true" : "false"}
                                        aria-label={`Slide ${idx + 1}`}
                                      ></button>
                                    ))}
                                  </div>
                                  <div className="carousel-inner">
                                    {e?.hotel_photo?.map((ele, idx) => (
                                      <div key={idx} className={`carousel-item ${idx === 0 ? "active" : ""}`}>
                                        <img src={ele} className="d-block w-100" alt="..." />
                                      </div>
                                    ))}
                                  </div>
                                  <button className="carousel-control-prev" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                  </button>
                                  <button className="carousel-control-next" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                  </button>
                                </div>
                              </div>
                              <div className='col-xl-6 col-12 first-change'>
                                <div className='d-inline-block mb-md-2'>
                                  <span className='d-flex day1-2  text-left align-items-center'>
                                    <div className='day-1-2 py-1 font-changes'>
                                      <h6>Day {e?.days}</h6>
                                    </div>
                                    <div className=' font-changes'>
                                      <h6 className='mb-1 pe-5'>
                                        {e?.hotel_name}
                                      </h6>
                                      <p className='mb-0 text-start'>
                                        <i class='fa-solid fa-location-dot me-2'></i>
                                        Dalhousie
                                      </p>
                                    </div>
                                  </span>
                                </div>
                                <div className='hotels-star'>
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className='p-1'
                                    style={{ color: "yellow" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className='p-1'
                                    style={{ color: "yellow" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className='p-1'
                                    style={{ color: "yellow" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className='p-1'
                                    style={{ color: "yellow" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className='p-1'
                                    style={{ color: "yellow" }}
                                  />
                                </div>
                                <div className='hotels-footer'>
                                  <p>
                                    {e?.hotel_address} <br />
                                    {e?.hotel_city},{e?.hotel_state}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>

                <div className='col-xl-4 col-lg-6 col-12 posi-second'>
                  <div className='CuStom_package details_box media-border-none d-flex justify-content-center align-items-center hyhy p-3'>
                    <div className='inner_CuStom_package w-100 pb-4'>
                      <p className='CuStom_package_header media-CuStom_package_header'>
                        {booked?.package_details?.[0]?.name}
                      </p>
                      <div className='CuStom_package_content'>
                        <p className='mb-1'>
                          <span>Name</span><span className="mx-1">:</span>{booked?.user_name}
                        </p>
                        <p className='mb-1'>
                          <span>Email</span><span className="mx-1">:</span>{booked?.email_id}
                        </p>
                        <p className='mb-1'>
                          <span>Contact No.</span><span className="mx-1">:</span>{booked?.contact_number}
                        </p>
                        <p className='mb-1'>
                          <span>Payment Status</span><span className="mx-1">:</span>{booked?.payment_type}
                        </p>
                        {booked?.gst_address !== "" && (
                          <p className='mb-1'>
                            <span>Email</span><span className="mx-1">:</span>{booked?.gst_address}
                          </p>
                        )}
                        {/* {booked.payment_type_on_booking !== "Partial Payment" && ( */}
                        <div className='d-flex'>
                          <p className=' mb-1 '>
                            <span>Price</span>
                            <span className='ms-1 me-2'>:</span>
                            <FontAwesomeIcon
                              className='me-1'
                              icon={faIndianRupeeSign}
                            />
                            {booked?.priceperperson !== 0 ? (
                              <>
                                {booked?.total_amount}
                              </>
                            ) : (
                              <>
                                {booked.total_amount}
                              </>
                            )}
                          </p>
                        </div>
                        {/* )} */}
                        <div>
                          {Number(booked?.price_per_person) === 0 && (
                            <>
                              <p className="mb-1" style={{ fontSize: "13px", fontWeight: "500" }}>Adult : {booked?.total_adult} * <FontAwesomeIcon className='me-1' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{booked?.price_per_person_adult} = <FontAwesomeIcon className='me-1' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{Number(booked?.total_adult) * Number(booked?.price_per_person_adult)}</p>
                              <p className="mb-1" style={{ fontSize: "13px", fontWeight: "500" }}>Children : {booked?.total_child} * <FontAwesomeIcon className='me-1' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{booked?.price_per_person_child} = <FontAwesomeIcon className='me-1' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{Number(booked?.total_child) * Number(booked?.price_per_person_child)}</p>
                              <p className="mb-2" style={{ fontSize: "13px", fontWeight: "500" }}>Infants : {booked?.total_infant} * <FontAwesomeIcon className='me-1' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{booked?.price_per_person_infant} = <FontAwesomeIcon className='me-1' icon={faIndianRupeeSign} style={{ fontSize: "11px" }} />{Number(booked?.total_infant) * Number(booked?.price_per_person_infant)}</p>
                            </>
                          )}
                        </div>
                        {booked.payment_type_on_booking !== "Partial Payment" && (
                          <div className='d-flex'>
                            <p className='mb-1 '>
                              <span>Transaction Id</span>
                              <span className='ms-1 me-2'>:</span>
                              {booked.transaction_id}
                            </p>
                          </div>
                        )}
                        <div className='d-flex'>
                          <p className='mb-1 '>
                            <span>Payment Type</span>
                            <span className='ms-1 me-2'>:</span>
                            {booked.payment_type_on_booking}
                          </p>
                        </div>
                        <div className='d-flex'>
                          <p className='mb-1 '>
                            <span>Booking Date</span>
                            <span className='ms-1 me-2'>:</span>
                            {formatDate(booked?.bookdate)}
                          </p>
                        </div>
                        <div
                          className=''
                          style={{
                            display: "grid",
                            gridAutoColumns: "1fr 1fr",
                          }}
                        >
                          <p className='mb-0'>
                            {" "}
                            <span> Total Reviews </span>{" "}
                            <span className='mx-1'>:</span> {agencyTotalReview}
                          </p>
                          <p className='mb-4'>
                            {agencyAverageStar}
                            {stars}
                          </p>
                        </div>
                        <div className='CuStom_package_images'>
                          <div className='text-center'>
                            <FontAwesomeIcon
                              icon={faHotel}
                              style={{ color: "#868383", fontSize: "30px" }}
                              className=''
                            />
                            <p>{booked.hoteltype}</p>
                          </div>
                          <div className='mx-2 text-center'>
                            <FontAwesomeIcon
                              icon={faUtensils}
                              style={{ color: "#868383", fontSize: "30px" }}
                              className=''
                            />
                            <p>Meals</p>
                          </div>
                          <div className='text-center'>
                            <FontAwesomeIcon
                              icon={faBinoculars}
                              style={{ color: "#868383", fontSize: "30px" }}
                              className=''
                            />
                            <p>Sightseeing</p>
                          </div>
                          <div className='mx-2 text-center'>
                            <div>
                              {/* {(booked.transport_mode?.toLowerCase()?.includes("bus") ||
                                booked.transport_mode?.toLowerCase()?.includes("train") ||
                                booked.transport_mode?.toLowerCase()?.includes("flight") ||
                                booked?.transport_mode?.toLowerCase()?.includes("car")) && (
                                  <>
                                    <FontAwesomeIcon
                                      icon={faBus}
                                      style={{
                                        color: "#868383",
                                        fontSize: "30px",
                                      }}
                                      className=''
                                    />
                                  </>
                                )} */}
                              {booked.transport_mode?.toLowerCase() ===
                                "train" && (
                                  <>
                                    <FontAwesomeIcon
                                      icon={faTrainSubway}
                                      style={{ color: "#868383", fontSize: "30px" }}
                                      className=''
                                    />
                                  </>
                                )}
                              {booked.transport_mode?.toLowerCase() ===
                                "flight" && (
                                  <>
                                    <FontAwesomeIcon
                                      icon={faPlane}
                                      style={{ color: "#868383", fontSize: "30px" }}
                                      className=''
                                    />
                                  </>
                                )}
                              {booked.transport_mode?.toLowerCase() === "cab/car" && (
                                <>
                                  <FontAwesomeIcon
                                    icon={faTaxi}
                                    style={{ color: "#868383", fontSize: "30px" }}
                                    className=''
                                  />
                                </>
                              )}
                            </div>
                            <p>By {booked.transport_mode}</p>
                          </div>
                        </div>
                        <div className="my-3">
                          <h5 className="fw-bold">Travellers :</h5>
                          <div className="travellers-table">
                            <span></span>
                            <span>FULLNAME</span>
                            <span>GENDER</span>
                            <span>AGE</span>
                          </div>
                          {booked?.travel_details && booked?.travel_details?.map((e, index) => (
                            <div className="travellers-table">
                              <p>{index + 1}.</p>
                              <p>{e?.first_name} {e?.last_name}</p>
                              <p>{e?.gender}</p>
                              <p>{getYearFromDate(e?.dob)}</p>
                            </div>
                          ))}
                        </div>
                        <div style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                          {showReview && (
                            <div className="row">
                              <div className="">
                                <p className="mb-0" style={{ fontSize: "14px" }}>
                                  {review ? (
                                    <span>
                                      <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', marginBottom: '5px' }}>
                                        <div style={{ marginRight: '5px', color: "#09656D" }}>My Review</div>
                                        <div>
                                          {[...Array(5)].map((_, index) => (
                                            <FontAwesomeIcon
                                              key={index}
                                              icon={faStar}
                                              className='CuStom_package_icon'
                                              style={{ color: index < gotrating ? "#FFD700" : "#B8B8B8" }}
                                            />
                                          ))}
                                        </div>
                                      </div>
                                      <hr style={{ border: "2px solid black", margin: "0" }}></hr>
                                      <div style={{ marginBottom: '5px', fontSize: "15px", margin: "5px", paddingX: "10px", paddingBottom: "5px" }}>{gotcomment}</div>
                                      <div style={{ cursor: 'pointer', color: 'white', textDecoration: 'underline', textAlign: "end" }} >
                                        <button style={{ backgroundColor: "#09646D", color: "white", paddingLeft: "20px", paddingRight: "20px", paddingTop: "5px", paddingBottom: "5px", margin: "5px", borderRadius: "10px" }} onClick={handleShow}>
                                          Edit
                                        </button>
                                      </div>
                                    </span>
                                  ) : (
                                    <span>
                                      Please give your{" "}
                                      <a style={{ cursor: 'pointer', color: '#007bff', textDecoration: 'underline' }} onClick={handleShow}>
                                        Review
                                      </a>
                                    </span>
                                  )}
                                </p>
                              </div>
                            </div>
                          )}

                        </div>
                      </div>
                      {booked.payment_type_on_booking === "Partial Payment" && (
                        <>
                          <div className="partial-payment-header">
                            {booked.total_amount - paidAmount === 0 ? (
                              <p className="">Payment Details</p>
                            ) : (
                              <p className="">Complete Your Payment</p>
                            )}
                          </div>

                          {paidAmount !== booked.total_amount && (
                            <div className="partial-payment-body">
                              <div>
                                <h5>Total Amount :</h5>
                                <p><FontAwesomeIcon
                                  className='me-1'
                                  icon={faIndianRupeeSign}
                                />{booked.total_amount}</p>
                              </div>
                              <div>
                                <h5>Paid Amount :</h5>
                                <p><FontAwesomeIcon
                                  className='me-1'
                                  icon={faIndianRupeeSign}
                                />{paidAmount} {(paidAmount < booked.total_amount / 2 && "(30%)")} {(paidAmount > booked.total_amount / 2 && "(65%)")} {(paidAmount === booked.total_amount / 2 && "(50%)")} </p>
                              </div>
                              <div>
                                <h5>Remaining Amount :</h5>
                                <p><FontAwesomeIcon
                                  className='me-1'
                                  icon={faIndianRupeeSign}
                                />{booked.total_amount - paidAmount}</p>
                              </div>
                            </div>
                          )}

                          <div className="partial-payment-body-table mt-3">
                            <div className="partial-payment-table-header-payment">
                              <span></span>
                              <span>AMOUNT</span>
                              <span>TRANSACTION ID</span>
                              <span>DATE</span>
                            </div>
                            <hr className="mt-1 mb-2" />
                            {booked?.payment && booked?.payment?.map((e, index) => (
                              <div className="partial-payment-table-header-payment">
                                <p></p>
                                <p><FontAwesomeIcon
                                  className='me-1'
                                  icon={faIndianRupeeSign}
                                />{e?.paid_amount}</p>
                                <p>{e?.transaction_id}</p>
                                <p>{formatDate(e?.payment_date)}</p>
                              </div>
                            ))}
                          </div>

                          {booked.total_amount - paidAmount === 0 ? (
                            <></>
                          ) : (
                            <div className="partial-payment-button">
                              {paidAmount < booked.total_amount / 2 ? (
                                <select value={paymentAmount} name="" id="" onChange={handlePrice}>
                                  <option value="">Select</option>

                                  <option value={(booked.total_amount - paidAmount) / 2}><FontAwesomeIcon
                                    className='me-1'
                                    icon={faIndianRupeeSign}
                                  />{(booked.total_amount - paidAmount) / 2}(35%)</option>

                                  <option value={booked.total_amount - paidAmount}><FontAwesomeIcon
                                    className='me-1'
                                    icon={faIndianRupeeSign}
                                  />{booked.total_amount - paidAmount} (70%)</option>
                                </select>
                              ) : (
                                <select value={paymentAmount} name="" id="" onChange={handlePrice}>
                                  <option value="">Select</option>
                                  <option value={booked.total_amount - paidAmount}><FontAwesomeIcon
                                    className='me-1'
                                    icon={faIndianRupeeSign}
                                  />{booked.total_amount - paidAmount}(Remaining All Amount)</option>
                                </select>
                              )}
                              <button onClick={hadlePayment}>Pay</button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className='col-12 posi-third' ref={privacy}>
                  <div className='mt-5'>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: privacy.term_and_condition_content,
                      }}
                    />
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </section>
      )}
      <hr />
      <Footer />
    </div>
  )
}

export default Booked_package_fulDetail