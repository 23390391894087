/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../Project/css/index1.css";
import Header2 from "./Header2";
import { Form, Row, Button, Modal } from "react-bootstrap";
import My_pannel from "./My_pannel";
import { BASE_URL } from "../BASE_URL";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loader from "../images/loading.png";
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

const AddItineries = () => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [hotelList, setHotelList] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState(null);

  const navigate = useNavigate();
  const [BidData, setBidData] = useState([]);

  const { id, BidId } = useParams();

  const [editorData, setEditorData] = useState('');
  console.log(editorData)

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  const [itineriesData, setItineriesData] = useState({
    day: 1,
    title: "",
    photo: "",
  });

  const txt = (e) => {
    const { name, value } = e.target;
    setItineriesData({ ...itineriesData, [name]: value });
  };

  const getItinerary = async () => {
    const res = await fetch(`${BASE_URL}itinerary?bid_id=${BidId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    setBidData(data.data);
    console.log(data?.data);
    setItineriesData({ ...itineriesData, day: data?.data?.length + 1 });
  };

  useEffect(() => {
    if (BidId !== undefined) {
      getItinerary();
    }

    const fetchItineraryHotel = async () => {
      const token = localStorage.getItem("vendorToken");
      const res = await fetch(`${BASE_URL}api/hotel_itienrary/displayAgencyById`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      const data = await res.json();
      setHotelList(data?.data);
    };

    fetchItineraryHotel();
  }, [BidId]);

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setItineriesData({ ...itineriesData, photo: file });
  };

  const AddItineriesData = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("vendorToken");

    const { day, title, photo } = itineriesData;

    if (!day || !title || !editorData || !selectedHotel?._id) {
      toast.error("Please fill all details!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (day > 10) {
      toast.error("Please Enter Valid Day!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("bid_id", BidId);
    formData.append("day", day);
    formData.append("title", title);
    formData.append("activity", editorData);
    formData.append("photo", photo);
    formData.append("hotel_itienrary_id", selectedHotel?._id);

    const res = await fetch(`${BASE_URL}itinerary/addBid`, {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: formData,
    });

    if (res.status === 200) {
      setLoading(false);
      navigate(`/vendor/Submit_package_form/${id}`);
    } else {
      setLoading(false);
      console.error("Error:", res.status, res.statusText);
    }
  };

  return (
    <>
      <Header2 />
      <div className='costum_container'>
        <My_pannel />
        <ToastContainer />
        <section>
          <div className='costum_container'>
            <div className=' p-3'>
              <div>
                <Form>
                  <Row
                    className='itinerary_padding green_border gy-2 gx-5 margin_left_right justify-content-center'
                    style={{ backgroundColor: "#ffffff" }}
                  >
                    <div className='col-12'>
                      <div className='row mb-3'>
                        <div className='col-12'>
                          <span className='text-20 itinerary_header'>
                            Day wise Itinerary plan
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='row d-flex justify-content-center'>
                      <div
                        className='col-12'
                        style={{
                          border: "1px solid grey",
                          borderRadius: "15px",
                        }}
                      >
                        <div className='p-3'>
                          <div>
                            <div className='mb-2'>
                              <label htmlFor='text'>Day</label>
                              <input
                                type='number'
                                name='day'
                                onChange={txt}
                                value={itineriesData.day}
                                readOnly
                              />
                            </div>
                            <div className='mb-2'>
                              <label htmlFor='text'>Add Title</label>
                              <input type='text' name='title' onChange={txt} />
                            </div>
                            <div className='mb-2'>
                              <label htmlFor='text'>Add Photo</label>
                              <input
                                type='file'
                                name='photo'
                                onChange={handlePhotoChange}
                              />
                            </div>
                            <div className='mb-2'>
                              <label htmlFor='text'>Select Hotel</label>
                              <div className='d-flex align-items-center'>
                                <input
                                  type='text'
                                  name='select_hotel'
                                  onChange={txt}
                                  className='flex-grow-1'
                                  value={selectedHotel ? selectedHotel.hotel_name : ""}
                                  readOnly
                                />
                                <Button
                                  variant='primary'
                                  className='ms-2'
                                  onClick={() => setShowModal(true)}
                                >
                                  +
                                </Button>
                              </div>
                            </div>
                            <div className='mb-2 ckeditor-p-tag'>
                              <a>Description/Activity</a>
                              <CKEditor
                                editor={ClassicEditor}
                                data={editorData}
                                onChange={handleEditorChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                          {loading ? (
                            <button
                              className='m-3'
                              style={{
                                width: "160px",
                                height: "40px",
                                backgroundColor: "#09646d",
                                border: "none",
                                color: "white",
                              }}
                            >
                              <img src={loader} alt="" className="h-75 spinner" />
                            </button>
                          ) : (
                            <button
                              className='m-3'
                              style={{
                                width: "160px",
                                height: "40px",
                                backgroundColor: "#09646d",
                                border: "none",
                                color: "white",
                              }}
                              onClick={AddItineriesData}
                            >
                              Submit
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Body>
          <div>
            <h5 className="mb-0 mt-2 ms-2">Select Hotel</h5>
            <hr />
            {hotelList && hotelList.map((e, index) => (
              <div key={index} className="d-flex align-items-start mb-3 mx-4 gap-3">
                <input
                  type="radio"
                  name="selectedHotel"
                  value={e._id}
                  onChange={() => setSelectedHotel(e)}
                  checked={selectedHotel && selectedHotel._id === e._id}
                  className="mt-2"
                />
                <div>
                  <p className="mb-1 cmnp">{e.hotel_name}</p>
                  <p className="mb-1 cmnp">{e?.hotel_address}</p>
                  <p className="mb-1 cmnp">{e?.hotel_city},{e?.hotel_state}</p>
                </div>
              </div>
            ))}
            {hotelList?.length < 1 && (
              <Button
                variant="primary"
                style={{
                  backgroundColor: "#155E75",
                }}
                onClick={() => navigate("/vendor/addhotels")}
              >
                Add Hotel
              </Button>
            )}
            {hotelList?.length > 0 && (
              <div className="d-flex justify-content-center mt-4 mb-3">
                <Button
                  variant="primary"
                  style={{
                    backgroundColor: "#155E75",
                  }}
                  onClick={() => setShowModal(false)}
                >
                  Select
                </Button>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddItineries
