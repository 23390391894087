import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBell,
    faEnvelopeOpen,
    faUser,
    faStar
} from "@fortawesome/free-solid-svg-icons";
import My_pannel from "./My_pannel";
import Header2 from "./Header2";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { BASE_URL } from "../BASE_URL";
import { Rating } from "@mui/material";
import Button from "react-bootstrap/Button";
import './css/Hotels.css'
import ProfileSection from "./SecondNavbar";
// import hotelphoto from './hotel1.3.png'

const HotelCard = ({ hotel }) => {
    const navigate = useNavigate();

    const handleViewHotel = () => {
        navigate(`/vendor/myhotels/${hotel._id}`);
    };

    return (
        <div className="hotel-card">
            <div className="hotel-card-image-container">
                <img src={hotel.hotel_photo[0] ? hotel.hotel_photo[0] : '/hotel1.3.png'} className="hotel-card-image" alt="photos" />
            </div>
            <div className="hotel-card-details">
                <div>
                    <div className="hotel-card-ratingandtitle">
                        <div className="hotel-card-name">{hotel.hotel_name}</div>
                        <div>
                            {hotel.hotel_type}<FontAwesomeIcon
                                icon={faStar}
                                className='hotel-card-rating-star'
                            />
                        </div>
                    </div>
                    <div className="hotel-card-descripiton">{hotel.hotel_description}</div>
                </div>
                <div>
                    <div className="hotel-card-address">Address: {hotel.hotel_address}, {hotel.city}, {hotel.state}</div>
                    <button className="hotel-card-button" variant="primary" onClick={handleViewHotel}>View</button>
                </div>
            </div>
        </div>
    );
};

const MyHotels = () => {
    const navigate = useNavigate();

    const [editedata, setEditedData] = useState("");

    const Call = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}agency/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setEditedData(data?.data?.[0]);
    };

    useEffect(() => {
        Call();
    }, []);


    const handleNav = () => {
        navigate("/vendor/myprofile");
    };

    const handleAddHotels = () => {
        navigate(`/vendor/myhotels/Addhotels`)
    }

    const [hotels, setHotels] = useState([]);
    const [error, setError] = useState(null);

    const fetchHotels = async () => {
        try {
            const token = localStorage.getItem("vendorToken");
            const response = await fetch(`${BASE_URL}hotel_syt/`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }); // Replace with your actual API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();

            setHotels(data.data);

        } catch (error) {
            setError(error.message);
        }
    };


    useEffect(() => {
        fetchHotels();
    }, []);




    return (
        <>
            <Header2 />
            <div className="costum_container">
                <My_pannel />
                <div style={{ backgroundColor: "whitesmoke", minHeight: "100vh" }}>
                    <ProfileSection title="My Hotels" />

                    <section className="booked_packega_margin p-4">
                        <div className="">
                            <Button
                                variant="primary"
                                className="ms-2"
                                style={{
                                    backgroundColor: "#155E75",
                                    padding: "8px 40px",
                                }}
                                onClick={handleAddHotels}
                            >
                                Add Hotels
                            </Button>
                        </div>
                        <hr style={{ borderBottom: "3px solid #09646D" }}></hr>
                        <div className="hotels-container">
                            {
                                hotels.length !== 0 ? hotels.map((hotel) => (
                                    <HotelCard key={hotel._id} hotel={hotel} />
                                )) : <div>No Hotels Added</div>
                            }
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
};

export default MyHotels;
