import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faEnvelopeOpen, faUser, faPenToSquare, faPlus } from "@fortawesome/free-solid-svg-icons";
import My_pannel from "./My_pannel";
import Header2 from "./Header2";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../BASE_URL";
import { Button, Row } from "react-bootstrap";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import ProfileSection from "./SecondNavbar";

const ItineraryHotelList = () => {
    const navigate = useNavigate();

    const [editedata, setEditedData] = useState("");
    const [hotelList, setHotelList] = useState([]);

    const Call = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}agency/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setEditedData(data?.data?.[0]);
    };

    const fetchItineraryHotel = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}api/hotel_itienrary/displayAgencyById`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        console.log(data?.data)
        setHotelList(data?.data);
    };

    useEffect(() => {
        Call();
        fetchItineraryHotel();
    }, []);

    const handleNav = () => {
        navigate("/vendor/myprofile");
    };

    return (
        <>
            <Header2 />
            <div className="costum_container bg-white">
                <My_pannel />
                <div style={{ backgroundColor: "white", height: "100vh" }}>
                    <ProfileSection title="Itinerary Hotels" />

                    <section className="booked_packega_margin p-4">
                        <div className="">
                            <Button
                                variant="primary"
                                className="ms-2"
                                style={{
                                    backgroundColor: "#155E75",
                                    padding: "8px 40px",
                                }}
                                onClick={() => navigate("/vendor/addhotels")}
                            >
                                Add Hotels
                            </Button>
                        </div>
                        <hr style={{ borderBottom: "3px solid #09646D" }}></hr>
                        <div className="hotels-container">
                            {hotelList.length !== 0 ? (
                                <div className="row">
                                    {hotelList && hotelList.map((e, index) => (
                                        <div key={index} className="col-6 mt-3">
                                            <div className="inner_green_border py-3 px-4 hotel-card-relative">
                                                <div className="row gap-1">
                                                    <div className="col-5">
                                                        <div id={`carouselExampleCaptions-${index}`} className="carousel slide" data-bs-ride="carousel">
                                                            <div className="carousel-indicators">
                                                                {e?.hotel_photo?.map((_, idx) => (
                                                                    <button
                                                                        key={idx}
                                                                        type="button"
                                                                        data-bs-target={`#carouselExampleCaptions-${index}`}
                                                                        data-bs-slide-to={idx}
                                                                        className={idx === 0 ? "active" : ""}
                                                                        aria-current={idx === 0 ? "true" : "false"}
                                                                        aria-label={`Slide ${idx + 1}`}
                                                                    ></button>
                                                                ))}
                                                            </div>
                                                            <div className="carousel-inner">
                                                                {e?.hotel_photo?.map((ele, idx) => (
                                                                    <div key={idx} className={`carousel-item ${idx === 0 ? "active" : ""}`}>
                                                                        <img src={ele} className="d-block hotel-itinerary-img-carousel w-100" alt="..." />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <button className="carousel-control-prev" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="prev">
                                                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                <span className="visually-hidden">Previous</span>
                                                            </button>
                                                            <button className="carousel-control-next" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="next">
                                                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                                <span className="visually-hidden">Next</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div>
                                                            <h4 className="mb-1">{e?.hotel_name}<span className="fs-5">({e?.hotel_type})</span></h4>
                                                            <p className="mb-1">{e?.hotel_address}</p>
                                                            <p className="mb-1">{e?.hotel_city},{e?.hotel_state}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mb-2">
                                                        <h5 style={{ color: "#09646d", fontSize: "14px", fontWeight: "600" }}>DESCRIPTION</h5>
                                                        <p>{e?.hotel_description}</p>
                                                    </div>
                                                    <div className="col-12 mb-2">
                                                        <h5 style={{ color: "#09646d", fontSize: "14px", fontWeight: "600" }}>OTHER</h5>
                                                        <p>{e?.other}</p>
                                                    </div>
                                                    <div className="hotel-card-absolute">
                                                        <FontAwesomeIcon icon={faPenToSquare} onClick={() => navigate(`/vendor/addhotels/${e?._id}`)} style={{ cursor: "pointer" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) :
                                <div>No Hotels Added</div>
                            }
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
};

export default ItineraryHotelList;
