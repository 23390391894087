import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import "./css/Hotal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupeeSign, faStar } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../BASE_URL";
import Footer from "./Footer";
import axios from "axios";
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import BookingModal from "./booking-hotel-modal/BookingHotelModal";
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Rating from "@mui/material/Rating"
import Carousel from 'react-bootstrap/Carousel';

function Hotel_Details(props) {

  const { id } = useParams();

  const navigate = useNavigate()

  const [hotelDetail, setHotelDetail] = useState([]);
  const [propertyDetail, setPropertyDetail] = useState('');
  const [amenities, setAmenities] = useState([]);

  const [showButton, setShowButton] = useState(false)

  const hotelList = async () => {
    const token = localStorage.getItem("userToken");
    const res = await fetch(`${BASE_URL}hotel_syt/details?_id=${id}`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    
    setHotelDetail(data?.data?.[0]);
    setPropertyDetail(data?.data?.[0]?.property_policies[0])
    setAmenities(data?.data?.[0]?.amenities_and_facilities)
    const myId = localStorage.getItem('my_id')


    if (data?.data?.[0]?.hotel_reviews) {
      const reviews = data.data[0].hotel_reviews;

      const hasMatchingId = reviews?.some(review => review?.user_details?._id === myId);

      if (!hasMatchingId) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    } else {
      setShowButton(true);
    }
  };

  useEffect(() => {
    hotelList();
  }, []);

  const overRef = useRef(null);
  const roomsRef = useRef(null);
  const facilities = useRef(null);
  const location = useRef(null);
  const policies = useRef(null);
  const reviewRef = useRef(null);


  const scrollTo = (section) => {
    let targetRef;

    switch (section) {
      case 'overview':
        targetRef = overRef;
        break;
      case 'rooms':
        targetRef = roomsRef;
        break;
      case 'facilities':
        targetRef = facilities;
        break;
      case 'location':
        targetRef = location;
        break;
      case 'policies':
        targetRef = policies;
        break;
      case 'review':
        targetRef = reviewRef;
        break;
      default:
        targetRef = null;
    }

    if (targetRef) {
      window.scroll({
        top: targetRef.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };



  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsSticky(offset > 0);
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [googleMapsEmbedUrl, setGoogleMapsEmbedUrl] = useState('');

  useEffect(() => {
    // Function to fetch coordinates based on the address
    const fetchCoordinates = async (address) => {
      try {
        const apiKey = 'YOUR_GOOGLE_MAPS_API_KEY'; // Replace with your actual API key
        const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`;

        const response = await axios.get(geocodeUrl);

        if (response.data.status === 'OK') {
          const location = response.data.results[0].geometry.location;
          const latitude = location.lat;
          const longitude = location.lng;

          // Construct the embed URL with the obtained coordinates
          const embedUrl = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${latitude},${longitude}`;
          setGoogleMapsEmbedUrl(embedUrl);
        } else {
          console.error('Geocode was not successful for the following reason:', response.data.status);
        }
      } catch (error) {
        console.error('Error fetching coordinates:', error);
      }
    };

    const myLocation = hotelDetail.hotel_name + "," + hotelDetail.hotel_address

    if (myLocation) {
      fetchCoordinates(myLocation);
    }
  }, [hotelDetail]);

  const totalStars = 5;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [room_id, setRoomId] = useState('');
  const [roomData, setRoomData] = useState([]);


  const [myArray, setMyArray] = useState([])
  const [reviewAverage, setReviewAverage] = useState(0);

  const myId = localStorage.getItem('my_id')

  useEffect(() => {
    const sortedReviews = hotelDetail && hotelDetail?.hotel_reviews?.sort((a, b) => {
      if (a?.user_details?._id === myId && b?.user_details?._id !== myId) return -1;
      if (a?.user_details?._id !== myId && b?.user_details?._id === myId) return 1;
      return 0;
    });
    let sum = 0;
    if (hotelDetail.hotel_reviews) {
      hotelDetail.hotel_reviews.map(ele => Number(ele.star)).forEach(function (item, idx) {
        sum += item;
      });
      const average = sum / hotelDetail.hotel_reviews.length;
      setReviewAverage(average)
    }
    setMyArray(sortedReviews)

  }, [hotelDetail]);

  const [alreadyCheckIn, setAlreadyCheckIn] = useState(null)
  const [alreadyCheckOut, setAlreadyCheckOut] = useState(null)
  const [alreadyAdults, setAlreadyAdults] = useState(null)
  const [alreadyChildren, setAlreadyChildren] = useState(null)
  const [alreadyTotalRooms, setAlreadyTotalRooms] = useState(null)

  const today = new Date().toISOString().split('T')[0];

  const handleAlreadyCheckin = (e) => {
    const checkInDate = e.target.value;
    setAlreadyCheckIn(checkInDate);

    // Ensure check-out date is always later than check-in date
    if (alreadyCheckOut && new Date(checkInDate) >= new Date(alreadyCheckOut)) {
      setAlreadyCheckOut('');
    }
  };

  const handleAlreadyCheckOut = (e) => {
    setAlreadyCheckOut(e.target.value);
  };

  const handleAlreadyAdults = (e) => {
    setAlreadyAdults(e.target.value);
  };

  const handleAlreadyChildren = (e) => {
    setAlreadyChildren(e.target.value);
  };

  const handleAlreadyTotalRooms = (e) => {
    setAlreadyTotalRooms(e.target.value);
  };

  useEffect(() => {
    const adults = parseInt(alreadyAdults) || 0;
    const children = parseInt(alreadyChildren) || 0;
    const sum = adults + children;

    let rooms = Math.ceil(sum / 2); // Default to sum / 2 rounded up

    if (sum === 5) rooms = 3;
    else if (sum === 7) rooms = 4;
    else if (sum === 9) rooms = 5;

    setAlreadyTotalRooms(rooms.toString());
  }, [alreadyAdults, alreadyChildren]);


  const handleSearch = async () => {

    if (!alreadyCheckIn) {
      toast.error("Please Select Checkin Date", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return;
    }
    if (!alreadyCheckOut) {
      toast.error("Please Select Checkout Date", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return;
    }
    if (alreadyChildren + alreadyAdults === 0) {
      toast.error("Please Select Atleast 1 person", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return;
    }
    if (!alreadyTotalRooms) {
      toast.error("Please Select Total Rooms!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return;
    }
    const token = localStorage.getItem("userToken");
    const res = await fetch(`${BASE_URL}hotel_syt/search`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_checkin: alreadyCheckIn,
        user_checkout: alreadyCheckOut,
        number_of_rooms: alreadyTotalRooms,
        hotel_id: id
      })
    });
    const data = await res.json();
    setRoomData(data.data);
    scrollTo('rooms')
  }

  return (
    <>
      <Header />

      {/*  <!----------------------------------------------- image section ---------------------------------------------> */}

      <section class="pt-5">
        <div class="container-customes">
          <div class="row text-center">
            <div className="col-8">
              <div className="row h-100">
                <div class="col-5 h-100">
                  <div class="header-images sea-image h-100">
                    <img src={"/Rectangle 421.png"} alt="" class="h-100" />
                  </div>
                </div>
                <div class="col-7 h-100">
                  <div class="row gy-1 h-50">
                    <div class="col-6 h-100">
                      <div className="h-100">
                        <img src="/Rectangle 429.png" alt="" class="h-100" />
                      </div>
                    </div>
                    <div class="col-6 h-100">
                      <div className="h-100">
                        <img src="/Rectangle 425.png" alt="" class="h-100" />
                      </div>
                    </div>
                    <div class="col-6 h-100">
                      <div className="h-100">
                        <img src="/Rectangle 427.png" alt="" class="h-100" />
                      </div>
                    </div>
                    <div class="col-6 h-100">
                      <div className="h-100">
                        <img src="/Rectangle 428.png" alt="" class="h-100" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <div className="availibility">
                <h4>Check Availability</h4>
                <div className="d-flex align-items-center mb-3">
                  <label htmlFor="checkin-date" style={{ width: "55%" }}>
                    Check-In date :
                  </label>
                  <input
                    type="date"
                    id="checkin-date"
                    value={alreadyCheckIn}
                    min={today}
                    className="px-2"
                    onChange={handleAlreadyCheckin}
                  />
                </div>
                <div className="d-flex align-items-center mb-3">
                  <label htmlFor="checkout-date" style={{ width: "55%" }}>
                    Check-Out date :
                  </label>
                  <input
                    type="date"
                    id="checkout-date"
                    value={alreadyCheckOut}
                    min={alreadyCheckIn ? new Date(new Date(alreadyCheckIn).getTime() + 86400000).toISOString().split('T')[0] : today}
                    className="px-2"
                    onChange={handleAlreadyCheckOut}
                    disabled={!alreadyCheckIn}
                  />
                </div>
                <div className="row text-center mb-4">
                  <div className="col-4">
                    <label htmlFor="adults-select">Adult</label>
                    <select
                      id="adults-select"
                      className="form-select"
                      aria-label="Default select example"
                      value={alreadyAdults}
                      onChange={handleAlreadyAdults}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                  <div className="col-4">
                    <label htmlFor="children-select">Children</label>
                    <select
                      id="children-select"
                      className="form-select"
                      aria-label="Default select example"
                      value={alreadyChildren}
                      onChange={handleAlreadyChildren}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                  <div className="col-4">
                    <label htmlFor="rooms-select">Total Rooms</label>
                    <select
                      id="rooms-select"
                      className="form-select"
                      aria-label="Default select example"
                      value={alreadyTotalRooms}
                      onChange={handleAlreadyTotalRooms}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                </div>
                <button className="available-search mb-2" onClick={handleSearch}>
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={` whyso ${isSticky ? 'sticky' : ''}`}>
        <div class="container-customes stick-link-bar">
          <div class={`row  rounded px-4 pb-1 pt-2 text-center gx-2 ${isSticky ? 'hshshs' : ''}`}>
            <div class="col-6 Overview">
              <div class="d-flex justify-content-between align-items-center">
                <a onClick={() => scrollTo('overview')} style={{ cursor: "pointer" }}>Overview</a>
                <a onClick={() => scrollTo('rooms')} style={{ cursor: "pointer" }} class="d-lg-block d-md-none d-none">
                  Rooms
                </a>
                <a onClick={() => scrollTo('facilities')} style={{ cursor: "pointer" }} class="d-lg-block d-md-none d-none">
                  Facilities
                </a>
                <a onClick={() => scrollTo('location')} style={{ cursor: "pointer" }} class="d-lg-block d-md-none d-none">
                  Location
                </a>
                <a onClick={() => scrollTo('policies')} style={{ cursor: "pointer" }} class="d-lg-block d-md-none d-none">
                  Policies
                </a>
                <a onClick={() => scrollTo('review')} style={{ cursor: "pointer" }} class="d-lg-block d-md-none d-none">
                  Review
                </a>
              </div>
            </div>
            <div class="col-6 rupees text-end">
              <span>from</span>
              <h4 class="d-inline ms-1">
                <FontAwesomeIcon icon={faIndianRupeeSign} /> {hotelDetail.rooms === undefined || hotelDetail.rooms?.length === 0 ? "N/A" : Math.min(...hotelDetail.rooms.map(ele => ele.price))}
              </h4>
            </div>
          </div>
        </div>
      </section>

      {/* <!---------------------------------------- center ---------------------------------------------> */}

      <section ref={overRef} className={`${isSticky ? 'overrefcss' : ''}`}>
        <div class="container-customes" >
          <div class="row gx-3" >
            <div class="col-xl-12 col-lg-8 col-md-12 col-sm-12">
              <div class="rose-valley" >
                <div>
                  <div>
                    <div class="d-flex flex-xl-row flex-lg-row flex-md-row flex-sm-column flex-column">
                      <div class="me-4">
                        <h4 class="m-0 text-start">{hotelDetail.hotel_name}</h4>
                      </div>
                      <div class="d-flex">
                        {[...Array(totalStars)].map((_, index) => {
                          return (
                            <FontAwesomeIcon
                              key={index}
                              icon={faStar}
                              style={{ color: index < reviewAverage ? 'yellow' : '#DDDDDD' }}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <p class="mt-2 pb-3 border-bot">
                    {hotelDetail.hotel_address},{hotelDetail.city},{hotelDetail.state}
                  </p>
                </div>
                <div class="rose-paragraph">
                  <p>{hotelDetail.hotel_description}</p>
                </div>
                <div>
                  <div>Other Details</div>
                  <ul>
                    {hotelDetail.other?.map(e => (
                      <li>{e}</li>
                    ))}
                  </ul>
                </div>
              </div>

              <div class="highlights my-4">
                <div class="me-4 mb-4">
                  <h4 class="m-0">Highlights</h4>
                </div>
                <div class="row text-center">
                  {hotelDetail?.Highlights && hotelDetail?.Highlights.map((e) => (
                    <div className="col-2">
                      <img src={e.icon} alt="" class="mb-2" style={{ height: "40px", width: "40px" }} />
                      <p>{e.title}</p>
                    </div>
                  ))}
                </div>
              </div>

              {roomData.length === 0 && <div ref={roomsRef} class="delux-room" style={{ display: "flex", flexWrap: "wrap" }} >
                {hotelDetail.rooms &&
                  hotelDetail.rooms.map((ele) => {
                    return (
                      <div >
                        <div style={{ border: "1px solid black", padding: "10px", display: "flex", borderRadius: "20px" }}>
                          <div>
                            <Carousel style={{ height: "200px", width: "300px" }}>
                              {ele.photos.map((e, index) => (
                                <Carousel.Item key={index} interval={2000} >
                                  <img src={e} alt={`Slide ${index}`} className="inner-rooms-image" />
                                </Carousel.Item>
                              ))}
                            </Carousel>
                          </div>
                          <div >
                            <div className="facilities">
                              <h5>
                                {ele.room_title}
                              </h5>
                              <div>
                                <ul className="mb-1 row">
                                  {ele.room_highlights.map((elem) => (
                                    <li className="col-7" key={elem}>{elem}</li>
                                  ))}
                                </ul>
                              </div>
                              <div>
                                <h2 className="mb-xl-3 mb-lg-3 mb-md-2 mb-sm-1 mb-1">
                                  <FontAwesomeIcon icon={faIndianRupeeSign} />
                                  {ele.price} <span>per night</span>
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                <BookingModal
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  hotelId={id}
                  hotelData={hotelDetail}
                  roomId={room_id}
                />
              </div>}

              {roomData.length !== 0 && <div  ref={roomsRef} class="delux-room" style={{ display: "flex", flexWrap: "wrap" }} >
                {roomData &&
                  roomData.map((ele) => {
                    return (
                      <div >
                        <div style={{ border: "1px solid black", padding: "10px", display: "flex", borderRadius: "20px" }}>
                          <div>
                            <Carousel style={{ height: "200px", width: "300px" }}>
                              {ele.photos.map((e, index) => (
                                <Carousel.Item key={index} interval={2000} >
                                  <img src={e} alt={`Slide ${index}`} className="inner-rooms-image" />
                                </Carousel.Item>
                              ))}
                            </Carousel>
                          </div>
                          <div >
                            <div className="facilities">
                              <h5>
                                {ele.room_title}
                                <span className={`ms-2 status-label ${ele.status === 'available' ? 'available' : ''} ${ele.status === 'sold out' ? 'sold-out-status' : ''}`}>
                                  {ele.status}
                                </span>
                              </h5>
                              <div>
                                <ul className="mb-1 row">
                                  {ele.room_highlights.map((elem) => (
                                    <li className="col-7" key={elem}>{elem}</li>
                                  ))}
                                </ul>
                              </div>
                              <div>
                                <h2 className="mb-xl-3 mb-lg-3 mb-md-2 mb-sm-1 mb-1">
                                  <FontAwesomeIcon icon={faIndianRupeeSign} />
                                  {ele.price} <span>per night</span>
                                </h2>
                                <div className="book-now">
                                  {ele.status !== "sold out" && (
                                    <button onClick={() => { setIsModalOpen(true); setRoomId(ele); }}>Book Now</button>
                                  )}
                                  {/* <button className="ms-2">View Detail</button> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                <BookingModal
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  hotelId={id}
                  roomId={room_id}
                  checkindate={alreadyCheckIn}
                  checkoutdate={alreadyCheckOut}
                  totalRooms={alreadyTotalRooms}
                  alreadyAdults={alreadyAdults}
                  alreadyChildren={alreadyChildren}
                />
                {/* <button onClick={handleSlidePrev} className="room-swipe-left-btn"> <FontAwesomeIcon icon={faLeftLong} /></button>
                  <button onClick={handleSlideNext} className="room-swipe-right-btn"><FontAwesomeIcon icon={faRightLong} /></button> */}
              </div>}



              {/* amenities  */}

              <div class="amenities my-4" ref={facilities}>
                <h5 class="ps-2">Amenities and facilities</h5>
                <div class="row px-4">
                  {amenities && amenities.map((e) => (
                    <div class="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-12 antiviral">
                      <h6>{e.title}</h6>
                      <ul class="mb-1">
                        {e.points.map((data) => (
                          <li class="mb-1">{data}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>


            </div>
          </div>
        </div>
      </section>

      {/* <!---------------------------------------- map -------------------------------------------> */}

      <section class="py-4" ref={location}>
        <div class="container-customes">
          <div class="row">
            <div class="col-12">
              <h4>Location</h4>
              <iframe
                src={googleMapsEmbedUrl}
                width="100%"
                height="450"
                style={{ borderRadius: "10px" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      {/* <!------------------------------- footer ----------------------------------------> */}

      <section class="my-4 pb-4" ref={policies}>
        <div class="container-customes">
          <div class="row foot-center">
            <div class="col footer">
              <div class="foot">
                <div class="footer-head">
                  <h5 class="mb-5">Property policies</h5>
                  <h6 class="mb-3">{propertyDetail?.policy_title}</h6>
                  <p>
                    {propertyDetail?.policy_description}
                  </p>
                </div>
                <div class="row gx-3">
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 pt-3">
                    <div class="box">
                      <div class="mb-3">
                        <h5 class="pb-2">Infant 0-2 year</h5>
                      </div>
                      <div>
                        <h6 class="mb-3">
                          {propertyDetail?.infant}
                        </h6>
                        {propertyDetail && propertyDetail?.infant_points?.map((e) => (
                          <p>{e}</p>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 pt-3">
                    <div class="box">
                      <div class="mb-3">
                        <h5 class="pb-2">Children 3-11 year</h5>
                      </div>
                      <div class="pb-3">
                        <h6 class="mb-3">{propertyDetail?.children}</h6>
                        {propertyDetail && propertyDetail?.childern_points?.map((e) => (
                          <p>{e}</p>))}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 pt-3">
                    <div class="box">
                      <div class="mb-3">
                        <h5 class="pb-2">Adults 12 & above</h5>
                      </div>
                      <div class="pb-5">
                        <h6 class="mb-3">{propertyDetail?.infant}</h6>
                        {propertyDetail && propertyDetail?.infant_points?.map((e) => (
                          <p>{e}</p>))}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="others mt-3">
                  <h5>Others</h5>
                  <ul>
                    {propertyDetail && propertyDetail?.policy_other.map((e) => (
                      <li>
                        {e}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="my-4 pb-4" ref={reviewRef}>
        <div class="container-customes">
          <div class="row foot-center">
            <div class="col footer">
              <div class="foot">
                <div class="footer-head hotel-review-box">
                  <div>
                    <h5 className="mb-1">Reviews</h5>
                    <div className="d-flex align-items-center gap-2">
                      {[...Array(totalStars)].map((_, index) => {
                        return (
                          <FontAwesomeIcon
                            key={index}
                            icon={faStar}
                            style={{ color: index < reviewAverage ? 'yellow' : '#DDDDDD' }}
                          />
                        );
                      })}
                      {reviewAverage}
                    </div>
                  </div>
                </div>
                <hr className="mb-5" />
                <div className="hotel-riviews">
                  {myArray && myArray?.map((e) => (
                    <div className="hotel-review-specific">
                      <div className="personal-review">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex hotel-review-header">
                            <div>
                              <img src="" alt="" />
                            </div>
                            <div>
                              <h5 className="mb-1">{e?.user_details?.name}</h5>
                              <p className="mb-0">{e?.createdAt?.slice(0, 10)}</p>
                            </div>
                          </div>
                          <div>
                            <Rating
                              name="simple-controlled"
                              value={parseInt(e.star)}
                              readOnly
                            />
                          </div>
                        </div>
                        <hr />
                        <div>
                          <p>{e?.comment_box}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <hr />


      <Footer />
    </>
  );
}

export default Hotel_Details;
