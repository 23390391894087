import React, { useContext, useEffect, useState, useRef } from "react"
import {
    faArrowLeft,
    faBell,
    faEnvelopeOpen,
    faUser,
    faPlus,
    faTrashAlt,
    faTrash,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useParams, NavLink } from "react-router-dom"
import My_pannel from "./My_pannel"
import "../Project/css/index1.css"
import Header2 from "./Header2"
import { Form, Modal, Row } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import ButtonGroup from "react-bootstrap/ButtonGroup"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { useNavigate } from "react-router-dom"
import PropTypes from "prop-types"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Select from "react-select"
import { day } from "../App"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { BASE_URL } from "../BASE_URL"
import { Avatar } from "@mui/material"
import Collapse from 'react-bootstrap/Collapse';
// import { colourOptions } from "../data";

const formatDate = (dateString) => {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
        return 'Invalid date format';
    }

    const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    };

    return date.toLocaleDateString('en-GB', options);
};


function EditMyPackage(props) {

    const navigate = useNavigate()

    const [editedata, setEditedData] = useState("")

    const Call = async () => {
        const token = localStorage.getItem("vendorToken")
        const res = await fetch(`${BASE_URL}agency/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        })
        const data = await res.json()
        setEditedData(data?.data?.[0])
    }

    useEffect(() => {
        Call()
    }, [])

    const handleNav = () => {
        navigate("/vendor/myprofile")
    }

    const [formData1, setFormData1] = useState({
        name: "",
        total_days: "",
        total_nights: "",
        destination: "",
        destination_category_id: "",
        meal_required: "",
        meal_type: "",
        travel_by: "",
        hotel_type: "",
        more_details: "",
        sightseeing: "",
        place_to_visit_id: "",
        status: "",
        package_type: "",
        room_sharing: "",
    });

    const { id } = useParams();
    const [priceRanges, setPriceRanges] = useState([]);

    const getPackage = async () => {
        const token = localStorage.getItem("token")

        const res = await fetch(`${BASE_URL}package/getPackageData?package_id=${id}`, {
            method: "GET",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        });
        const data = await res.json();
        console.log(data.data[0]);
        setPriceRanges(data?.data?.[0]?.price_and_date)
        setDestinationList(
            data.data[0].destination_category_id.map((category) => category._id)
        )
        setCheckedItems(data.data[0].meal_required || "")
        setHotelType(data.data[0].hotel_type || "")
        setFormData1({
            name: data.data[0].name || "",
            total_days: data.data[0].total_days || "",
            total_nights: data.data[0].total_nights || "",
            destination: data.data[0].destination || "",
            meal_type: data.data[0].meal_type || "",
            travel_by: data.data[0].travel_by || "",
            more_details: data.data[0].more_details || "",
            sightseeing: data.data[0].sightseeing || "",
            place_to_visit_id: data.data[0].place_to_visit_id || "",
            status: data.data[0].status,
            room_sharing: data.data[0].room_sharing,
            package_type: data.data[0].package_type
        });

        setIncludeServices(data.data[0].include_service.map(ele => ele.include_services_value))
        setexcludeServices(data.data[0].exclude_service.map(ele => ele.exclude_services_value))
        setStartDate(data.data[0].start_date.slice(0, 10));
        setEndDate(data.data[0].end_date.slice(0, 10));
    };

    useEffect(() => {
        getPackage();
    }, []);

    const [checkedItems, setCheckedItems] = useState("");

    const handleCheckboxChange = (event) => {
        const itemName = event.target.name;
        if (event.target.checked) {
            setCheckedItems([...checkedItems, itemName]);
        } else {
            setCheckedItems(checkedItems.filter((item) => item !== itemName));
        }
    };


    const [destinationList, setDestinationList] = useState("");

    const handleCheckboxChange2 = (event) => {
        const itemName = event.target.name;
        if (event.target.checked) {
            setDestinationList([...destinationList, itemName]);
        } else {
            setDestinationList(destinationList.filter((item) => item !== itemName));
        }
    };

    const [hotelType, setHotelType] = useState("");

    const handleCheckboxChange3 = (event) => {
        const itemName = event.target.name;
        if (event.target.checked) {
            setHotelType([...hotelType, itemName]);
        } else {
            setHotelType(hotelType.filter((item) => item !== itemName));
        }

    };
    const handleOptionChange = (event) => {
        const { name, value } = event.target;
        setFormData1({ ...formData1, [name]: value })
    };

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handleStartDateChange = (event) => {
        const selectedStartDate = event.target.value;
        setStartDate(selectedStartDate);

        // Ensure end date is not before start date
        if (endDate && new Date(endDate) < new Date(selectedStartDate)) {
            setEndDate(selectedStartDate);
        }
    };

    const handleEndDateChange = (event) => {
        const selectedEndDate = event.target.value;
        // Check if end date is not before start date
        if (new Date(selectedEndDate) >= new Date(startDate)) {
            setEndDate(selectedEndDate);
        } else {
            alert('End date cannot be before start date');
        }
    };

    const [Days, setDays] = useState("");
    const [Nights, setNights] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData1.name) {
            toast.error("Add Name of the Package", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!formData1.total_days || isNaN(Number(formData1.total_days))) {
            toast.error("Add valid Total Days", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!formData1.total_nights || isNaN(Number(formData1.total_nights))) {
            toast.error("Add valid Total Nights", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        // if (!selectedDestinationId) {
        //     toast.error("Select a Destination", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
        //     return;
        // }
        if (!destinationList || destinationList.length === 0) {
            toast.error("Select Destination Category", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (checkedItems.length === 0) {
            toast.error("Select Meal Required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!formData1.meal_type) {
            toast.error("Select Meal Type", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!formData1.travel_by) {
            toast.error("Select Travel By", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!hotelType) {
            toast.error("Select Hotel Type", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!formData1.more_details) {
            toast.error("Add More Details", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!formData1.sightseeing) {
            toast.error("Add Sightseeing Details", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        // if (!selectplacetovisit || selectplacetovisit.length === 0) {
        //     toast.error("Select Places to Visit", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
        //     return;
        // }
        if (!formData1.status) {
            toast.error("Select Status", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!includeServices || includeServices.length === 0) {
            toast.error("Add Included Services", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!excludeServices || excludeServices.length === 0) {
            toast.error("Add Excluded Services", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!startDate) {
            toast.error("Select Start Date", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!endDate) {
            toast.error("Select End Date", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }


        try {
            const token = localStorage.getItem("vendorToken");

            const response = await fetch(`${BASE_URL}package/${id}`, {
                method: 'PUT',
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: formData1.name,
                    total_days: formData1.total_days,
                    total_nights: formData1.total_nights,
                    destination_category_id: destinationList,
                    meal_required: checkedItems,
                    meal_type: formData1.meal_type,
                    travel_by: formData1.travel_by,
                    hotel_type: hotelType,
                    more_details: formData1.more_details,
                    price_and_date: priceRanges,
                    sightseeing: formData1.sightseeing,
                    status: formData1.status,
                    include_service: includeServices,
                    exclude_service: excludeServices,
                    start_date: startDate,
                    end_date: endDate
                })
            });

            const result = await response.json();

            if (response.ok && result.status === "OK") {
                setTimeout(() => {
                    navigate(`/vendor/displaymypackage/${id}`);
                }, 2000);
            } else {
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const [maxItineraries, setmaxItineraries] = useState(0);

    useEffect(() => {
        setmaxItineraries(Days, Nights)
    }, [Days, Nights])





    // get api for select placetovisit

    const [placetovisit, setPlacetovisit] = useState([])


    const places = async () => {
        const token = localStorage.getItem("vendorToken")

        const res = await fetch(`${BASE_URL}placetovisit`, {
            method: "GET",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        });
        const data = await res.json();
        setPlacetovisit(data.data);

    };

    useEffect(() => {
        places();
    }, []);


    const [selectplacetovisit, setSelectplacetovisit] = useState("");

    const handleSelectPlace = (event) => {
        const selectedId = event.target.value; // Get the selected option's ID
        setSelectplacetovisit(selectedId); // Update the state with the selected ID
    };



    // get api for select destination

    const [destination, setDestination] = useState([])

    const mealRequired = ["Breakfast", "Lunch", "Dinner"];
    const hotelTypeList = ["5", "4", "3", "2", "1"];

    const Dest = async () => {
        const token = localStorage.getItem("vendorToken")

        const res = await fetch(`${BASE_URL}destination/alldestination`, {
            method: "GET",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        });
        const data = await res.json();
        setDestination(data.data);

    };

    useEffect(() => {
        Dest();
    }, []);

    // select for destination category 

    const [Destcat, setDestcat] = useState([]);

    useEffect(() => {
        const fetchCategoryList = async () => {
            try {
                const token = localStorage.getItem("VendorToken");
                const response = await fetch(`${BASE_URL}destinationcategory`, {
                    headers: {
                        Authorization: token,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setDestcat(data.data);

            } catch (error) {

            }
        };
        fetchCategoryList();
    }, []);

    const [includeServices, setIncludeServices] = useState([]);
    const [newService, setNewService] = useState("");

    const handleAddService = () => {
        if (newService.trim() !== "") {
            setIncludeServices((prevServices) => [...prevServices, newService]);
            setNewService("");
        }
    };


    const handleRemoveService = (index) => {
        // Create a new array without the item to be removed
        const updatedServices = includeServices.filter((_, i) => i !== index);
        setIncludeServices(updatedServices);
    };



    const [excludeServices, setexcludeServices] = useState([]);
    const [exclude, setExclude] = useState("");

    const handleAddExclude = () => {
        if (exclude.trim() !== "") {
            setexcludeServices((prevServices) => [...prevServices, exclude]);
            setExclude(""); // Clear the input field after adding
        }
    };


    const handleRemoveExclude = (index) => {
        // Create a new array without the item to be removed
        const updatedServices = excludeServices.filter((_, i) => i !== index);
        setexcludeServices(updatedServices);
    };


    const [openRangeModal, setOpenRangeModal] = useState(false);
    const [price, setPrice] = useState('');
    const [startDatePrice, setStartDatePrice] = useState(null);
    const [endDatePrice, setEndDatePrice] = useState(null);

    const handleOpenInsertModal = () => {
        setOpenRangeModal(true);
    };

    const handleCloseRangeModal = () => {
        setOpenRangeModal(false);
    };

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tommorowDate = tomorrow.toISOString().split('T')[0];


    const handleStartDate = (e) => {
        // const selectedDate = e.target.value
        setStartDatePrice(e.target.value);

    };

    const handleEndDate = (e) => {
        // const selectedDate = e.target.value
        setEndDatePrice(e.target.value);
    };

    const handleSave = () => {
        if (price && startDatePrice && endDatePrice) {
            const newPriceRange = {
                price_per_person: Number(price),
                price_start_date: startDatePrice,
                price_end_date: endDatePrice,
            };

            // Add the new price range to the array
            setPriceRanges([...priceRanges, newPriceRange]);

            // Reset the form and close the modal
            setPrice('');
            setStartDatePrice(null);
            setEndDatePrice(null);
            setOpenRangeModal(false);
        } else {
            alert('Please fill in all fields');
        }
    }

    const handleDelete = (index) => {
        const updatedPriceRanges = priceRanges.filter((_, i) => i !== index);
        setPriceRanges(updatedPriceRanges);
    };


    return (
        <>
            <Header2 />
            <div className='costum_container'>
                <My_pannel />
                <ToastContainer />
                <div style={{ backgroundColor: "whitesmoke" }}>
                    <section style={{ backgroundColor: "#fff" }}>
                        <div className='costum_container'>
                            <div>
                                <div className='pt-2 align-items-center second_navbar'>
                                    <div className='ps-md-4 ps-sm-2 ps-2 pe-xl-5 pe-lg-5 pe-md-3 pe-sm-2 pe-2 align-items-center d-flex justify-content-between'>
                                        <div className='d-flex align-items-center'>
                                            <a
                                                onClick={() =>
                                                    navigate(`/vendor/mypackages`)
                                                }
                                                style={{ cursor: "pointer" }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faArrowLeft}
                                                    className='p-2 cmnbkg'
                                                />
                                            </a>
                                            <span className=' ps-2'>Submit Form</span>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <a href=''>
                                                <FontAwesomeIcon
                                                    icon={faBell}
                                                    className='p-2 cmnclr cmnicon'
                                                />
                                            </a>
                                            <a href=''>
                                                <FontAwesomeIcon
                                                    icon={faEnvelopeOpen}
                                                    className='p-2 cmnclr cmnicon ms-2'
                                                />
                                            </a>
                                            <div
                                                className='d-flex  align-items-center cmn py-1 px-2 ms-2'
                                                style={{ cursor: "pointer" }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faUser}
                                                    className='cmnclr cmnicon p-2'
                                                />
                                                <p className='cmnp ps-1' onClick={handleNav}>
                                                    {editedata?.full_name}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className='p-3'>
                        <Box sx={{ width: "100%" }}>
                            <div className="vendoraddpackage-container">
                                <div className="vendoraddpackage-form-container">
                                    <div className="vendoraddpackage-header">Edit Package</div>
                                    <div className="vendoraddpackage-form">
                                        <div className="vendoraddpackage-flex">
                                            <div className="vendoraddpackage-input-group">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    placeholder="Enter package name"
                                                    value={formData1.name}
                                                    onChange={handleOptionChange}
                                                    className="vendoraddpackage-input"
                                                />
                                            </div>
                                            <div className="vendoraddpackage-input-group">
                                                <select
                                                    name="package_type"
                                                    value={formData1.package_type}
                                                    onChange={handleOptionChange}
                                                    className="vendoraddpackage-select"
                                                >
                                                    <option value="">Select Package Type</option>
                                                    <option value="Land Packge">Land Package</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="vendoraddpackage-flex">
                                            <div className="vendoraddpackage-input-group">
                                                <select
                                                    name="total_days"
                                                    value={formData1.total_days}
                                                    onChange={handleOptionChange}
                                                    className="vendoraddpackage-select"
                                                >
                                                    <option value="">Select days</option>
                                                    {[...Array(30).keys()].map(day => (
                                                        <option key={day + 1} value={day + 1}>{day + 1}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="vendoraddpackage-input-group">
                                                <select
                                                    name="total_nights"
                                                    value={formData1.total_nights}
                                                    onChange={handleOptionChange}
                                                    className="vendoraddpackage-select"
                                                    disabled={!formData1.total_days}
                                                >
                                                    <option value="">Select nights</option>
                                                    {formData1.total_days && [-1, 0, 1].map(offset => {
                                                        const nightValue = parseInt(formData1.total_days) + offset;
                                                        if (nightValue > 0) {
                                                            return <option key={nightValue} value={nightValue}>{nightValue}</option>;
                                                        }
                                                        return null;
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ display: "flex", flex: "1" }}>
                                                <div className="vendoraddpackage-checkbox-group">
                                                    <label className="vendoraddpackage-label">Meal Required</label>
                                                    {mealRequired.map((option) => (
                                                        <div key={option} display="flex" alignItems="center" style={{ marginBottom: '10px' }}>
                                                            <input
                                                                type="checkbox"
                                                                name={option.toLowerCase()}
                                                                style={{ marginRight: '10px' }}
                                                                onChange={handleCheckboxChange}
                                                                checked={checkedItems.includes(option)}
                                                            />
                                                            <label>{option}</label>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="vendoraddpackage-checkbox-group">
                                                    <label className="vendoraddpackage-label">Destination Category</label>
                                                    {Destcat.map((feature) => (
                                                        <div key={feature._id} className="vendoraddpackage-checkbox">
                                                            <input checked={destinationList.includes(feature._id)} type="checkbox" id={feature._id} name={feature._id} onChange={handleCheckboxChange2} />
                                                            <label htmlFor={feature._id}>{feature.category_name}</label>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="vendoraddpackage-checkbox-group">
                                                    <label className="vendoraddpackage-label">Hotel Type</label>

                                                    {hotelTypeList.map((option) => (
                                                        <div key={option} display="flex" alignItems="center" style={{ marginBottom: '10px' }}>
                                                            <input
                                                                type="checkbox"
                                                                name={option.toLowerCase()}
                                                                style={{ marginRight: '10px' }}
                                                                onChange={handleCheckboxChange3}
                                                                checked={hotelType.includes(option)}
                                                            />
                                                            <label >{option} Star</label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="vendoraddpackage-checkbox-group">
                                                <div className="vendoraddpackage-input-group2">
                                                    <select
                                                        name="meal_type"
                                                        value={formData1.meal_type}
                                                        onChange={handleOptionChange}
                                                        className="vendoraddpackage-select"
                                                    >
                                                        <option value="">Select Meal type</option>
                                                        <option value="Any">Any</option>
                                                        <option value="Veg">Veg</option>
                                                        <option value="Non-Veg">Non-Veg</option>
                                                    </select>
                                                </div>
                                                <div className="vendoraddpackage-input-group2">
                                                    <select
                                                        name="travel_by"
                                                        value={formData1.travel_by}
                                                        onChange={handleOptionChange}
                                                        className="vendoraddpackage-select"
                                                    >
                                                        <option value="">Select Travel By</option>
                                                        <option value="Train">Train</option>
                                                        <option value="Flight">Flight</option>
                                                        <option value="Car">Car</option>
                                                        <option value="Bus">Bus</option>
                                                    </select>
                                                </div>
                                                <div className="vendoraddpackage-input-group2">
                                                    <select
                                                        name="sightseeing"
                                                        value={formData1.sightseeing}
                                                        onChange={handleOptionChange}
                                                        className="vendoraddpackage-select"
                                                    >
                                                        <option value="">Select Sightseeing</option>
                                                        <option value="yes">Yes</option>
                                                        <option value="no">No</option>
                                                    </select>
                                                </div>
                                                <div className="vendoraddpackage-input-group2">
                                                    <select
                                                        name="room_sharing"
                                                        value={formData1.room_sharing}
                                                        onChange={handleOptionChange}
                                                        className="vendoraddpackage-select"
                                                    >
                                                        <option value="">Select Room Sharing</option>
                                                        <option value="single sharing">single sharing</option>
                                                        <option value="double sharing">double sharing</option>
                                                        <option value="triple sharing">triple sharing</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="vendoraddpackage-flex">
                                            <div className="vendoraddpackage-services">
                                                <div style={{ fontWeight: "bold", fontSize: "14px" }}>Include Services</div>
                                                {includeServices.length !== 0 && <div className="vendoraddpackage-service-list">
                                                    <ul>
                                                        {includeServices.map((e, index) => (
                                                            <li key={index} className="vendoraddpackage-service-item">
                                                                <span>{e}</span>
                                                                <FontAwesomeIcon
                                                                    icon={faTrash}
                                                                    className='vendoraddpackage-remove-button'
                                                                    onClick={() => handleRemoveService(index)}
                                                                />
                                                                {/* <button type="button" onClick={() => handleRemoveService(index)} className="vendoraddpackage-remove-button">Remove</button> */}
                                                            </li>

                                                        ))}
                                                    </ul>

                                                </div>}
                                                <div className="vendoraddpackage-input-wrapper">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter Include Services"
                                                        value={newService}
                                                        onChange={(e) => setNewService(e.target.value)}
                                                        className="vendoraddpackage-input"
                                                    />
                                                    <button type="button" onClick={handleAddService} className="vendoraddpackage-add-button">Add</button>
                                                </div>
                                            </div>
                                            <div className="vendoraddpackage-services">
                                                <div style={{ fontWeight: "bold", fontSize: "14px" }}>Exclude Services</div>
                                                {excludeServices.length !== 0 && <div className="vendoraddpackage-service-list">
                                                    <ul>
                                                        {excludeServices.map((e, index) => (
                                                            <li key={index} className="vendoraddpackage-service-item">
                                                                <span>{e}</span>
                                                                <FontAwesomeIcon icon={faTrash} onClick={() => handleRemoveExclude(index)} className="vendoraddpackage-remove-button" />
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>}
                                                <div className="vendoraddpackage-input-wrapper">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter Exclude Services"
                                                        value={exclude}
                                                        onChange={(e) => setExclude(e.target.value)}
                                                        className="vendoraddpackage-input"
                                                    />
                                                    <button type="button" onClick={handleAddExclude} className="vendoraddpackage-add-button">Add</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="vendoraddpackage-flex">
                                            <textarea
                                                type="text"
                                                name="more_details"
                                                placeholder="Enter More Details"
                                                value={formData1.more_details}
                                                onChange={handleOptionChange}
                                                rows="3"
                                                style={{ fontSize: "12px", width: "100%", padding: "10px" }}

                                            />
                                        </div>
                                        <div className="vendoraddpackage-flex">
                                            <div className="vendoraddpackage-input-group">
                                                <div style={{ fontWeight: "bold", fontSize: "14px" }}>Start Date:</div>
                                                <input
                                                    type="date"
                                                    name="start_date"
                                                    placeholder="Enter Start Date"
                                                    value={startDate}
                                                    onChange={handleStartDateChange}
                                                    className="vendoraddpackage-input"
                                                />
                                            </div>
                                            <div className="vendoraddpackage-input-group">
                                                <div style={{ fontWeight: "bold", fontSize: "14px" }}>End Date:</div>
                                                <input
                                                    type="date"
                                                    name="end_date"
                                                    placeholder="Enter End Date"
                                                    value={endDate}
                                                    onChange={handleEndDateChange}
                                                    min={startDate}
                                                    className="vendoraddpackage-input"
                                                />
                                            </div>
                                            <div className="vendoraddpackage-input-group">
                                                <div style={{ fontWeight: "bold", fontSize: "14px" }}>Status:</div>
                                                <select
                                                    name="status"
                                                    value={formData1.status}
                                                    onChange={handleOptionChange}
                                                    style={{ width: '100%', padding: '9px 10px', fontSize: '12px', border: '1px solid rgb(216, 216, 216)', borderRadius: '6px', color: 'rgb(122, 131, 139)' }}
                                                >
                                                    <option disabled value="">Select</option>
                                                    <option value={true}>Active</option>
                                                    <option value={false}>InActive</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="price-range-container p-4 bg-light border rounded-md shadow-sm">
                                                <div className="d-flex justify-content-end mb-3">
                                                    <button
                                                        className="vendoraddpackage-button"
                                                        onClick={handleOpenInsertModal}
                                                    >
                                                        + Add Price Range
                                                    </button>
                                                </div>
                                                <table className="table table-striped table-hover">
                                                    <thead className="table-dark">
                                                        <tr>
                                                            <th scope="col"></th>
                                                            <th scope="col">
                                                                <p className="vendoraddpackage-label mb-0">Price</p>
                                                            </th>
                                                            <th scope="col">
                                                                <p className="vendoraddpackage-label mb-0">Start Date</p>
                                                            </th>
                                                            <th scope="col">
                                                                <p className="vendoraddpackage-label mb-0">End Date</p>
                                                            </th>
                                                            <th scope="col">
                                                                <p className="vendoraddpackage-label mb-0">Actions</p>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {priceRanges.length > 0 ? (
                                                            priceRanges.map((range, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}.</td>
                                                                    <td>{range.price_per_person}</td>
                                                                    <td>{formatDate(range.price_start_date)}</td>
                                                                    <td>{formatDate(range.price_end_date)}</td>
                                                                    <td>
                                                                        <Button
                                                                            variant="danger"
                                                                            onClick={() => handleDelete(index)}
                                                                            className="btn btn-sm"
                                                                        >
                                                                            Delete
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="5" className="text-center text-muted">
                                                                    No price ranges added yet.
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>

                                            <Modal show={openRangeModal} onHide={handleCloseRangeModal}>
                                                <Modal.Body>
                                                    <Modal.Title>Add Price</Modal.Title>
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            placeholder="price"
                                                            name="price"
                                                            value={price}
                                                            onChange={(e) => setPrice(e.target.value)}
                                                            className="vendoraddpackageitinerary-input"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <input
                                                            type="date"
                                                            placeholder="startDate"
                                                            name="startDate"
                                                            value={startDatePrice}
                                                            min={tommorowDate}
                                                            onChange={handleStartDate}
                                                            className="vendoraddpackageitinerary-input"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <input
                                                            type="date"
                                                            placeholder="endDate"
                                                            name="endDate"
                                                            value={endDatePrice}
                                                            min={startDatePrice}
                                                            onChange={handleEndDate}
                                                            className="vendoraddpackageitinerary-input"
                                                            disabled={!startDatePrice}
                                                        />
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <Button variant="secondary" onClick={handleCloseRangeModal}>
                                                            Close
                                                        </Button>
                                                        <div className="d-flex align-items-center">
                                                            <Button className="vendoraddpackage-button" onClick={handleSave}>
                                                                Save
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Modal.Body>
                                            </Modal>

                                        </div>
                                        <div className="vendoraddpackage-submit">
                                            <button onClick={handleSubmit} className="vendoraddpackage-button">Submit</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Box>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditMyPackage;
