import React, { useEffect, useRef, useState } from "react";
import Hearer from "./Header";
import "../Project/css/index1.css";
import "../Project/css/custom_package.css";
import Footer from "./Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faChevronDown,
    faHotel,
    faHandHoldingDroplet,
    faHandsHoldingCircle,
    faCalendar,
    faUserDoctor,
    faTaxi,
    faPlane,
    faTrainSubway,
    faPaperclip,
    faCircleCheck,
    faBrush,
    faCheck,
    faXmarkCircle,
    faHandshakeAlt,
    faStar,
    faUsers
} from "@fortawesome/free-solid-svg-icons"
import loader from "../images/loading.png"
import { Modal, Row } from "react-bootstrap";
import { BASE_URL } from "../BASE_URL";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import countries from "../CountryStateCity.json";
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function BookingPayment() {

    const navigate = useNavigate();
    const location = useLocation()

    const [cities, setCities] = useState([])
    const [selectedState, setSelectedState] = useState('')
    const [selectedCity, setSelectedCity] = useState('')

    const selectedStates = countries?.find((e) => e.name === "India")

    const handleStateChange = (e) => {
        const selected = e.target.value
        setSelectedState(selected)
        const selectedCity = selectedStates?.states && selectedStates?.states?.find((e) => e?.name === selected)
        setCities(selectedCity?.cities)
    }

    const { id } = useParams();

    const [loading, setLoading] = useState(false)
    const [activeAccordion, setActiveAccordion] = useState(null);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false)
    const handleOpen = () => setShow(true)

    const toggleAccordion = (index) => {
        setActiveAccordion(activeAccordion === index ? null : index);
    };

    const handleStopLoading = () => {
        setLoading(false)
    }

    const scrollTo = (section) => {
        let targetRef

        switch (section) {
            case "overview":
                targetRef = overRef
                break
            case "services":
                targetRef = services
                break
            case "itenarary":
                targetRef = itenarary
                break
            default:
                targetRef = null
        }

        if (targetRef) {
            window.scroll({
                top: targetRef.current.offsetTop,
                behavior: "smooth",
            })
        }
    }

    const overRef = useRef(null)
    const itenarary = useRef(null)
    const services = useRef(null)

    const [bidDetails, setBidDetails] = useState("")
    const [policies, setPolicies] = useState([])
    const firstWord = bidDetails?.travel_by?.split(',')[0];

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [number, setNumber] = useState("")
    const [address, setAddress] = useState("")
    const [destinationDate, setDestinationDate] = useState("")

    const DetailsBid = async () => {
        const res = await fetch(`${BASE_URL}bidpackage/biddetails?_id=${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })

        const data = await res.json()

        if (data.code === 200) {
            setBidDetails(data?.data?.[0])
        }
    }

    const privacypolicies = async () => {
        const res = await fetch(`${BASE_URL}policy`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
        const data = await res.json()
        setPolicies(data?.data)
    }

    const Profile = async () => {
        const token = localStorage.getItem("userToken")
        const res = await fetch(`${BASE_URL}user/userprofile`, {
            method: "GET",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        })
        const data = await res.json()
        const userData = data?.data?.[0]?.user_details?.[0]

        const selectedCity = selectedStates?.states && selectedStates?.states?.find((e) => e?.name === userData?.state)
        setCities(selectedCity?.cities)

        setName(userData?.name)
        setEmail(userData?.email_address)
        setNumber(data?.data?.[0]?.phone)
        setSelectedState(userData?.state)
        setSelectedCity(userData?.city)
    }

    useEffect(() => {
        Profile();
        privacypolicies();
        DetailsBid();
    }, []);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [gender, setGender] = useState('');
    const [travellers, setTravellers] = useState([]);

    const totalTraveller = Number(bidDetails?.total_adult) + Number(bidDetails?.total_child) + Number(bidDetails?.Infant)

    const handleMakeAPayment = async () => {

        const totalPerson = Number(bidDetails?.total_adult) + Number(bidDetails?.total_child) + Number(bidDetails?.Infant)
        console.log(travellers?.length)
        console.log(totalPerson)

        if (travellers?.length < totalPerson) {
            toast.error("Please Enter All Travellers Detail!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        if (!name) {
            toast.error("Please fill in the Name field!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email) {
            toast.error("Please fill in the Email field!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        } else if (!emailRegex.test(email)) {
            toast.error("Please enter a valid Email address!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        const numberRegex = /^\d{10}$/;
        if (!number) {
            toast.error("Please fill in the Number field!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        } else if (!numberRegex.test(number)) {
            toast.error("Number must be exactly 10 digits!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        if (!selectedState) {
            toast.error("Please select a State!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        if (!selectedCity) {
            toast.error("Please select a City!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        setLoading(true);

        const token = localStorage.getItem("userToken")
        const myId = localStorage.getItem("my_id")
        const mobileno = number;
        const url = location.pathname;

        const res = await fetch(`${BASE_URL}payment/pay`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({
                MERCHANT_USER_ID: myId,
                amount: 1,
                mobileNumber: mobileno,
                link: url,
                custom_requirement_id: bidDetails?.custom_requirement_id,
                bid_id: bidDetails?._id,
                URL: "http://localhost:3000/my_package",

                total_adult: bidDetails?.total_adult,
                total_child: bidDetails?.total_child,
                total_infant: bidDetails?.Infant,
                price_per_person_infant: bidDetails?.price_per_person_infant,
                price_per_person_child: bidDetails?.price_per_person_child,
                price_per_person_adult: bidDetails?.price_per_person_adult,
                total_amount: bidDetails?.total_amount,
                meal: bidDetails?.meal_required,
                meal_type: bidDetails?.meal_types,
                totaldays: bidDetails?.total_days,
                totalnight: bidDetails?.total_nights,
                hoteltype: bidDetails?.hotel_types,
                room_sharing: bidDetails?.room_sharing,
                siteseeing: bidDetails?.sightseeing,
                departure: bidDetails?.departure,
                destination: bidDetails?.destination,
                category: bidDetails?.destination_category,
                transport_mode: bidDetails?.travel_by,
                bid_package_id: bidDetails?._id,
                approx_start_date: bidDetails?.start_date1,
                approx_end_date: bidDetails?.end_date1,
                custom_package_id: bidDetails?.custom_requirement_id,
                othere_requirement: bidDetails?.additional_requirement,
                total_person: Number(bidDetails?.total_adult) + Number(bidDetails?.total_child) + Number(bidDetails?.Infant),
                personal_care: bidDetails?.personal_care,
                booked_include: bidDetails?.include_services,
                booked_exclude: bidDetails?.exclude_services,
                booked_itinerary: bidDetails?.itineries,
                transport_include_exclude: bidDetails?.sightseeing,
                contact_number: number,
                email_id: email,
                user_name: name,
                city: selectedCity,
                state: selectedState,
                travel_details: travellers,
                gst_address: address,
                destination_arrival_date: "10-06-2025",
                payment_type: "Full Payment",

            }),
        });

        const data = await res.json();
        console.log(data)
        window.location.href = data?.data?.url
    };

    const getMaxDOB = () => {
        const today = new Date();
        const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
        return maxDate.toISOString().split("T")[0];
    };

    const handleSubmit = () => {

        if (!firstName) {
            toast.error("Please fill in the First Name field!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }
        if (!lastName) {
            toast.error("Please fill in the Last Name field!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }
        if (!dateOfBirth) {
            toast.error("Please fill in the Date of Birth field!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        const maxDOB = getMaxDOB();
        if (dateOfBirth > maxDOB) {
            toast.error("Traveller must be at least 18 years old!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        if (!gender) {
            toast.error("Please select a Gender!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        const newTraveller = {
            first_name: firstName,
            last_name: lastName,
            dob: dateOfBirth,
            gender: gender,
        };

        setTravellers([...travellers, newTraveller]);

        setFirstName('');
        setLastName('');
        setDateOfBirth('');
        setGender('');

        // Close the modal
        handleClose();
    };


    return (
        <>
            <Hearer />
            <ToastContainer />

            <div className="">
                <div className="CuStom_package_image">
                    <div className="packagebooking-box">
                        <ToastContainer />
                        <div className="booking-header">
                            <div className="booking-header-content container">
                                <div>
                                    <h4 className="mb-1">
                                        Ahmedabad to Goa <span className="mb-0">(2D/1N)</span>
                                    </h4>
                                </div>
                                <div>
                                    <p className="mb-0">4000/person</p>
                                </div>
                            </div>
                        </div>
                        <div className="packagebooking-grid container mt-4">
                            <div className="packagebooking-tabs">
                                <div className="booking-accordion-item mb-1">
                                    <div className="booking-accordion-header" onClick={() => toggleAccordion(1)}>
                                        <h5 className="mb-0">1.Traveller Details</h5>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </div>
                                    <div className={`accordion-content ${activeAccordion === 1 ? 'active' : ''}`}>
                                        {activeAccordion === 1 &&
                                            <>
                                                <div className="booking-traveller-box">
                                                    <div className="booking-traveller-box-header">
                                                        <div>
                                                            <h5 className="mb-0">1.TOTAL TRAVELLER - 4</h5>
                                                        </div>
                                                        <div className="d-flex gap-2">
                                                            <span>(2 Adults,2  Childrens)</span>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <h6 onClick={handleOpen}>+ Add Travellers Details</h6>
                                                        </div>
                                                    </div>
                                                    <div className="booking-traveller-box-body mx-5 mt-4">
                                                        {travellers && travellers.map((e, index) => (
                                                            <div className="booking-traveller-box-traveller-list">
                                                                <div><span>{index + 1}</span></div>
                                                                <div><b>{e?.first_name} {e?.last_name}</b></div>
                                                                <div>Adult</div>
                                                                <div><p className="mb-0">UPDATE</p></div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <hr />
                                                    <div className="booking-traveller-form my-4">
                                                        <div>
                                                            <h5>2.PLEASE ENETER CONTACT DETAILS <span className="text-red">*</span></h5>
                                                        </div>
                                                        <div className="booking-traveller-form-details">
                                                            <div className="d-flex flex-column gap-2">
                                                                <label htmlFor="">Name <span className="text-red">*</span></label>
                                                                <input type="text" value={name} />
                                                            </div>
                                                            <div className="d-flex flex-column gap-2">
                                                                <label htmlFor="">Email <span className="text-red">*</span></label>
                                                                <input type="email" value={email} />
                                                            </div>
                                                            <div className="d-flex flex-column gap-2">
                                                                <label htmlFor="">Mobile Number <span className="text-red">*</span></label>
                                                                <input type="tel" value={number} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="booking-traveller-form mb-3">
                                                        <div>
                                                            <h5>3.PLEASE ENTER GST DETAILS <span className="text-red">*</span></h5>
                                                        </div>
                                                        <div className="row booking-traveller-form-grid">
                                                            <div className="col-4">
                                                                <div className="d-flex flex-column gap-2 w-100">
                                                                    <label htmlFor="">Address</label>
                                                                    <textarea name="" id="" value={address} onChange={(e) => setAddress(e.target.value)}></textarea>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="d-flex flex-column gap-2 w-100">
                                                                    <label htmlFor="">Gst State <span className="text-red">*</span></label>
                                                                    <select name="" id="" value={selectedState} onChange={handleStateChange}>
                                                                        <option value="">select state</option>
                                                                        {selectedStates && selectedStates?.states.map((e) => (
                                                                            <option value={e?.name}>{e?.name}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="d-flex flex-column gap-2 w-100">
                                                                    <label htmlFor="">City</label>
                                                                    <select name="" id="" value={selectedCity} onChange={(e) => setSelectedCity(e?.target?.value)}>
                                                                        <option value="">select city</option>
                                                                        {cities && cities?.map((e) => (
                                                                            <option value={e?.name}>{e?.name}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>

                                <div className="booking-accordion-item mb-1">
                                    <div className="booking-accordion-header" onClick={() => toggleAccordion(2)}>
                                        <h5 className="mb-0">2.Package Details</h5>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </div>
                                    <div className={`accordion-content ${activeAccordion === 2 ? 'active' : ''}`}>
                                        {activeAccordion === 2 &&
                                            <>
                                                <div className=' col-12 text-center m-0 p-0 posi-first'>
                                                    <div className='d-flex mt-3 overview-packages'>
                                                        <div className='me-3 mb-3'>
                                                            <button
                                                                class='button-47'
                                                                role='button'
                                                                onClick={() => scrollTo("overview")}
                                                            >
                                                                Overview
                                                            </button>
                                                        </div>
                                                        <div className='me-3 mb-3'>
                                                            <button
                                                                class='button-47'
                                                                role='button'
                                                                onClick={() => scrollTo("services")}
                                                            >
                                                                Services
                                                            </button>
                                                        </div>
                                                        <div className='me-3 mb-3'>
                                                            <button
                                                                class='button-47'
                                                                role='button'
                                                                onClick={() => scrollTo("itenarary")}
                                                            >
                                                                Itinerary
                                                            </button>
                                                        </div>
                                                        <div className='me-3 mb-3'>
                                                            <button
                                                                class='button-47'
                                                                role='button'
                                                                onClick={() => scrollTo("privacy")}
                                                            >
                                                                Policy
                                                            </button>
                                                        </div>
                                                        <ToastContainer />
                                                    </div>
                                                    <section className='mb-3 mt-3' ref={overRef}>
                                                        <div className=''>
                                                            <div>
                                                                <button className='w-100 over-btn'>
                                                                    <img src='/c-icon.png' className=' me-3' />
                                                                    Overview
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </section>

                                                    <section className='text-start'>
                                                        <div className=''>
                                                            <Row>
                                                                <div className='col-xl-6 col-lg-12 mb-3'>
                                                                    <div className='amh_to_hima'>
                                                                        <div>
                                                                            <h6 className='pb-2 mb-0 cmntext text-capitalize'>
                                                                                {bidDetails?.despature} to {bidDetails?.destination}
                                                                            </h6>
                                                                        </div>
                                                                        <div className=''>
                                                                            <div className='row'>
                                                                                <div className='col-12 px-0'>
                                                                                    <div className='amh_to_hima_border ms-3 px-2 px-sm-4 px-md-4 px-lg-4 px-xl-4 py-2'>
                                                                                        <p className='cmnp py-1'>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faHotel}
                                                                                                className='cmnclr'
                                                                                            />{" "}
                                                                                            <b>Adult (12+yrs)</b> : {bidDetails?.total_adult}
                                                                                        </p>
                                                                                        <p className='cmnp py-1'>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faHandHoldingDroplet}
                                                                                                className='cmnclr'
                                                                                            />{" "}
                                                                                            <b>Infant (2 to 12 years)</b> : {bidDetails?.Infant}
                                                                                        </p>
                                                                                        <p className='cmnp py-1'>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faHandHoldingDroplet}
                                                                                                className='cmnclr'
                                                                                            />{" "}
                                                                                            <b>Kids(0 to 12 years)</b> : {bidDetails?.total_child}
                                                                                        </p>
                                                                                        <p className='cmnp py-1'>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faUsers}
                                                                                                className='cmnclr'
                                                                                            />{" "}
                                                                                            <b>Total Travellers</b> :{" "}
                                                                                            {Number(bidDetails?.total_adult) + Number(bidDetails?.Infant) + Number(bidDetails?.total_child)}
                                                                                        </p>
                                                                                        <p className='cmnp py-1'>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faHandsHoldingCircle}
                                                                                                className='cmnclr'
                                                                                            />{" "}
                                                                                            <b>Any Traveller Needs Personal Care?</b>{" "}
                                                                                            : {bidDetails?.personal_care}
                                                                                        </p>
                                                                                        <p className='cmnp py-1'>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faCalendar}
                                                                                                className='cmnclr'
                                                                                            />{" "}
                                                                                            <b>Total Days & Night</b> : {bidDetails?.total_days} & {bidDetails?.total_nights}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className='mt-3 mb-2'>
                                                                                <h6 className='cmntext'>
                                                                                    <FontAwesomeIcon
                                                                                        icon={faCalendar}
                                                                                        className='cmnclr'
                                                                                    />{" "}
                                                                                    Approx Departure Between{" "}
                                                                                </h6>
                                                                            </div>
                                                                            <div className='cmn'>
                                                                                <p className='cmnp ms-4 py-2'>
                                                                                    {bidDetails?.start_date} to {bidDetails?.end_date}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='col-xl-6 col-lg-12 mb-3'>
                                                                    <div className='amh_to_hima'>
                                                                        <div>
                                                                            <h6 className=' pb-2 mb-0 cmntext'>
                                                                                <FontAwesomeIcon
                                                                                    icon={faHotel}
                                                                                    className='cmnclr'
                                                                                />{" "}
                                                                                Hotel & Food
                                                                            </h6>
                                                                        </div>
                                                                        <div className=''>
                                                                            <div className='row'>
                                                                                <div className='col-12 px-0'>
                                                                                    <div className='amh_to_hima_border ms-3 px-4 py-2'>
                                                                                        <p className='cmnp py-1'>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faHotel}
                                                                                                className='cmnclr'
                                                                                            />{" "}
                                                                                            <b>Hotel Type</b> : {bidDetails?.hotel_types}
                                                                                        </p>
                                                                                        <p className='cmnp py-1'>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faHotel}
                                                                                                className='cmnclr'
                                                                                            />{" "}
                                                                                            <b>Room Sharing</b> : {bidDetails?.room_sharing}
                                                                                        </p>
                                                                                        <p className='cmnp py-1'>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faUserDoctor}
                                                                                                className='cmnclr'
                                                                                            />{" "}
                                                                                            <b>Meal</b> : {bidDetails?.meal}
                                                                                        </p>
                                                                                        <p className='cmnp py-1'>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faUserDoctor}
                                                                                                className='cmnclr'
                                                                                            />{" "}
                                                                                            <b>Meal Type</b> : {bidDetails?.meal_types}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='mt-3 mb-2'>
                                                                            {bidDetails?.travel_by?.length > 10 ? (
                                                                                <h6 className='cmntext'>
                                                                                    <FontAwesomeIcon
                                                                                        icon={firstWord?.toLowerCase() === "train" ? faTrainSubway : firstWord?.toLowerCase() === "flight" ? faPlane : faTaxi}
                                                                                        className='cmnclr'
                                                                                    />{" "}
                                                                                    Travel By
                                                                                </h6>
                                                                            ) : (
                                                                                <h6 className='cmntext'>
                                                                                    <FontAwesomeIcon
                                                                                        icon={bidDetails?.travel_by?.toLowerCase() === "train" ? faTrainSubway : bidDetails?.travel_by?.toLowerCase() === "flight" ? faPlane : faTaxi}
                                                                                        className='cmnclr'
                                                                                    />{" "}
                                                                                    Travel By
                                                                                </h6>
                                                                            )}
                                                                        </div>
                                                                        <div className='cmn'>
                                                                            <p className='cmnp ms-4 py-2'>{bidDetails?.travel_by}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Row>

                                                            <div className='cmn  text-start mt-3'>
                                                                <div className='mt-3 mb-2 text-start cmnp ms-4 pb-2'>
                                                                    <h6 className='cmntext'>
                                                                        <FontAwesomeIcon
                                                                            icon={faPaperclip}
                                                                            className='cmnclr'
                                                                        />{" "}
                                                                        Additional Requirements By Traveller
                                                                    </h6>
                                                                </div>
                                                                {bidDetails?.additional_req && bidDetails?.additional_req.map(req => (
                                                                    <p className='cmnp ms-4 py-2 mb-3' key={req.id}>{req}</p>
                                                                ))
                                                                }

                                                            </div>
                                                        </div>
                                                    </section>

                                                    <section className='my-3' ref={services}>
                                                        <div className=''>
                                                            <div className='mb-3'>
                                                                <button className='w-100 over-btn'>
                                                                    <FontAwesomeIcon className='me-3' icon={faBrush} />{" "}
                                                                    Services
                                                                </button>
                                                            </div>
                                                            <div className='cmn cmn_2 row text-start included_service'>
                                                                <div className='col-12 col-md-6'>
                                                                    <h6 className='cmntext mb-2'>
                                                                        <FontAwesomeIcon icon={faCircleCheck} /> Include
                                                                        Services
                                                                    </h6>
                                                                    <div className='cmn package_service mb-3'>
                                                                        <p style={{ color: "#00B707" }} className='cmnp'>
                                                                            {bidDetails?.include_services.map((e1) => {
                                                                                return (
                                                                                    <>
                                                                                        <div className='d-flex , align-items-center'>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faCheck}
                                                                                                className='me-2'
                                                                                                style={{
                                                                                                    marginBottom: "15px",
                                                                                                }}
                                                                                            />
                                                                                            <div
                                                                                                dangerouslySetInnerHTML={{
                                                                                                    __html: e1.include_services_value,
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='col-12 col-md-6'>
                                                                    <h6 className='cmntext'>
                                                                        <FontAwesomeIcon icon={faXmarkCircle} /> Exclude
                                                                        Services
                                                                    </h6>
                                                                    <div className='cmn package_service'>
                                                                        <p style={{ color: "red" }} className='cmnp'>
                                                                            {bidDetails?.exclude_services.map((e2) => {
                                                                                return (
                                                                                    <>
                                                                                        <div className='d-flex , align-items-center'>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faXmarkCircle}
                                                                                                className='me-2'
                                                                                                style={{
                                                                                                    marginBottom: "15px",
                                                                                                }}
                                                                                            />
                                                                                            {/* <div
                                                                                                dangerouslySetInnerHTML={{
                                                                                                    __html: location?.pathname?.includes(
                                                                                                        "booking-payment",
                                                                                                    )
                                                                                                        ? e2?.include_services_value
                                                                                                        : e2?.exclude_services_value,
                                                                                                }}
                                                                                            /> */}
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>

                                                    <section className='text-start' ref={itenarary}>
                                                        <div className=''>
                                                            <div className='my-3'>
                                                                <button className='w-100 over-btn'>
                                                                    <FontAwesomeIcon
                                                                        icon={faHandshakeAlt}
                                                                        className='me-2'
                                                                    />{" "}
                                                                    Itinerary
                                                                </button>
                                                            </div>
                                                            <div className='cmn cmn__3'>
                                                                {bidDetails?.itineries.map((e3, i) => {
                                                                    return (
                                                                        <>
                                                                            <div className="py-2">
                                                                                <h6 className="cmntext mb-2">Day {i + 1}</h6>
                                                                                <div className='cmn p-2'>

                                                                                    <Row className='align-items-start'>
                                                                                        <div className='col-lg-9'>
                                                                                            <div className='py-1'>
                                                                                                <h6 className='cmnp cmnclr1'>Title</h6>
                                                                                                <p className='cmnp cmnclr font-weight-bolder'>
                                                                                                    {e3?.title}{" "}
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className='py-1'>
                                                                                                <h6 className='cmnp cmnclr1'>Activity</h6>
                                                                                                <p className='cmnp cmnclr font-weight-bolder'>
                                                                                                    <div
                                                                                                        style={{ textAlign: "justify" }}
                                                                                                        dangerouslySetInnerHTML={{
                                                                                                            __html: e3?.activity,
                                                                                                        }}
                                                                                                    />
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        {e3?.photo && <div className='col-lg-3'>
                                                                                            <div className='text-center py-2'>
                                                                                                <img src={e3?.photo} className='img-fluid' style={{ height: "120px", width: "120px" }} />
                                                                                            </div>
                                                                                        </div>}
                                                                                    </Row>


                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </section>

                                                    <section className='pt-4'>
                                                        <div className='hotels-section'>
                                                            <div className='last-hotels'>
                                                                <h3 className="text-start">Hotels</h3>

                                                                {/* <!--------------------------- part-1 ---------------------------------------------> */}

                                                                <div className='hotel-president'>
                                                                    {bidDetails?.hotel_itienrary && bidDetails?.hotel_itienrary?.map((e, index) => (
                                                                        <div className='row gx-5 p-2 second-changes'>
                                                                            <div className='col-xl-6 col-12'>
                                                                                <div id={`carouselExampleCaptions-${index}`} className="carousel slide" data-bs-ride="carousel">
                                                                                    <div className="carousel-indicators">
                                                                                        {e?.hotel_photo?.map((_, idx) => (
                                                                                            <button
                                                                                                key={idx}
                                                                                                type="button"
                                                                                                data-bs-target={`#carouselExampleCaptions-${index}`}
                                                                                                data-bs-slide-to={idx}
                                                                                                className={idx === 0 ? "active" : ""}
                                                                                                aria-current={idx === 0 ? "true" : "false"}
                                                                                                aria-label={`Slide ${idx + 1}`}
                                                                                            ></button>
                                                                                        ))}
                                                                                    </div>
                                                                                    <div className="carousel-inner">
                                                                                        {e?.hotel_photo?.map((ele, idx) => (
                                                                                            <div key={idx} className={`carousel-item ${idx === 0 ? "active" : ""}`}>
                                                                                                <img src={ele} className="d-block w-100" alt="..." />
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                    <button className="carousel-control-prev" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="prev">
                                                                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                                        <span className="visually-hidden">Previous</span>
                                                                                    </button>
                                                                                    <button className="carousel-control-next" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="next">
                                                                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                                                        <span className="visually-hidden">Next</span>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-xl-6 col-12 first-change'>
                                                                                <div className='d-inline-block mb-md-2'>
                                                                                    <span className='d-flex day1-2  text-left align-items-center'>
                                                                                        <div className='day-1-2 py-1 font-changes'>
                                                                                            <h6>Day {e?.days}</h6>
                                                                                        </div>
                                                                                        <div className=' font-changes'>
                                                                                            <h6 className='mb-1 pe-5'>
                                                                                                {e?.hotel_name}
                                                                                            </h6>
                                                                                            <p className='mb-0 text-start'>
                                                                                                <i class='fa-solid fa-location-dot me-2'></i>
                                                                                                Dalhousie
                                                                                            </p>
                                                                                        </div>
                                                                                    </span>
                                                                                </div>
                                                                                <div className='hotels-star'>
                                                                                    <FontAwesomeIcon
                                                                                        icon={faStar}
                                                                                        className='p-1'
                                                                                        style={{ color: "yellow" }}
                                                                                    />
                                                                                    <FontAwesomeIcon
                                                                                        icon={faStar}
                                                                                        className='p-1'
                                                                                        style={{ color: "yellow" }}
                                                                                    />
                                                                                    <FontAwesomeIcon
                                                                                        icon={faStar}
                                                                                        className='p-1'
                                                                                        style={{ color: "yellow" }}
                                                                                    />
                                                                                    <FontAwesomeIcon
                                                                                        icon={faStar}
                                                                                        className='p-1'
                                                                                        style={{ color: "yellow" }}
                                                                                    />
                                                                                    <FontAwesomeIcon
                                                                                        icon={faStar}
                                                                                        className='p-1'
                                                                                        style={{ color: "yellow" }}
                                                                                    />
                                                                                </div>
                                                                                <div className='hotels-footer'>
                                                                                    <p>
                                                                                        {e?.hotel_address} <br />
                                                                                        {e?.hotel_city},{e?.hotel_state}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>

                                <div className="booking-accordion-item mb-2">
                                    <div className="booking-accordion-header" onClick={() => toggleAccordion(3)}>
                                        <h5 className="mb-0">3.Terms & Conditions</h5>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </div>
                                    <div className={`accordion-content ${activeAccordion === 3 ? 'active' : ''}`}>
                                        {activeAccordion === 3 &&
                                            <>
                                                <div className='mt-5'>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: policies?.[2]?.term_and_condition_content,
                                                        }}
                                                    />
                                                </div>
                                                <div className='mt-5'>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: policies?.[0]?.term_and_condition_content,
                                                        }}
                                                    />
                                                </div>
                                                <div className='mt-5'>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: policies?.[1]?.term_and_condition_content,
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="packagebooking-payment-card">
                                <div className="packagebooking-payment-card-body my-3">
                                    <div>
                                        <p className="mb-0">Adults</p>
                                        <p className="mb-0">2 <span>(2000/person)</span></p>
                                    </div>
                                    <div>
                                        <p className="mb-0">Children</p>
                                        <p className="mb-0">2 <span>(2000/person)</span></p>
                                    </div>
                                    <div>
                                        <p className="mb-0">Infants</p>
                                        <p className="mb-0">2 <span>(2000/person)</span></p>
                                    </div>
                                </div>
                                <div className="packagebooking-payment-card-header">
                                    <span className="mb-0">Total</span>
                                    <h5 className="mb-0">₹50000</h5>
                                </div>
                                <div className="packagebooking-payment-card-footer mt-3">
                                    {loading ? (
                                        <img onClick={handleStopLoading} src={loader} alt="" className="spinner" />
                                    ) : (
                                        <button onClick={handleMakeAPayment}>Make A Payment</button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered
            >
                <Modal.Body className="pt-3 px-5">
                    <div className="traveller-information-add-modal">
                        <div className="traveller-information-add-modal-header">
                            <h5>Add Traveller Details</h5>
                        </div>
                        <div>
                            <div className="mt-4">
                                <h5 className="mb-0">Basic Informations</h5>
                                <hr />
                            </div>
                            <div className="traveller-detail-form">
                                <div className="d-flex flex-column">
                                    <label htmlFor="">First Name</label>
                                    <input
                                        type="text"
                                        placeholder="Enter a First Name"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Last Name</label>
                                    <input
                                        type="text"
                                        placeholder="Enter a Last Name"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Date Of Birth</label>
                                    <input
                                        type="date"
                                        value={dateOfBirth}
                                        onChange={(e) => setDateOfBirth(e.target.value)}
                                        max={getMaxDOB()}
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Gender</label>
                                    <select
                                        name=""
                                        id=""
                                        value={gender}
                                        onChange={(e) => setGender(e.target.value)}
                                    >
                                        <option value="">select</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center my-4">
                        <button
                            className="reject-modal reject-modal-no"
                            onClick={handleClose}
                        >
                            No
                        </button>
                        <button
                            className="reject-modal reject-modal-yes ms-2"
                            onClick={handleSubmit}
                        >
                            Yes
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            <hr />
            <Footer />
        </>
    );
}

export default BookingPayment;
