import React, { useRef } from "react"
// import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faGift,
  faMobileScreenButton,
  faPhoneVolume,
} from "@fortawesome/free-solid-svg-icons"
// import axios from "axios";
import { faUser } from "@fortawesome/free-regular-svg-icons"
import "./css/index1.css"
import { useEffect, useState } from "react"
import Modal from "react-bootstrap/Modal"
// import { FormCheck, FormControl, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { NavLink } from "react-router-dom"
// import Button from "@mui/material/Button"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { BASE_URL } from "../BASE_URL"
// import { auth } from "../firebase"
// import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth"
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons"
import Login from "./Login"

function Header({ setShow, show }) {
  const navigate = useNavigate()

  // const [show, setShow] = useState(false)
  // const [show1, setShow1] = useState(false)
  // const [show2, setShow2] = useState(false)
  // const [show3, setShow3] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const [name, setName] = useState("")

  const token = localStorage.getItem("userToken")

  const handleShow = () => setShow(true)

  const vendorToken = localStorage.getItem("vendorToken")

  const goingTravel = () => {
    vendorToken ? navigate("/vendor/home-page") : navigate("/vendor/login")
  }

  const [updateName, setUpdateName] = useState(false)

  const profile = async () => {
    const res = await fetch(`${BASE_URL}user/userprofile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    const data = await res.json()
    setName(data?.data?.[0]?.user_details?.[0]?.name)
    localStorage.setItem('my_id' , data.data[0].user_details[0]._id)
  }

  const [password, setPassword] = useState("")

  useEffect(() => {
    profile()
  }, [])

  useEffect(() => {
    profile()
    setUpdateName(false)
  }, [updateName])

  const [show11, setShow11] = useState(false)

  const handleClose11 = () => setShow11(false)
  const handleShow11 = () => setShow11(true)

  const handleLogout = () => {
    localStorage.clear("userToken")
    handleClose11()
    navigate("/")
  }

  const handleLogoutNo = () => {
    handleClose11()
  }

  return (
    <>
      <div className='' style={{ borderBottom: "1px solid #000" }} bg='light'>
        <Navbar expand='lg' className='px-lg-4 px-md-4 px-sm-2 px-2 nav_width'>
          <NavLink
            to='/'
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Navbar.Brand className='logo'>
              <img
                src='/syt_logo.png'
                alt=''
                className='me-2'
                style={{ height: "40px" }}
              />
              START YOUR TOUR
            </Navbar.Brand>
          </NavLink>
          <Navbar.Toggle aria-controls='navbarScroll' />
          <Navbar.Collapse id='navbarScroll'>
            <Nav
              className='m-right my-2 my-lg-0'
              style={{ maxHeight: "200px", marginLeft: "auto" }}
              navbarScroll
            >
              <Nav.Link href='tel:+91 96623 99609' className='text-dark'>
                <FontAwesomeIcon icon={faPhoneVolume} className='me-1' />
                +91 96623 99609
              </Nav.Link>
              <Nav.Link href='' className='text-dark' onClick={goingTravel}>
                <FontAwesomeIcon icon={faPhoneVolume} className='me-1' />
                Travel Agent? Join Us
              </Nav.Link>
              <Nav.Link href='' className='text-dark'>
                <FontAwesomeIcon icon={faGift} className='me-1' />
                Offers
              </Nav.Link>
              <Nav.Link href='' className='text-dark'>
                <FontAwesomeIcon icon={faMobileScreenButton} className='me-1' />
                Download App
              </Nav.Link>
            </Nav>
            {token ? (
              <div
                className='cmn p-2'
                style={{
                  backgroundColor: "#09646d",
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={handleClickMenu}
              >
                <FontAwesomeIcon icon={faUser} />{" "}
                {name?.length > 6 ? `${name?.slice(0, 6)}...` : name}
              </div>
            ) : (
              <a
                href='#'
                className='log_btn w-0'
                onClick={handleShow}
                variant='primary'
              >
                Login
              </a>
            )}
            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <NavLink
                to='/profile'
                style={{ textDecoration: "none", color: "black" }}
              >
                <MenuItem onClick={handleCloseMenu}>My Profile</MenuItem>
              </NavLink>
              <NavLink
                to='/custom_requirement'
                style={{ textDecoration: "none", color: "black" }}
              >
                <MenuItem onClick={handleCloseMenu}>My Requirements</MenuItem>
              </NavLink>
              <NavLink
                to='/my_package'
                style={{ textDecoration: "none", color: "black" }}
              >
                <MenuItem onClick={handleCloseMenu}>My Booking</MenuItem>
              </NavLink>
              <MenuItem
                onClick={() => {
                  handleCloseMenu()
                  handleShow11()
                  // navigate("/");
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </Navbar.Collapse>
        </Navbar>
      </div>

      <div>
        <Modal
          show={show11}
          onHide={handleClose11}
          backdrop='static'
          keyboard={false}
          style={{ zIndex: "10101010" }}
          centered
        >
          <Modal.Body>
            <div className='pt-3 pb-2 px-3 text-center'>
              <FontAwesomeIcon
                icon={faRightFromBracket}
                className='mb-3'
                style={{ fontSize: "50px" }}
              />
              <h5 className='text-center'>Are You Sure You Want To Logout?</h5>
              <div className='logout-btn d-flex justify-content-center mt-3'>
                <button
                  className='logout-btn-1'
                  variant='secondary'
                  onClick={handleLogoutNo}
                >
                  No
                </button>
                <button
                  className='logout-btn-2 ms-2'
                  variant='primary'
                  onClick={handleLogout}
                >
                  Yes
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>

     

      <Login setName={setName} setShow={setShow} show={show} />

      <ToastContainer />
    </>
  )
}

export default Header
