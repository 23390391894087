/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  faArrowLeft,
  faBell,
  faEnvelopeOpen,
  faCircle,
  faInbox,
  faLocationPin,
  faPhone,
  faTag,
  faUser,
  faCalendarDays
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { Container, Form, Row } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import My_pannel from "./My_pannel"
import Header2 from "./Header2"
import { useNavigate } from "react-router-dom"
import { BASE_URL } from "../BASE_URL"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import ProfileSection from "./SecondNavbar"

const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return 'Invalid date format';
  }

  // Format options to get the date in 'dd-mm-yyyy' format
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  return date.toLocaleDateString('en-GB', options);
};

function Requirements_details(props) {
  const [details, setDetails] = useState([])
  const navigate = useNavigate()
  const { id } = useParams()

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("sm"))

  const Requirement = async () => {
    const token = localStorage.getItem("vendorToken")
    const res = await fetch(`${BASE_URL}customrequirements/details?_id=${id}`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()

    setDetails(data.data)
  }

  const [BidData, setBidData] = useState([])
  const [BidDataId, setBidDataId] = useState()

  const getBidPackage = async () => {
    const token = localStorage.getItem("vendorToken")
    const res = await fetch(`${BASE_URL}bidpackage/agencybid`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()

    const matchingBidPackage = data.data.find(
      (bidPackage) => bidPackage.custom_requirement_id === id,
    )

    if (matchingBidPackage) {
      setBidData(matchingBidPackage)
      setBidDataId(matchingBidPackage._id)
    } else {
    }
  }

  useEffect(() => {
    Requirement()
    getBidPackage()
  }, [id])

  const [editedata, setEditedData] = useState("")

  const Call = async () => {
    const token = localStorage.getItem("vendorToken")
    const res = await fetch(`${BASE_URL}agency/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    const data = await res.json()
    setEditedData(data?.data?.[0])
  }

  useEffect(() => {
    Call()
  }, [])

  const handleNav = () => {
    navigate("/vendor/myprofile")
  }

  return (
    <>
      <Header2 />
      <div className='costum_container'>
        <My_pannel />
        <div>

        <ProfileSection title="Custome Package Details" />

          <div className='requirements_details'>
            <section className='requirements_details_section pt-4'>
              {details.map((e) => {
                return (
                  <>
                    <div
                      className='requirements_details_1 green_border_requirments'
                      key={e._id}
                    >
                      <div className='p-3'>
                        <span className='cmnbkg span_squar'>1</span>
                        <p className='text-end'>
                          Status :{" "}
                          <span
                            style={{
                              fontWeight: "500",
                              color: "green",
                              fontSize: "20px",
                            }}
                          >
                            {BidData.bid_status
                              ? BidData.bid_status
                              : "Pending"}
                          </span>
                        </p>
                        <div className='py-2 px-xl-4 px-lg-4 px-md-3 px-sm-2 px-1'>
                          <Row className='py-2'>
                            <div className='col-md-6 col-xl-4 col-12'>
                              <div
                                style={{
                                  borderRight: mobile
                                    ? "unset"
                                    : "1px solid #B8B8B8",
                                }}
                              >
                                <div className='pt-3'>
                                  <h6 className="">
                                    <FontAwesomeIcon
                                      icon={faCircle}
                                      style={{ color: "#00B707" }}
                                    />{" "}
                                    <span className="font-vendor">Departure</span>: {e.departure}
                                  </h6>
                                  <h6>
                                    <FontAwesomeIcon
                                      icon={faCircle}
                                      style={{ color: "#00B707" }}
                                    />{" "}
                                    <span className="font-vendor">Destination</span>: {e.destination}
                                  </h6>
                                </div>
                                <div>
                                  <h6 className="font-vendor">Categories</h6>
                                  <div className='d-flex align-items-center flex-wrap gap-2'>
                                    {e.destination_category.map((ele) => {
                                      return (
                                        <>
                                          <Link className='cmn-re-btn'>
                                            {ele.category_name}
                                          </Link>
                                        </>
                                      )
                                    })}
                                  </div>
                                </div>
                                <h6 className="pt-1 font-vendor">Travel by</h6>
                                <div className='pb-1 d-flex align-items-center flex-wrap gap-2'>
                                  {e.travel_by.map((ele) => {
                                    return (
                                      <>
                                        <Link className='cmn-re-btn'>
                                          {ele}
                                        </Link>
                                      </>
                                    )
                                  })}
                                </div>
                                {/* <div>
                                  <h6 className='py-2 font-vendor'>Total People</h6>
                                  <div>
                                    <ButtonGroup
                                      aria-label='Basic example'
                                      style={{
                                        border: "1px solid #B8B8B8",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      <Button
                                        variant='white'
                                        className='btn_group_style'
                                        style={{
                                          borderRight: "1px solid #B8B8B8",
                                        }}
                                      >
                                        Adults {e.total_adult}
                                      </Button>
                                      <Button
                                        variant='white'
                                        className='btn_group_style'
                                        style={{
                                          borderRight: "1px solid #B8B8B8",
                                        }}
                                      >
                                        Infants {e.Infant}
                                      </Button>
                                      <Button
                                        variant='white'
                                        className='btn_group_style'
                                        style={{}}
                                      >
                                        Children {e.total_child}
                                      </Button>
                                    </ButtonGroup>
                                    <button className='cmn-re-btn btn_group_style ms-2 py-1 mt-3 mt-md-0'>
                                      Total{" "}
                                      {e.total_adult + e.Infant + e.total_child}
                                    </button>
                                  </div>
                                </div> */}
                                <div>
                                  <h6 className='py-2 font-vendor'>Total People</h6>
                                  <div className="row me-4">
                                    <div className="col-12">
                                      <p className="cmnp ps-1" >Adults (12+ years) : <span className="float-end">{e?.total_adult}</span></p>
                                      <p className="cmnp ps-1" >Children (2 to 12 years) : <span className="float-end">{e?.total_child}</span></p>
                                      <p className="cmnp ps-1 mb-1" >Infants (Below 2 year old) : <span className="float-end">{e?.Infant}</span></p>
                                      <hr className="my-1" />
                                      <p className="cmnp ps-1 fw-bold" >Total : <span className="float-end">{Number(e?.total_adult) + Number(e?.total_child) + Number(e?.Infant)}</span></p>
                                    </div>
                                  </div>
                                </div>
                                <div className='pt-3 d-flex gap-2 align-items-center'>
                                  <h6 className="font-vendor mb-0">
                                    Personal Care :{" "}
                                  </h6>
                                  {e.personal_care}
                                </div>
                              </div>
                            </div>
                            <div className='col-md-6 col-xl-4 col-12'>
                              <div
                                className="d-flex flex-column h-100"
                                style={{
                                  borderRight: mobile
                                    ? "unset"
                                    : "1px solid #B8B8B8",
                                }}
                              >
                                <div>
                                  <p className='cmnp py-1'>
                                    <FontAwesomeIcon icon={faUser} /> <span className="font-vendor ms-1">Name</span> :
                                    {e.full_name}
                                  </p>
                                  <p className='cmnp py-1'>
                                    <FontAwesomeIcon icon={faInbox} /> <span className="font-vendor ms-1">Email Id</span> :
                                    {e.email_address}
                                  </p>
                                  <p className='cmnp py-1'>
                                    <FontAwesomeIcon icon={faPhone} /> <span className="font-vendor ms-1">Phone Number</span> : {e.mobile_no}
                                  </p>
                                  <p className='cmnp py-1'>
                                    <FontAwesomeIcon icon={faLocationPin} />{" "}
                                    <span className="font-vendor ms-1">State</span> : {e.state}
                                  </p>
                                  <p className='cmnp py-1'>
                                    <FontAwesomeIcon icon={faLocationPin} />{" "}
                                    <span className="font-vendor ms-1">City</span> : {e.city}
                                  </p>
                                  <p className='cmnp py-1'>
                                    <FontAwesomeIcon icon={faTag} />
                                    <span className="font-vendor ms-1">Budget Per Person</span> : {e.budget_per_person} / Person
                                  </p>
                                  <p className='cmnp py-1'>
                                    <FontAwesomeIcon icon={faCalendarDays} />
                                    <span className="font-vendor ms1">Date</span> : {formatDate(e?.createdAt)}
                                  </p>
                                </div>

                                <div className='py-3 pt-3 pb-4 mt-auto'>
                                  {(BidData.bid_status !== "submit" &&
                                    BidData.bid_status !== "reject") && (
                                      <Link
                                        to={`/vendor/Submit_package_form/${e._id}`}
                                        className='batann_2_2'
                                      >
                                        Submit Bid
                                      </Link>
                                    )}
                                  {
                                    BidData.bid_status === "submit" &&
                                    (
                                      <Link
                                        to={`/vendor/Submit_package_form/${e._id}`}
                                        className='batann_2_2'
                                      >
                                        View Submitted Bid
                                      </Link>
                                    )
                                  }
                                </div>
                              </div>
                            </div>
                            <div className='col-md-6 col-xl-4 col-12'>
                              <div className='py-2'>
                                <h6 className="font-vendor">Travel Between</h6>
                                <div className='pb-1 d-flex flex-wrap align-items-center'>
                                  <h6>
                                    {formatDate(e.start_date)} to{" "}
                                    {formatDate(e.end_date)}
                                  </h6>

                                </div>
                                <div className="d-flex align-items-center ">
                                  <h6 className="me-2"><span className="font-vendor">Total Days Of Traveling</span></h6>
                                  <Link className='cmn-re-btn'>
                                    {e.total_travel_days} Days
                                  </Link>
                                </div>
                                <div className='py-1 d-flex align-items-center'>
                                  <h6 className="font-vendor">Hotel Type</h6>
                                  {e.hotel_type.map((ele) => {
                                    return (
                                      <>
                                        <Link className='cmn-re-btn ms-2'>
                                          {ele}
                                        </Link>
                                      </>
                                    )
                                  })}
                                </div>
                                <div className="d-flex align-items-center">
                                  <h6 className="font-vendor">Meal Type</h6>
                                  <Link className='cmn-re-btn ms-2'>
                                    {e.meal_type}
                                  </Link>
                                </div>
                                <div className='py-1 d-flex align-items-center'>
                                  <h6 className="font-vendor">Meal Required</h6>
                                  {e.meal_require.map((ele) => {
                                    return (
                                      <>
                                        <Link className='cmn-re-btn ms-2'>
                                          {ele}
                                        </Link>
                                      </>
                                    )
                                  })}
                                </div>
                                <div className='py-2'>
                                  <h6 className="font-vendor">Additional Requirements By Traveler</h6>
                                  <textarea
                                    name=""
                                    id=""
                                    cols="30"
                                    rows="4"
                                    className='extra_requirements_input px-2 py-2 rounded'
                                    value={e.additional_requirement[0]}
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </>
                )
              })}
            </section>
          </div>
        </div>
      </div>
    </>
  )
}

export default Requirements_details
