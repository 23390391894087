import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBell,
    faEnvelopeOpen,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import My_pannel from "./My_pannel";
import Header2 from "./Header2";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from "../BASE_URL";
import './css/HotelBooking.css'
import ProfileSection from "./SecondNavbar";

const ViewHotelBooking = () => {
    const navigate = useNavigate();

    const [editedata, setEditedData] = useState("");

    const Call = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}agency/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setEditedData(data?.data?.[0]);
    };

    useEffect(() => {
        Call();
    }, []);

    const { id } = useParams();

    const [bookingData, setBookingData] = useState([]);

    const getBookedHotel = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}hotel_booking_syt/hotel_book_details/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setBookingData(data.data[0]);
        console.log(data.data[0]);
        setbookingstatus(data.data[0].status);

    };

    useEffect(() => {
        getBookedHotel();
    }, []);

    const [bookingstatus, setbookingstatus] = useState();

    const handleStatusChange = (e) => {
        setbookingstatus(e.target.value);
    };

    const handleUpdateStatus = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}hotel_booking_syt/updateStatus/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({
                status: bookingstatus
            }),
        });

        if (!res.ok) {
            throw new Error(`Failed to update status `);
        }
        else {
            toast.success("Status Updated", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            getBookedHotel();
            return res.json();
        }

    };

    const handleNav = () => {
        navigate("/vendor/myprofile");
    };

    return (
        <>
            <Header2 />
            <div className="costum_container">
                <My_pannel />
                <ToastContainer />
                <div style={{ backgroundColor: "whitesmoke", minHeight: "100vh" }}>
                     <ProfileSection title="Hotel Booking" />

                    <section className="booked_packega_margin p-4">
                        {bookingData.length !== 0 && <div>
                            <div className="booked-details">
                                <div className="booked-hotel-details-container">
                                    <div className='booked-details-title'>Hotel Details</div>
                                    {
                                        bookingData.hotel_details ? (
                                            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                                <div className="booked-details-name"><span className="cust-details-headings">Hotel Name:</span>{bookingData.hotel_details[0].hotel_name}</div>
                                                <div className="booked-details-address"><span className="cust-details-headings">Address: </span>{bookingData.hotel_details[0].hotel_address}</div>
                                            </div>
                                        ) : (
                                            <div>N/A</div>
                                        )
                                    }
                                </div>
                                <div className="booked-user-details-container">
                                    <div className='booked-details-title'>Costumer Details</div>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                        <div><span className="cust-details-headings">Customer Name:</span>{bookingData.users ? bookingData.users[0].customer_details.name : "N/A"}</div>
                                        <div><span className="cust-details-headings">Email:</span>{bookingData.users ? bookingData.users[0].customer_details.email_address : "N/A"}</div>
                                        <div><span className="cust-details-headings">Mobile No.:</span>{bookingData.users ? bookingData.users[0].phone : "N/A"}</div>
                                        <div><span className="cust-details-headings">Address:</span>{bookingData.users ? bookingData.users[0].customer_details.city : "N/A"} {bookingData.users ? bookingData.users[0].customer_details.state : "N/A"}</div>
                                    </div>
                                </div>
                                <div className="booked-status-container">
                                    <div className='booked-details-title'>Booked Status</div>
                                    <div className='booked-status'>{bookingData.status === 'pending' ? "Pending" : (bookingData.status === "approve" ? "Approved" : "Rejected")}</div>
                                    <div style={{ paddingTop: "10px", borderTop: "1px solid #09646D" }}>
                                        <div className="update-status-title">Update Status:</div>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <select value={bookingstatus} onChange={handleStatusChange} className="update-status-select">
                                                <option value="pending">Pending</option>
                                                <option value="approve">Approve</option>
                                                <option value="reject">Reject</option>
                                            </select>
                                            <button onClick={handleUpdateStatus} className="update-status-button">Update</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div>
                                <div className='booked-room-details'>Room Details</div>
                                <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                    <div><span className="cust-details-headings">Room Title:</span>{bookingData.room_details[0].room_title}</div>
                                    <div className="cust-details-headings">Images</div>
                                    <div style={{ display: "flex", flexWrap:"wrap", gap: "10px" }}>{bookingData.room_details[0].photos.map(mp => (
                                        <img src={mp} alt="img" style={{height:"200px", width:"200px", borderRadius:"10px"}}></img>
                                    ))}</div>
                                    <div><span className="cust-details-headings">Price:</span>{bookingData !== 0 ? bookingData.room_details[0].price : ""}</div>
                                </div>
                            </div>
                            <div className="booked-rooms-container">

                            </div>
                        </div>}
                    </section>
                </div>
            </div>
        </>
    );
};

export default ViewHotelBooking;
