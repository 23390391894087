import React, { useContext, useEffect, useState, useRef } from "react"
import {
    faArrowLeft,
    faBell,
    faEnvelopeOpen,
    faUser,
    faPlus,
    faTrashAlt,
    faTrash,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import My_pannel from "./My_pannel"
import "../Project/css/index1.css"
import Header2 from "./Header2"
import { Modal } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import ButtonGroup from "react-bootstrap/ButtonGroup"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { useNavigate } from "react-router-dom"
import PropTypes from "prop-types"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Select from "react-select"
import { day } from "../App"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { BASE_URL } from "../BASE_URL"
import { Avatar } from "@mui/material"
import Collapse from 'react-bootstrap/Collapse';
import loader from "../images/loading.png"
import ProfileSection from "./SecondNavbar"
// import { colourOptions } from "../data";


const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
};

function AddMyPackage(props) {

    const navigate = useNavigate()

    const [loading, setloading] = useState(false)

    const [editedata, setEditedData] = useState("")

    const Call = async () => {
        const token = localStorage.getItem("vendorToken")
        const res = await fetch(`${BASE_URL}agency/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        })
        const data = await res.json()
        setEditedData(data?.data?.[0])
    }

    useEffect(() => {
        Call()
    }, [])

    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(true);

    const [hotelList, setHotelList] = useState("")
    const [selectedHotel, setSelectedHotel] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const fetchItineraryHotel = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}api/hotel_itienrary/displayAgencyById`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setHotelList(data?.data);
    };

    useEffect(() => {
        fetchItineraryHotel()
    }, []);

    const [formData1, setFormData1] = useState({
        name: "",
        price_per_person: "",
        total_days: "",
        total_nights: "",
        destination: "",
        destination_category_id: "",
        meal_required: "",
        meal_type: "",
        travel_by: "",
        hotel_type: "",
        more_details: "",
        sightseeing: "",
        place_to_visit_id: "",
        status: "",
        room_sharing: "",
        package_type: "",
    });

    console.log(formData1)

    const [checkedItems, setCheckedItems] = useState("");

    const handleCheckboxChange = (event) => {
        const itemName = event.target.name;
        if (event.target.checked) {
            setCheckedItems([...checkedItems, itemName]);
        } else {
            setCheckedItems(checkedItems.filter((item) => item !== itemName));
        }
    };


    const [destinationList, setDestinationList] = useState("");

    const handleCheckboxChange2 = (event) => {
        const itemName = event.target.name;
        if (event.target.checked) {
            setDestinationList([...destinationList, itemName]);
        } else {
            setDestinationList(destinationList.filter((item) => item !== itemName));
        }
    };

    const [hotelType, setHotelType] = useState("");

    const handleCheckboxChange3 = (event) => {
        const itemName = event.target.name;
        if (event.target.checked) {
            setHotelType([...hotelType, itemName]);
        } else {
            setHotelType(hotelType.filter((item) => item !== itemName));
        }

    };
    const handleOptionChange = (event) => {
        const { name, value } = event.target;
        setFormData1({ ...formData1, [name]: value })
    };

    const [priceRanges, setPriceRanges] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handleStartDateChange = (event) => {
        const selectedStartDate = event.target.value;
        setStartDate(selectedStartDate);

        // Ensure end date is not before start date
        if (endDate && new Date(endDate) < new Date(selectedStartDate)) {
            setEndDate(selectedStartDate);
        }
    };

    const handleEndDateChange = (event) => {
        const selectedEndDate = event.target.value;
        setEndDate(selectedEndDate);
    };


    const [packageid, setpackageid] = useState("");
    const [Days, setDays] = useState("");
    const [Nights, setNights] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation
        if (!formData1.name) {
            toast.error("Add Name of the Package", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!formData1.total_days || isNaN(Number(formData1.total_days))) {
            toast.error("Add valid Total Days", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!formData1.total_nights || isNaN(Number(formData1.total_nights))) {
            toast.error("Add valid Total Nights", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!selectedDestinationId) {
            toast.error("Select a Destination", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!destinationList || destinationList.length === 0) {
            toast.error("Select Destination Category", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (checkedItems.length === 0) {
            toast.error("Select Meal Required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!formData1.meal_type) {
            toast.error("Select Meal Type", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!formData1.travel_by) {
            toast.error("Select Travel By", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!hotelType) {
            toast.error("Select Hotel Type", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!formData1.more_details) {
            toast.error("Add More Details", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!formData1.sightseeing) {
            toast.error("Add Sightseeing Details", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!selectplacetovisit || selectplacetovisit.length === 0) {
            toast.error("Select Places to Visit", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!formData1.status) {
            toast.error("Select Status", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!includeServices || includeServices.length === 0) {
            toast.error("Add Included Services", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!excludeServices || excludeServices.length === 0) {
            toast.error("Add Excluded Services", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!startDate) {
            toast.error("Select Start Date", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!endDate) {
            toast.error("Select End Date", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (priceRanges?.length < 1) {
            toast.error("Enter Price Range", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        setloading(true)

        try {
            const token = localStorage.getItem("vendorToken");

            const response = await fetch(`${BASE_URL}package`, {
                method: 'POST',
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: formData1.name,
                    price_and_date: priceRanges,
                    total_days: formData1.total_days,
                    total_nights: formData1.total_nights,
                    destination: selectedDestinationId,
                    destination_category_id: destinationList,
                    meal_required: checkedItems,
                    meal_type: formData1.meal_type,
                    travel_by: formData1.travel_by,
                    hotel_type: hotelType,
                    more_details: formData1.more_details,
                    sightseeing: formData1.sightseeing,
                    place_to_visit_id: selectplacetovisit,
                    status: formData1.status,
                    include_service: includeServices,
                    exclude_service: excludeServices,
                    start_date: startDate,
                    end_date: endDate,
                    room_sharing: formData1?.room_sharing,
                    package_type: formData1?.package_type,
                    status: true
                })
            });

            const result = await response.json();

            if (response.ok && result.status === "OK") {
                setloading(false)
                toast.success("Package added successfully", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
                setTimeout(() => {
                    setOpen2(!open2);
                    setOpen1(!open1);
                    setpackageid(result.data._id);
                    setDays(result.data.total_days);
                    setNights(result.data.total_nights);
                    // navigate("/")
                }, 2000);
            } else {
                setloading(false)
                toast.error(`Error: ${result.message}`, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            }
        } catch (error) {
            setloading(false)
            toast.error("An error occurred while submitting the form", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            console.error('Error:', error);
        }
    };


    const [maxItineraries, setmaxItineraries] = useState(0);

    useEffect(() => {
        setmaxItineraries(Days, Nights)
    }, [Days, Nights])





    // get api for select placetovisit

    const [placetovisit, setPlacetovisit] = useState([])


    const places = async () => {
        const token = localStorage.getItem("vendorToken")

        const res = await fetch(`${BASE_URL}placetovisit`, {
            method: "GET",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        });
        const data = await res.json();
        setPlacetovisit(data.data);

    };

    useEffect(() => {
        places();
    }, []);


    const [selectplacetovisit, setSelectplacetovisit] = useState("");

    const handleSelectPlace = (event) => {
        const selectedId = event.target.value; // Get the selected option's ID
        setSelectplacetovisit(selectedId); // Update the state with the selected ID
    };



    // get api for select destination

    const [destination, setDestination] = useState([])



    const Dest = async () => {
        const token = localStorage.getItem("vendorToken")

        const res = await fetch(`${BASE_URL}destination/alldestination`, {
            method: "GET",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        });
        const data = await res.json();
        setDestination(data.data);

    };

    useEffect(() => {
        Dest();
    }, []);

    // select for destination category 

    const [Destcat, setDestcat] = useState([]);

    useEffect(() => {
        const fetchCategoryList = async () => {
            try {
                const token = localStorage.getItem("VendorToken");
                const response = await fetch(`${BASE_URL}destinationcategory`, {
                    headers: {
                        Authorization: token,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setDestcat(data.data);

            } catch (error) {
                console.log(error);
            }
        };
        fetchCategoryList();
    }, []);




    const [selectedDestinationId, setSelectedDestinationId] = useState("");

    const selectedDestination = destination.find((e) => e?._id === selectedDestinationId)
    console.log(selectedDestination)

    const handleSelect = (event) => {
        const selectedId = event.target.value;
        setSelectedDestinationId(selectedId);

    };


    const [includeServices, setIncludeServices] = useState([]);
    const [newService, setNewService] = useState("");

    const handleAddService = () => {
        if (newService.trim() !== "") {
            setIncludeServices((prevServices) => [...prevServices, newService]);
            setNewService("");
        }
    };


    const handleRemoveService = (index) => {
        // Create a new array without the item to be removed
        const updatedServices = includeServices.filter((_, i) => i !== index);
        setIncludeServices(updatedServices);
    };



    const [excludeServices, setexcludeServices] = useState([]);
    const [exclude, setExclude] = useState("");

    const handleAddExclude = () => {
        if (exclude.trim() !== "") {
            setexcludeServices((prevServices) => [...prevServices, exclude]);
            setExclude(""); // Clear the input field after adding
        }
    };


    const handleRemoveExclude = (index) => {
        // Create a new array without the item to be removed
        const updatedServices = excludeServices.filter((_, i) => i !== index);
        setexcludeServices(updatedServices);
    };

    const [itineraries, setItineraries] = useState([]);
    const [formDatait, setFormDatait] = useState({
        title: '',
        photoFile: null,
        hotelName: '',
        description: ''
    });
    const [isEdit, setIsEdit] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [open, setOpen] = useState(false);

    // Get max number of itineraries allowed

    const handleOpen = (index = null) => {
        setOpen(true);
        if (index !== null) {
            setIsEdit(true);
            setEditIndex(index);
            setFormDatait({
                ...itineraries[index],
            });
        } else {
            setIsEdit(false);
            setFormDatait({
                title: '',
                photoFile: null,
                hotelName: '',
                description: ''
            });
        }
    };

    const handleClose = () => {
        setOpen(false);
        setFormDatait({
            title: '',
            photoFile: null,
            hotelName: '',
            description: ''
        });
    };

    const handleChangeit = (e) => {
        if (e.target.name === 'photoFile') {
            setFormDatait({ ...formDatait, photoFile: e.target.files[0] });
        } else {
            const { name, value } = e.target;
            setFormDatait({ ...formDatait, [name]: value });
        }
    };

    const handleSaveit = () => {
        const { title, photoFile, hotelName, description } = formDatait;

        // Validation
        if (!title) {
            toast.error("Title is required.", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!photoFile) {
            toast.error("Photo file is required.", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        // if (!hotelName) {
        //     toast.error("Hotel name is required.", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
        //     return;
        // }
        if (!description) {
            toast.error("Description is required.", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        const newItinerary = {
            title: formDatait.title,
            photoFile: formDatait.photoFile,
            // hotelName: formDatait.hotelName,
            description: formDatait.description
        };

        if (isEdit) {
            const updatedItineraries = [...itineraries];
            updatedItineraries[editIndex] = newItinerary;
            setItineraries(updatedItineraries);
            // toast.success("Itinerary updated successfully", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
        } else {
            setItineraries([...itineraries, newItinerary]);
            // toast.success("Itinerary added successfully", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
        }

        handleClose();
    };


    const handleSubmit2 = async (e) => {
        e.preventDefault();

        if (!itineraries || itineraries.length === 0) {
            toast.error("Itineraries Required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        if (!selectedHotel) {
            toast.error("Please Select Hotel", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        try {
            const token = localStorage.getItem("vendorToken");

            const requests = itineraries.map((itinerary, index) => {
                const formData = new FormData();
                formData.append("package_id", packageid);
                formData.append("title", itinerary.title);
                formData.append("day", index + 1);
                formData.append("activity", itinerary.description);
                formData.append("hotel_itienrary_id", selectedHotel?._id);
                if (itinerary.photoFile) {
                    formData.append("photo", itinerary.photoFile);
                }

                return fetch(`${BASE_URL}itinerary`, {
                    method: "POST",
                    headers: {
                        Authorization: token,
                    },
                    body: formData,
                }).then(response => response.json());
            });

            const responses = await Promise.all(requests);

            const allSuccessful = responses.every(response => response.status === "OK");

            if (allSuccessful) {
                setTimeout(() => {
                    toast.success("Itineraries added successfully", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
                    navigate("/vendor/mypackages");
                }, 2000);
            }
        } catch (error) {
            console.error("Error submitting itineraries:", error);
        }
    };


    const [openRangeModal, setOpenRangeModal] = useState(false);
    const [price, setPrice] = useState('');
    const [startDatePrice, setStartDatePrice] = useState(null);
    const [endDatePrice, setEndDatePrice] = useState(null);


    const handleOpenInsertModal = () => {
        if (!startDate || !endDate) {
            toast.error("Please Select Package Start Date And End Date", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
        } else {
            setOpenRangeModal(true);
        }
    };

    const handleCloseRangeModal = () => {
        setOpenRangeModal(false);
    };

    const today = new Date().toISOString().split('T')[0];
    
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tommorowDate = tomorrow.toISOString().split('T')[0];

    const handleStartDatePriceChange = (e) => {
        const newStartDate = e.target.value;
        setStartDatePrice(newStartDate);

        // If endDatePrice is before the new startDatePrice, clear it
        if (endDatePrice && new Date(endDatePrice) < new Date(newStartDate)) {
            setEndDatePrice('');
        }
    };

    const handleEndDatePriceChange = (e) => {
        setEndDatePrice(e.target.value);
    };

    const handleSave = () => {
        if (price && startDatePrice && endDatePrice) {
            const newPriceRange = {
                price_per_person: Number(price),
                price_start_date: startDatePrice,
                price_end_date: endDatePrice,
            };

            // Add the new price range to the array
            setPriceRanges([...priceRanges, newPriceRange]);

            // Reset the form and close the modal
            setPrice('');
            setStartDatePrice(null);
            setEndDatePrice(null);
            setOpenRangeModal(false);
        } else {
            alert('Please fill in all fields');
        }
    }

    const handleDelete = (index) => {
        const updatedPriceRanges = priceRanges.filter((_, i) => i !== index);
        setPriceRanges(updatedPriceRanges);
    };

    // get api for select placetovisit

    const [margin, setMargin] = useState([])


    const fetchMargin = async () => {
        const token = localStorage.getItem("vendorToken")

        const res = await fetch(`${BASE_URL}api/package_profit_margin/all`, {
            method: "GET",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        });
        const data = await res.json();
        console.log(data?.data)
        setMargin(data.data);

        // Ensure destination is defined and selectedDestinationId is valid
        if (destination && selectedDestinationId) {
            const destinationName = destination.find((e) => e?._id === selectedDestinationId);
            console.log(destinationName);

            // Ensure destinationName is found
            if (destinationName) {
                const findMarginData = data?.data?.find(
                    (ele) => ele?.state_name?.toLowerCase() === destinationName?.destination_name?.toLowerCase()
                );
                console.log(findMarginData)
                setMargin(findMarginData?.month_and_margin_user || []);
            } else {
                console.warn("No matching destination found.");
                setMargin([]);
            }
        } else {
            console.warn("Destination data or selectedDestinationId is missing.");
            setMargin([]);
        }

    };

    useEffect(() => {
        fetchMargin();
    }, [selectedDestinationId]);

    return (
        <>
            <Header2 />
            <div className='costum_container'>
                <My_pannel />
                <ToastContainer />
                <div style={{ backgroundColor: "whitesmoke" }}>
                    <ProfileSection title="Submit Form" />
                    <div className='p-3'>
                        <Box sx={{ width: "100%" }}>
                            <div className="vendoraddpackage-container">
                                <Collapse in={open2}>
                                    <div className="vendoraddpackage-form-container">
                                        <div className="vendoraddpackage-header">Add Package</div>
                                        <div className="vendoraddpackage-form">
                                            <div className="vendoraddpackage-flex">
                                                <div className="vendoraddpackage-input-group">
                                                    <select onChange={handleSelect} name="Destinations" className="vendoraddpackage-select">
                                                        <option selected disabled>Select Destination</option>
                                                        {destination.map((ele) => (
                                                            <option key={ele._id} value={ele._id}>{ele.destination_name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="vendoraddpackage-input-group">
                                                    <select onChange={handleSelectPlace} name="Destinations" className="vendoraddpackage-select">
                                                        <option selected disabled>Select Place To Visit</option>
                                                        {placetovisit.filter(ele => ele.destination_id === selectedDestinationId).map((ele) => (
                                                            <option key={ele._id} value={ele._id}>{ele.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="vendoraddpackage-flex">
                                                <div className="vendoraddpackage-input-group">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        placeholder="Enter package name"
                                                        value={formData1.name}
                                                        onChange={handleOptionChange}
                                                        className="vendoraddpackage-input"
                                                    />
                                                </div>
                                                <div className="vendoraddpackage-input-group">
                                                    <select
                                                        name="package_type"
                                                        value={formData1.package_type}
                                                        onChange={handleOptionChange}
                                                        className="vendoraddpackage-select"
                                                    >
                                                        <option value="">Select Package Type</option>
                                                        <option value="Land Packge">Land Package</option>
                                                    </select>

                                                </div>
                                            </div>
                                            <div className="vendoraddpackage-flex">
                                                <div className="vendoraddpackage-input-group">
                                                    <select
                                                        name="total_days"
                                                        value={formData1.total_days}
                                                        onChange={handleOptionChange}
                                                        className="vendoraddpackage-select"
                                                    >
                                                        <option value="">Select days</option>
                                                        {[...Array(30).keys()].map(day => (
                                                            <option key={day + 1} value={day + 1}>{day + 1}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="vendoraddpackage-input-group">
                                                    <select
                                                        name="total_nights"
                                                        value={formData1.total_nights}
                                                        onChange={handleOptionChange}
                                                        className="vendoraddpackage-select"
                                                        disabled={!formData1.total_days}
                                                    >
                                                        <option value="">Select nights</option>
                                                        {formData1.total_days && [-1, 0, 1].map(offset => {
                                                            const nightValue = parseInt(formData1.total_days) + offset;
                                                            if (nightValue > 0) {
                                                                return <option key={nightValue} value={nightValue}>{nightValue}</option>;
                                                            }
                                                            return null;
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div style={{ display: "flex" }}>
                                                <div style={{ display: "flex", flex: "1" }}>
                                                    <div className="vendoraddpackage-checkbox-group">
                                                        <label className="vendoraddpackage-label">Meal Required</label>
                                                        <div className="vendoraddpackage-checkbox">
                                                            <input type="checkbox" name="Breakfast" onChange={handleCheckboxChange} />
                                                            <label>Breakfast</label>
                                                        </div>
                                                        <div className="vendoraddpackage-checkbox">
                                                            <input type="checkbox" name="Lunch" onChange={handleCheckboxChange} />
                                                            <label>Lunch</label>
                                                        </div>
                                                        <div className="vendoraddpackage-checkbox">
                                                            <input type="checkbox" name="Dinner" onChange={handleCheckboxChange} />
                                                            <label>Dinner</label>
                                                        </div>
                                                    </div>
                                                    <div className="vendoraddpackage-checkbox-group">
                                                        <label className="vendoraddpackage-label">Destination Category</label>
                                                        {Destcat.map((feature) => (
                                                            <div key={feature._id} className="vendoraddpackage-checkbox">
                                                                <input type="checkbox" id={feature._id} name={feature._id} onChange={handleCheckboxChange2} />
                                                                <label htmlFor={feature._id}>{feature.category_name}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="vendoraddpackage-checkbox-group">
                                                        <label className="vendoraddpackage-label">Hotel Type</label>
                                                        <div className="vendoraddpackage-checkbox">
                                                            <input type="checkbox" name="5" onChange={handleCheckboxChange3} />
                                                            <label>5 Star</label>
                                                        </div>
                                                        <div className="vendoraddpackage-checkbox">
                                                            <input type="checkbox" name="4" onChange={handleCheckboxChange3} />
                                                            <label>4 Star</label>
                                                        </div>
                                                        <div className="vendoraddpackage-checkbox">
                                                            <input type="checkbox" name="3" onChange={handleCheckboxChange3} />
                                                            <label>3 Star</label>
                                                        </div>
                                                        <div className="vendoraddpackage-checkbox">
                                                            <input type="checkbox" name="2" onChange={handleCheckboxChange3} />
                                                            <label>2 Star</label>
                                                        </div>
                                                        <div className="vendoraddpackage-checkbox">
                                                            <input type="checkbox" name="1" onChange={handleCheckboxChange3} />
                                                            <label>1 Star</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="vendoraddpackage-checkbox-group">
                                                    <div className="vendoraddpackage-input-group2">
                                                        <select
                                                            name="meal_type"
                                                            value={formData1.meal_type}
                                                            onChange={handleOptionChange}
                                                            className="vendoraddpackage-select"
                                                        >
                                                            <option value="">Select Meal type</option>
                                                            <option value="Any">Any</option>
                                                            <option value="Veg">Veg</option>
                                                            <option value="Non-Veg">Non-Veg</option>
                                                        </select>
                                                    </div>
                                                    <div className="vendoraddpackage-input-group2">
                                                        <select
                                                            name="travel_by"
                                                            value={formData1.travel_by}
                                                            onChange={handleOptionChange}
                                                            className="vendoraddpackage-select"
                                                        >
                                                            <option value="">Select Travel By</option>
                                                            <option value="Train">Train</option>
                                                            <option value="Flight">Flight</option>
                                                            <option value="Car">Car</option>
                                                            <option value="Bus">Bus</option>
                                                        </select>
                                                    </div>
                                                    <div className="vendoraddpackage-input-group2">
                                                        <select
                                                            name="sightseeing"
                                                            value={formData1.sightseeing}
                                                            onChange={handleOptionChange}
                                                            className="vendoraddpackage-select"
                                                        >
                                                            <option value="">Select Sightseeing</option>
                                                            <option value="yes">Yes</option>
                                                            <option value="no">No</option>
                                                        </select>
                                                    </div>
                                                    <div className="vendoraddpackage-input-group2">
                                                        <select
                                                            name="room_sharing"
                                                            value={formData1.room_sharing}
                                                            onChange={handleOptionChange}
                                                            className="vendoraddpackage-select"
                                                        >
                                                            <option value="">Select Room Sharing</option>
                                                            <option value="single sharing">single sharing</option>
                                                            <option value="double sharing">double sharing</option>
                                                            <option value="triple sharing">triple sharing</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="vendoraddpackage-flex">
                                                <div className="vendoraddpackage-services">
                                                    <div style={{ fontWeight: "bold", fontSize: "14px" }}>Include Services</div>
                                                    {includeServices.length !== 0 && <div className="vendoraddpackage-service-list">
                                                        <ul>
                                                            {includeServices.map((e, index) => (
                                                                <li key={index} className="vendoraddpackage-service-item">
                                                                    <span>{e}</span>
                                                                    <FontAwesomeIcon
                                                                        icon={faTrash}
                                                                        className='vendoraddpackage-remove-button'
                                                                        onClick={() => handleRemoveService(index)}
                                                                    />
                                                                    {/* <button type="button" onClick={() => handleRemoveService(index)} className="vendoraddpackage-remove-button">Remove</button> */}
                                                                </li>

                                                            ))}
                                                        </ul>

                                                    </div>}
                                                    <div className="vendoraddpackage-input-wrapper">
                                                        <input
                                                            type="text"
                                                            placeholder="Enter Include Services"
                                                            value={newService}
                                                            onChange={(e) => setNewService(e.target.value)}
                                                            className="vendoraddpackage-input"
                                                        />
                                                        <button type="button" onClick={handleAddService} className="vendoraddpackage-add-button">Add</button>
                                                    </div>
                                                </div>
                                                <div className="vendoraddpackage-services">
                                                    <div style={{ fontWeight: "bold", fontSize: "14px" }}>Exclude Services</div>
                                                    {excludeServices.length !== 0 && <div className="vendoraddpackage-service-list">
                                                        <ul>
                                                            {excludeServices.map((e, index) => (
                                                                <li key={index} className="vendoraddpackage-service-item">
                                                                    <span>{e}</span>
                                                                    <FontAwesomeIcon icon={faTrash} onClick={() => handleRemoveExclude(index)} className="vendoraddpackage-remove-button" />
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>}
                                                    <div className="vendoraddpackage-input-wrapper">
                                                        <input
                                                            type="text"
                                                            placeholder="Enter Exclude Services"
                                                            value={exclude}
                                                            onChange={(e) => setExclude(e.target.value)}
                                                            className="vendoraddpackage-input"
                                                        />
                                                        <button type="button" onClick={handleAddExclude} className="vendoraddpackage-add-button">Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="vendoraddpackage-flex">
                                                <textarea
                                                    type="text"
                                                    name="more_details"
                                                    placeholder="Enter More Details"
                                                    value={formData1.more_details}
                                                    onChange={handleOptionChange}
                                                    rows="3"
                                                    style={{ fontSize: "12px", width: "100%", padding: "10px" }}

                                                />
                                            </div>
                                            <div className="vendoraddpackage-flex">
                                                <div className="vendoraddpackage-input-group">
                                                    <div style={{ fontWeight: "bold", fontSize: "14px" }}>Start Date:</div>
                                                    <input
                                                        type="date"
                                                        name="start_date"
                                                        placeholder="Enter Start Date"
                                                        value={startDate}
                                                        onChange={handleStartDateChange}
                                                        min={today} // Restrict start date to current or future dates
                                                        className="vendoraddpackage-input"
                                                    />
                                                </div>
                                                <div className="vendoraddpackage-input-group">
                                                    <div style={{ fontWeight: "bold", fontSize: "14px" }}>End Date:</div>
                                                    <input
                                                        type="date"
                                                        name="end_date"
                                                        placeholder="Enter End Date"
                                                        value={endDate}
                                                        onChange={handleEndDateChange}
                                                        min={startDate || today} // Restrict end date to be after start date or today
                                                        disabled={!startDate} // Disable end date input if no start date is selected
                                                        className="vendoraddpackage-input"
                                                    />
                                                </div>
                                                <div className="vendoraddpackage-input-group">
                                                    <div style={{ fontWeight: "bold", fontSize: "14px" }}>Status:</div>
                                                    <select
                                                        name="status"
                                                        value={formData1.status}
                                                        onChange={handleOptionChange}
                                                        style={{ width: '100%', padding: '9px 10px', fontSize: '12px', border: '1px solid rgb(216, 216, 216)', borderRadius: '6px', color: 'rgb(122, 131, 139)' }}
                                                    >
                                                        <option disabled value="">Select</option>
                                                        <option value={true}>Active</option>
                                                        <option value={false}>InActive</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="price-range-container p-4 bg-light border rounded-md shadow-sm">
                                                <div className="d-flex justify-content-end mb-3">
                                                    <button
                                                        className="vendoraddpackage-button"
                                                        onClick={handleOpenInsertModal}
                                                    >
                                                        + Add Price Range
                                                    </button>
                                                </div>
                                                <table className="table table-striped table-hover">
                                                    <thead className="table-dark">
                                                        <tr>
                                                            <th scope="col"></th>
                                                            <th scope="col">
                                                                <p className="vendoraddpackage-label mb-0">Price</p>
                                                            </th>
                                                            <th scope="col">
                                                                <p className="vendoraddpackage-label mb-0">Start Date</p>
                                                            </th>
                                                            <th scope="col">
                                                                <p className="vendoraddpackage-label mb-0">End Date</p>
                                                            </th>
                                                            <th scope="col">
                                                                <p className="vendoraddpackage-label mb-0">Actions</p>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {priceRanges.length > 0 ? (
                                                            priceRanges.map((range, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}.</td>
                                                                    <td>{range.price_per_person}</td>
                                                                    <td>{range.price_start_date}</td>
                                                                    <td>{range.price_end_date}</td>
                                                                    <td>
                                                                        <Button
                                                                            variant="danger"
                                                                            onClick={() => handleDelete(index)}
                                                                            className="btn btn-sm"
                                                                        >
                                                                            Delete
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="5" className="text-center text-muted">
                                                                    No price ranges added yet.
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="price-range-container p-4 bg-light border rounded-md shadow-sm">
                                                <table className="table table-striped table-hover">
                                                    <thead className="table-dark">
                                                        <tr>
                                                            <th scope="col"></th>
                                                            <th scope="col">
                                                                <p className="vendoraddpackage-label mb-0">Month</p>
                                                            </th>
                                                            <th scope="col">
                                                                <p className="vendoraddpackage-label mb-0">Margin</p>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {margin.length > 0 ? (
                                                            margin?.[1]?.month_and_margin_agency?.map((range, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}.</td>
                                                                    <td>{range.month_name}</td>
                                                                    <td>{range.margin_percentage}%</td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="5" className="text-center text-muted">
                                                                    Margin Of Admin
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="vendoraddpackage-submit mt-3">
                                                <button onClick={handleSubmit} className="vendoraddpackage-button">Submit</button>
                                            </div>
                                        </div>

                                        <Modal show={openRangeModal} onHide={handleCloseRangeModal}>
                                            <Modal.Body>
                                                <Modal.Title>Add Price</Modal.Title>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        placeholder="price"
                                                        name="price"
                                                        value={price}
                                                        onChange={(e) => setPrice(e.target.value)}
                                                        className="vendoraddpackageitinerary-input"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        type="date"
                                                        name="start_date"
                                                        placeholder="Enter Start Date"
                                                        value={startDatePrice}
                                                        onChange={handleStartDatePriceChange}
                                                        min={today} // Restrict start date to current or future dates
                                                        max={startDate}
                                                        className="vendoraddpackage-input"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        type="date"
                                                        name="end_date"
                                                        placeholder="Enter End Date"
                                                        value={endDatePrice}
                                                        onChange={handleEndDatePriceChange}
                                                        min={startDatePrice} // Restrict end date to be after start date
                                                        max={startDate} // Optionally restrict end date to 1 year from start date
                                                        className="vendoraddpackageitinerary-input"
                                                        disabled={!startDatePrice} // Disable end date input if no start date is selected
                                                    />
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <Button variant="secondary" onClick={handleCloseRangeModal}>
                                                        Close
                                                    </Button>
                                                    <div className="d-flex align-items-center">
                                                        <Button className="vendoraddpackage-button" onClick={handleSave}>
                                                            Save
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                        </Modal>

                                    </div>
                                </Collapse>

                                <Collapse in={open1}>
                                    <div className="vendoraddpackageitinerary-container">
                                        <div className="vendoraddpackageitinerary-header">
                                            <div style={{ fontSize: "20px", fontWeight: "bold" }}>Itinerary</div>
                                            {
                                                itineraries.length < maxItineraries &&
                                                (
                                                    <button
                                                        onClick={() => handleOpen()}
                                                        className="vendoraddpackageitinerary-add-button"
                                                    >
                                                        Add Itinerary
                                                    </button>
                                                )}
                                        </div>

                                        <div className="vendoraddpackageitinerary-list">
                                            {itineraries.map((itinerary, index) => (
                                                <div key={index} className="vendoraddpackageitinerary-item">
                                                    <div style={{ fontSize: "16px", fontWeight: "bold" }}>{"Day " + (index + 1)}</div>
                                                    <div className="vendoraddpackageitinerary-card">
                                                        <div className="vendoraddpackageitinerary-card-content">
                                                            <h5 style={{ alignSelf: "center" }}>{itinerary.title}</h5>
                                                            {itinerary.photoFile && (
                                                                <img
                                                                    src={URL.createObjectURL(itinerary.photoFile)}
                                                                    alt={itinerary.title}
                                                                    className="vendoraddpackageitinerary-photo"
                                                                />
                                                            )}
                                                            <h6>Hotel Name: {itinerary.hotelName}</h6>
                                                            <h6>Description: </h6>
                                                            <p style={{ padding: "0px 20px" }}>{itinerary.description}</p>
                                                        </div>
                                                        <div style={{ display: "flex", justifyContent: "end" }}>
                                                            <button className="vendoraddpackageitinerary-editbutton" onClick={() => handleOpen(index)}>Edit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        <Modal show={open} onHide={handleClose}>

                                            <Modal.Body>
                                                <Modal.Title>{isEdit ? 'Edit Itinerary' : 'Add Itinerary'}</Modal.Title>
                                                <form>
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            placeholder="Title"
                                                            name="title"
                                                            value={formDatait.title}
                                                            onChange={handleChangeit}
                                                            className="vendoraddpackageitinerary-input"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={handleChangeit}
                                                            name="photoFile"
                                                            className="vendoraddpackageitinerary-photo-input"
                                                        />
                                                        {formDatait.photoFile && (
                                                            <img
                                                                src={URL.createObjectURL(formDatait.photoFile)}
                                                                alt="Preview"
                                                                className="vendoraddpackageitinerary-preview-image"
                                                            />
                                                        )}
                                                    </div>
                                                    <div className='mb-2'>
                                                        <label htmlFor='text'>Select Hotel</label>
                                                        <div className='d-flex align-items-center'>
                                                            <input
                                                                type='text'
                                                                name='select_hotel'
                                                                className='vendoraddpackageitinerary-input'
                                                                value={selectedHotel ? selectedHotel.hotel_name : ""}
                                                                readOnly
                                                            />
                                                            <Button
                                                                variant='primary'
                                                                className='ms-2'
                                                                onClick={() => setShowModal(true)}
                                                            >
                                                                +
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <textarea
                                                            placeholder="Description"
                                                            name="description"
                                                            value={formDatait.description}
                                                            onChange={handleChangeit}
                                                            className="vendoraddpackageitinerary-textarea"
                                                            rows="4"
                                                        />
                                                    </div>
                                                </form>

                                                <div>
                                                    <Button variant="secondary" onClick={handleClose}>
                                                        Close
                                                    </Button>
                                                    <Button variant="primary" onClick={handleSaveit}>
                                                        Save
                                                    </Button>
                                                </div>
                                            </Modal.Body>

                                        </Modal>

                                        {itineraries.length >= maxItineraries && (
                                            <div className="vendoraddpackageitinerary-submit-container">
                                                <button
                                                    onClick={handleSubmit2}
                                                    className="vendoraddpackageitinerary-submit-button"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        )}
                                    </div>

                                </Collapse>
                            </div>
                        </Box>
                        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                            <Modal.Body>
                                <div>
                                    <h5 className="mb-0 mt-2 ms-2">Select Hotel</h5>
                                    <hr />
                                    {hotelList && hotelList.map((e, index) => (
                                        <div key={index} className="d-flex align-items-start mb-3 mx-4 gap-3">
                                            <input
                                                type="radio"
                                                name="selectedHotel"
                                                value={e._id}
                                                onChange={() => setSelectedHotel(e)}
                                                checked={selectedHotel && selectedHotel._id === e._id}
                                                className="mt-2"
                                            />
                                            <div>
                                                <p className="mb-1 cmnp">{e.hotel_name}</p>
                                                <p className="mb-1 cmnp">{e?.hotel_address}</p>
                                                <p className="mb-1 cmnp">{e?.hotel_city},{e?.hotel_state}</p>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="d-flex justify-content-center mt-4 mb-3">
                                        <Button
                                            variant="primary"
                                            style={{
                                                backgroundColor: "#155E75",
                                            }}
                                            onClick={() => setShowModal(false)}
                                        >
                                            Select
                                        </Button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddMyPackage;
