import React from "react"
import Header from "../Project/Header"
import { useState } from "react"
import index1 from "../Project/css/index1.css"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useNavigate } from "react-router-dom"
import axios from "axios"

function VendorForgotPassword() {
  const Navigate = useNavigate()

  const [mobileNumber, setMobileNumber] = useState("")

  const handleMobileNumberChange = (event) => {
    const inputNumber = event.target.value
    if (inputNumber.length <= 10) {
      setMobileNumber(inputNumber)
    }
  }

  const handleSubmit = async() => {
    if (mobileNumber.length < 10) {
      toast.error("Please Enter Valid Mobile Number!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
    } else if (mobileNumber === "") {
      toast.error("Please Enter Mobile Number!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
    } else {
      const number = "+91" + mobileNumber;
      const random = Math.floor(Math.random() * 900000) + 100000;

      const url = `https://rslri.connectbind.com:8443/bulksms/bulksms?username=DG35-webearl&password=digimile&type=0&dlr=1&destination=${number}&source=WEBEAR&message=Dear User, Your one time password is ${random} and it is valid for 10 minutes. Do not share it to anyone. Thank you, Team WEBEARL TECHNOLOGIES.&entityid=1101602010000073269&tempid=1107169899584811565`;

      try {
        const response = await axios.get(url);
        if (response.status === 200) {
          Navigate("/vendor/otp", {state: random})
          toast.success('OTP sent successfully!', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
        } else {
          toast.error('Failed to send OTP. Please try again.', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
        }
      } catch (error) {
        console.error('Error sending OTP:', error);
        toast.error('Error sending OTP. Please check your network connection and try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      }
      
    }
  }

  const handleKeyDown = (e) => {
    if (
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) ||
      (e.keyCode === 67 && e.ctrlKey === true) ||
      (e.keyCode === 86 && e.ctrlKey === true) ||
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      return
    }
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault()
    }
  }

  return (
    <>
      <Header />
      <div className='container'>
        <div className='forgot_not'>
          <div className='forgot_pass'>
            <div className='forgot_box'>
              <div className='text-center mt-3'>
                <h2>Reset Password</h2>
              </div>
              <div className='mobile_number_box'>
                <div className='mobile_number_forgot text-start'>
                  <p className='mb-1'>Enter Your Mobile Number</p>
                  <input
                    type='tel' // Use type "tel" for mobile numbers
                    name='mobileNumber'
                    id='mobileNumber'
                    value={mobileNumber}
                    //   onChange={handleMobileNumberChange}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/\D/g, "")
                        .slice(0, 10)
                      handleMobileNumberChange(e)
                    }}
                    onKeyDown={handleKeyDown}
                    maxLength={10}
                  />
                  <ToastContainer />
                </div>
              </div>
              <div className='mobile_btn'>
                <button onClick={handleSubmit}>Next</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VendorForgotPassword
