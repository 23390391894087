import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faEnvelopeOpen, faUser, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { BASE_URL } from "../BASE_URL";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

const ProfileSection = ({ title }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate()

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleOptionClick = (option) => {
        if (option === "logout") {
            handleShow()
        } else if (option === "profile") {
            navigate("/vendor/myprofile")
        } else if (option === "changePassword") {
            navigate("/vendor/changepassword")
        }
        setDropdownOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const [editedata, setEditedData] = useState("");

    const Call = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}agency/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setEditedData(data?.data?.[0]);
    };

    useEffect(() => {
        Call();
    }, []);

    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const handleYesClick = () => {
        localStorage.removeItem("vendorToken")

        handleClose()

        navigate("/vendor/login")
    }

    const vendorToken = localStorage.getItem("vendorToken")
    useEffect(() => {
        if (!vendorToken) {
            navigate("/vendor/login")
        }
    }, [vendorToken])

    return (
        <section style={{ backgroundColor: "#fff" }}>
            <div className="costum_container">
                <div>
                    <div className="pt-2 align-items-center second_navbar">
                        <div className="ps-md-4 ps-sm-2 ps-2 pe-xl-5 pe-lg-5 pe-md-3 pe-sm-2 pe-2 align-items-center d-flex justify-content-between">
                            <div className="w-50 d-flex align-items-center">
                                <p className="mb-0" style={{ fontSize: "24px", fontWeight: "600" }}>{title}</p>
                            </div>
                            <div className="d-flex align-items-center">
                                <a href="">
                                    <FontAwesomeIcon
                                        icon={faBell}
                                        className="p-2 cmnclr cmnicon"
                                    />
                                </a>
                                <a href="">
                                    <FontAwesomeIcon
                                        icon={faEnvelopeOpen}
                                        className="p-2 cmnclr cmnicon ms-2"
                                    />
                                </a>
                                <div
                                    className="d-flex align-items-center cmn py-1 px-2 ms-2 position-relative"
                                    style={{ cursor: "pointer" }}
                                    onClick={toggleDropdown}
                                    ref={dropdownRef}
                                >
                                    <FontAwesomeIcon
                                        icon={faUser}
                                        className="cmnclr cmnicon p-2"
                                    />
                                    <p className="cmnp ps-1">{editedata?.full_name}</p>
                                    <FontAwesomeIcon
                                        icon={faCaretDown}
                                        className="ps-2"
                                    />
                                    {dropdownOpen && (
                                        <div className="dropdown-menu show position-absolute mt-5">
                                            <button
                                                className="dropdown-item"
                                                onClick={() => handleOptionClick('profile')}>
                                                Profile
                                            </button>
                                            <button
                                                className="dropdown-item"
                                                onClick={() => handleOptionClick('changePassword')}>
                                                Change Password
                                            </button>
                                            <button
                                                className="dropdown-item"
                                                onClick={() => handleOptionClick('logout')}>
                                                Logout
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop='static'
                keyboard={false}
                centered // Add the centered prop to center the modal
            >
                <Modal.Body className='p-5'>
                    <div>
                        <h4 className='text-center'>Are you Sure Want to Logout?</h4>
                    </div>
                    <div
                        className='d-flex justify-content-center'
                        style={{ gap: "10px", marginTop: "30px" }}
                    >
                        <button
                            style={{
                                width: "80px",
                                padding: "4px 0px",
                                backgroundColor: "lightgray",
                                borderRadius: "10px",
                                boxShadow: "none",
                                border: "none",
                                color: "grey",
                            }}
                            onClick={handleClose}
                        >
                            No
                        </button>
                        <button
                            style={{
                                width: "80px",
                                padding: "4px 0px",
                                backgroundColor: "#09646d",
                                borderRadius: "10px",
                                boxShadow: "none",
                                border: "none",
                                color: "white",
                            }}
                            onClick={handleYesClick}
                        >
                            Yes
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    );
};

export default ProfileSection;
