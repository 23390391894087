import React, { useRef } from "react"
import { Link } from "react-router-dom"

import html2canvas from "html2canvas"
import jsPDF from "jspdf"

import {
  faIndianRupeeSign
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const calculateAge = (dob) => {
  const birthDate = new Date(dob);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

const formatDate = (dateString) => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return 'Invalid date format';
  }

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};


function Book_packega2({ details }) {

  console.log(details)

  const pdfRef = useRef()

  const generatePDF = () => {
    const input = pdfRef.current
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png")
      const pdf = new jsPDF("p", "mm", "a4", true)
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()
      const imageWidth = canvas.width
      const imageHeight = canvas.height
      const ratio = Math.min(pdfWidth / imageWidth, pdfHeight / imageHeight)
      const imgX = (pdfWidth - imageWidth * ratio) / 2
      const imgY = 30
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imageWidth * ratio,
        imageHeight * ratio,
      )
      pdf.save("payment_receipt.pdf")
    })
  }

  return (
    <>
      <section className='booked_package_2'>
        <div className='costum_container'>
          <div
            className='green_border book_packega_1'
            style={{ backgroundColor: "#ffffff" }}
          >
            <div className='cmn p-3 m-md-3 m-0' ref={pdfRef}>
              <h3>Payment Receipt</h3>

              <div className='py-2'>
                <h6 className='cmnclr font-weight-bold cmnhr1'>
                  Booking Details
                </h6>
                {details.map((ele, index) => (
                  <div className='w-100 d-flex flex-column flex-md-row mt-4'>
                    <div className='w-md-50 w-100'>
                      <p className='text-capitalize'>
                        <b>Name</b> :{" "}
                        {ele.user_name}{" "}
                      </p>

                      <p>
                        <b>Email</b> :{" "}
                        {
                          ele.email_id
                        }{" "}
                      </p>

                      <p>
                        <b>Mobile no</b> : {ele.contact_number}{" "}
                      </p>

                      <p className='text-capitalize'>
                        <b>Departure</b> : {ele.departure}{" "}
                      </p>

                      <p className='text-capitalize'>
                        <b>Destination</b> :{" "}
                        {ele.destination || ele.package_details[0].destination[0].destination_name}{" "}
                      </p>
                    </div>

                    <div className='w-md-50 w-100'>
                      <p className='text-capitalize'>
                        <b>Adult</b> : {ele.total_adult}{" "}
                      </p>

                      <p className='text-capitalize'>
                        <b>Children</b> : {ele.total_child}{" "}
                      </p>

                      <p className='text-capitalize'>
                        <b>Infant</b> : {ele.total_infant}{" "}
                      </p>
                      <p className='text-capitalize'>
                        <b>Total Travellers</b> : {ele.total_person}{" "}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              {details?.[0]?.payment_type_on_booking !== "Partial Payment" && (
                <div className='py-2'>
                  <h6 className='cmnclr font-weight-bold cmnhr1'>
                    Payment Details
                  </h6>

                  {details.map((ele, index) => (
                    <div className='w-100 d-flex flex-column flex-md-row mt-4'>
                      <div className='w-md-50 w-100'>
                        <p className='text-capitalize'>
                          <b>Vendor Price Per Person</b> :{" "}
                          <span className='text-uppercase'>
                            {ele.priceperperson}
                          </span>
                        </p>
                      </div>

                      <div className='w-md-50 w-100'>
                        <p className='text-capitalize'>
                          <b>Admin Margin</b> :{" "}
                          {ele.admin_margin_price}({ele?.admin_margin_percentage}%)
                        </p>
                      </div>
                    </div>
                  ))}

                  {details.map((ele, index) => (
                    <div className='w-100 d-flex flex-column flex-md-row mt-2'>
                      <div className='w-md-50 w-100'>
                        <p className='text-capitalize'>
                          <b>Payment Method</b> :{" "}
                          <span className='text-uppercase'>
                            {ele.payment_type}
                          </span>
                        </p>
                      </div>

                      <div className='w-md-50 w-100'>
                        <p className='text-capitalize'>
                          <b>Total Amount</b> :{" "}
                          {ele.total_amount}
                        </p>
                      </div>
                    </div>
                  ))}

                  {details.map((ele, index) => (
                    <div className='w-100 d-flex flex-column flex-md-row mt-2'>
                      <div className='w-md-50 w-100'>
                        <p className='text-capitalize'>
                          <b>Tranjection Id</b> :{" "}
                          <span className='text-uppercase'>
                            {ele.transaction_id}
                          </span>
                        </p>
                      </div>
                      <div className='w-md-50 w-100'>
                        <p className='text-capitalize'>
                          <b>Paid Amount</b> :{" "}
                          <span className='text-uppercase'>
                            <FontAwesomeIcon
                              className='me-1'
                              icon={faIndianRupeeSign}
                            />
                            {ele?.total_amount}
                          </span>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {details?.[0]?.payment_type_on_booking === "Partial Payment" && (
                <div>
                  <h6 className='cmnclr font-weight-bold cmnhr1'>
                    Payment Details
                  </h6>
                  <div className='px-xl-5 px-lg-4 px-md-3 px-sm-2 px-1 py-2'>
                    <div className='cmn p-2 px-3'>
                      <div>
                        <table className="table table-bordered mt-2">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col">Amount</th>
                              <th scope="col">Transaction Id</th>
                              <th scope="col">Payment Mode</th>
                              <th scope="col">Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {details && details?.[0]?.payment?.map((e) => (
                              <tr>
                                <td><FontAwesomeIcon
                                  className='me-1'
                                  icon={faIndianRupeeSign}
                                />{e?.paid_amount}</td>
                                <td>{e?.transaction_id}</td>
                                <td>{e?.payment_mode}</td>
                                <td>{formatDate(e?.payment_date)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className='py-2 pt-3 m-3'>
              <Link className='batanv'>View</Link>
              <Link
                variant='primary'
                className='ms-2 batann'
                onClick={generatePDF}
              >
                Download
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Book_packega2
